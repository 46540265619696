.container {
  max-width: 1402px;
  &-fluid {
    max-width: 1860px;
    margin-left: auto;
    margin-right: auto;
  }
  &-medium {
    max-width: 1650px;
    margin-left: auto;
    margin-right: auto;
  }
  &-large {
    max-width: 1552px;
    margin-left: auto;
    margin-right: auto;
  }
  &-small {
    max-width: 1164px;
    margin-left: auto;
    margin-right: auto;
  }
  &-xsmall {
    max-width: 922px;
    margin-left: auto;
    margin-right: auto;
  }
}
.text-left { text-align: left; }
.text-right { text-align: right; }
.fw-semibold { font-weight: 500 !important }
