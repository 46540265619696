//Text Colors
.cl-white { color: #FFFFFF; }
.cl-darkwhite { color: #F5F5F5; }
.cl-darkerwhite { color: #E6E6E6; }
.cl-blue { color: #1B9CF2; }
.cl-darkblue { color: #032672; }
.cl-xtradarkblue { color: #03153B; }
.cl-lightgrey { color: #919191; }
.cl-steam { color: #DDDDDD; }

//Backgroud Colors
.bg-frost { background-color: #EAF0F0; }
.bg-blue { background-color: #2EA5EA; }
.bg-blueest { background-color: #1892D8; }
.bg-dark { background-color: #03153B !important; }
