.offcanvas-start {
  width: 100%;
  border-right: none;
  overflow: scroll;
  .offcanvas-header {
    .btn-close {
      font-size: 22px;
      color: #03153B !important;
      background: none;
      opacity: 1;
      margin: 0;
      width: auto;
      height: auto;
    }
  }
  .offcanvas-body {
    padding: 0;
    overflow: unset;
    flex-grow: 0;
    a {
      .iconify {
        float: left;
        margin-top: 5px;
      }
      &:hover, &:active, &:focus {
        color: #1892D8;
        .iconify {
          color: #1892D8;
        }
      }
    }
  }
}
