.section_board {
  .container {
    @media (min-width:454px) and (max-width: 1498px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
    .row {
      .col {
        h1 {

        }
        h6 {
          margin-top: 40px;
        }
      }
    }
  }
}
