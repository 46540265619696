/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */

/* ++++++++++++++++++++++++++++ */
.section_ldg_highlight_block_results {
    background-color: #ffffff;
    color: #031d56;
    font-family: "Be Vietnam Pro", Helvetica, Arial, serif;
    letter-spacing: 0;
    .container {
      @media (min-width:454px) and (max-width: 1498px) {
        padding-right: var(--bs-gutter-x,62px) !important;
        padding-left: var(--bs-gutter-x,62px) !important;
      }
      .row {
        &.title {
          flex-direction: column;
          justify-content: center;
          text-align: center;
          height: 70px;
          background-color: #F5F5F5;
          align-items: center;
          p {
            margin-bottom: 0;
          }
        }

/* SECTION 2 */
.section2-flex {
    display: flex;
    flex-direction: column;
    background-color: white; }
  
  .section2-flex.layout {
    position: relative;
    overflow: hidden; }
  
  .section2-flex1 {
    display: flex;
    flex-direction: column; }
  
  .section2-flex1.layout {
    position: relative;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    width: 75%;
    margin: 49px auto 0px; }
  
  .section2-flex2 {
    display: flex;
    flex-direction: column; }
  
  .section2-flex2.layout {
    position: relative;
    height: -webkit-min-content;
    height: -moz-min-content;
    width: 100%;
    margin: 0px 0px;   
   }

  .section2-txt-box {
    display: flex;
    justify-content: center; }
    @media (max-width: 1199px) {
      .section2-txt-box {
        align-items: flex-start;
        justify-content: center; } }
    @media (max-width: 991px) {
      .section2-txt-box {
        align-items: flex-start;
        justify-content: center; } }
    @media (max-width: 767px) {
      .section2-txt-box {
        align-items: flex-start;
        justify-content: center; } }
    @media (max-width: 575px) {
      .section2-txt-box {
        align-items: flex-start;
        justify-content: center; } }
    @media (max-width: 479px) {
      .section2-txt-box {
        align-items: flex-start;
        justify-content: center; } }
    @media (max-width: 383px) {
      .section2-txt-box {
        align-items: flex-start;
        justify-content: center; } }
  
  .section2-txt-box.layout {
    position: relative;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    width: 100%;
    margin: 48px 0px 0px;
    }

  .section2-txt {
    overflow: visible;
    margin-top: 0px;
    margin-bottom: 0px;
    margin: 0px;
    font: 700 36px/0.83 "Be Vietnam Pro", Helvetica, Arial, serif;
    color: #031e56;
    text-align: center;
    letter-spacing: 0px;
    white-space: pre-wrap; }
    @media (max-width: 1199px) {
      .section2-txt {
        font-size: 32px;
        text-align: center; } }
    @media (max-width: 991px) {
      .section2-txt {
        font-size: 29px; } }
    @media (max-width: 767px) {
      .section2-txt {
        font-size: 25px; } }
    @media (max-width: 575px) {
      .section2-txt {
        font-size: 24px; } }
    @media (max-width: 479px) {
      .section2-txt {
        font-size: 22px; } }
    @media (max-width: 383px) {
      .section2-txt {
        font-size: 21px; } }
  
  .section2-txt1 {
    display: flex;
    justify-content: center;
    font: 16px/1.62 "Be Vietnam Pro", Helvetica, Arial, serif;
    color: #031d56;
    text-align: center;
    letter-spacing: 0px; }
    @media (max-width: 1199px) {
      .section2-txt1 {
        font-size: 15px;
        text-align: center; } }
    @media (max-width: 991px) {
      .section2-txt1 {
        font-size: 14px; } }
    @media (max-width: 767px) {
      .section2-txt1 {
        font-size: 13px; } }
    @media (max-width: 479px) {
      .section2-txt1 {
        font-size: 12px; } }
  
  .section2-txt1.layout {
    position: relative;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    width: 100%;
    margin: 47px 0px 0px; }
  
  .section2-flex3 {
    display: flex; 
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 1399px) {
    .section2-flex3 {
      flex-wrap: wrap;
      row-gap: 16px; 
    } 
  }
  
  .section2-flex3.layout {
    position: relative;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    width: 95%;
    margin: 68px auto 112px; 
        @media (max-width: 1399px) {
            width: 80%;
        }
        @media (max-width: 1199px) {
            width: 80%;
        }
    }
  
  .section2-flex3-item {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 1 300px; }
    @media (min-width: 1200px) and (max-width: 1399px) {
      .section2-flex3-item {
        flex: 0 0 382px; } }
    @media (max-width: 1199px) {
      .section2-flex3-item {
        flex: 0 0 49.99%; } }
    @media (max-width: 991px) {
      .section2-flex3-item {
        flex: 0 0 49.99%; } }
    @media (max-width: 767px) {
      .section2-flex3-item {
        flex: 0 0 350px; } }
    @media (max-width: 575px) {
      .section2-flex3-item {
        flex: 0 0 100%; } }
  
  .section2-cover-block {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid white; }
  
  .section2-cover-block.layout {
    position: relative;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
  
  .section2-txt2 {
    display: flex;
    align-items: flex-end;
    font: 800 45px/0.72 "Be Vietnam Pro", Helvetica, Arial, serif;
    color: #031d56;
    letter-spacing: 0px; }
    @media (max-width: 1919px) {
      .section2-txt2 {
        text-align: left; } }

    @media (max-width: 1199px) {
      .section2-txt2 {
        font-size: 44px; } }
    @media (max-width: 991px) {
      .section2-txt2 {
        font-size: 38px; } }
    @media (max-width: 767px) {
      .section2-txt2 {
        font-size: 38px; } }
    @media (max-width: 575px) {
      .section2-txt2 {
        font-size: 38px; } }
    @media (max-width: 479px) {
      .section2-txt2 {
        font-size: 38px; } }
    @media (max-width: 383px) {
      .section2-txt2 {
        font-size: 36px; } }
  
  .section2-txt2.layout {
    position: absolute;
    top: 41px;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    width: 100%;
    margin: 0px auto; }
  
  @media (max-width: 1919px) {
    .section2-txt3-box {
      align-items: flex-start;
      justify-content: flex-start; } }
  @media (max-width: 1199px) {
    .section2-txt3-box {
      align-items: flex-start;
      justify-content: flex-start; } } 
  @media (max-width: 991px) {
    .section2-txt3-box {
      align-items: flex-start;
      justify-content: flex-start; } }
  @media (max-width: 767px) {
    .section2-txt3-box {
      align-items: flex-start;
      justify-content: flex-start; } }
  @media (max-width: 479px) {
    .section2-txt3-box {
      align-items: flex-start;
      justify-content: flex-start; } }
  
  .section2-txt3-box.layout {
    position: relative;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    width: 100%; /*70.33%;*/
    margin: 106px auto 40px; }
  
    .section2-txt3 {
    overflow: visible;
    margin-top: 0px;
    margin-bottom: 0px;
    margin: 0px;
    font: 16px/1.3 "Be Vietnam Pro", Helvetica, Arial, serif;
    color: #1b9cf2;
    letter-spacing: 0px;
    white-space: pre-wrap; }
        @media (max-width: 1919px) {
            .section2-txt3 {
            text-align: left; } }
        @media (max-width: 1199px) {
            .section2-txt3 {
            font-size: 15px; } }
        @media (max-width: 991px) {
            .section2-txt3 {
            font-size: 14px; } }
        @media (max-width: 767px) {
            .section2-txt3 {
            font-size: 14px; } }
        @media (max-width: 479px) {
            .section2-txt3 {
            font-size: 14px; } }
        @media (max-width: 1399px) {
            .section2-txt2, .section2-txt3 {
                text-align: center;
                justify-content: center;
            }
        }      
        
        .section2-img {
        background: var(--src) center center/contain no-repeat; 
        }
    
        .section2-img.layout {
        position: absolute;
        top: 0px;
        height: 133px;
        width: 1px;
        min-width: 15px;
        right: 0px;
        margin: 41px 0px 38px; 
            &.last {
                display: none;
                @media (max-width: 1399px) {
                  display: block;
                }
                @media (max-width: 767px) {
                  display: none;
                }
            }
            
            @media (max-width: 767px) {
              display: none;
            }
        }
    
        .section2-flex3-spacer {
            flex: 0 0 auto;
            min-width: 1px; 
        }
        @media (max-width: 1399px) {
            .section2-flex3-spacer {
            display: none; } 
        }
      }
    }
  }
  
.section_ldg_highlight_block_turnover_ebitda {
    min-height: 700px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #031d56;
    font-family: "Be Vietnam Pro", Helvetica, Arial, serif;
    letter-spacing: 0;
    .overlay {
        position: relative;
        background-color: rgba(3, 21, 59, 0.90);
        min-height: 700px;
        width: 100%;
        display: flex;
        align-items: center;
    }
    .img {
        @include media-breakpoint-down($sm) {
            height: 40px;
        }
    }
    
    /* SECTION 3 */
    .seccao3-block {
        display: flex;
        flex-direction: column;
        background: var(--src) center center/cover no-repeat;
    }
      
      .seccao3-block.layout {
        position: relative;
        overflow: hidden;
        min-height: 701px;
        flex-shrink: 0; }
      
      .seccao3-flex {
        display: flex; }
        @media (max-width: 1399px) {
          .seccao3-flex {
            flex-wrap: wrap;
            row-gap: 16px; } }
      
      .seccao3-flex.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        width: 66.67%;
        margin: 102px auto; }
        @media (max-width: 1199px) {
          .seccao3-flex.layout {
            width: 58.77%;
            margin: 84px auto; } }
        @media (max-width: 991px) {
          .seccao3-flex.layout {
            width: 65.53%;
            margin: 65px auto; } }
        @media (max-width: 767px) {
          .seccao3-flex.layout {
            width: 71.71%;
            margin: 49px auto; } }
        @media (max-width: 575px) {
          .seccao3-flex.layout {
            width: 77.17%;
            margin: 40px auto; } }
        @media (max-width: 479px) {
          .seccao3-flex.layout {
            width: 81.84%;
            margin: 32px auto; } }
        @media (max-width: 383px) {
          .seccao3-flex.layout {
            width: 85.73%;
            margin: 27px auto; } }
      
      .seccao3-flex-item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1 1 369px;
        align-items: center; }
        @media (max-width: 767px) {
          .seccao3-flex-item {
            flex: 0 0 100%; } }
      
      .seccao3-flex1 {
        display: flex;
        flex-direction: column; }
      
      .seccao3-flex1.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 0px 0px 15px; }
        @media (max-width: 1399px) {
          .seccao3-flex1.layout {
            margin: 0px 0px 50px; } }
        @media (max-width: 767px) {
          .seccao3-flex1.layout {
            margin: 0px 0px 100px; } }
        @media (max-width: 479px) {
          .seccao3-flex1.layout {
            margin: 0px 0px 50px; } }
      
      .seccao3-flex2 {
        display: flex; }
      
      .seccao3-flex2.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content; }
      
      .seccao3-flex2-item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 46px; }
      
      .seccao3-cover-block {
        display: flex;
        flex-direction: column;
        background: var(--src) center center/contain no-repeat; }
      
      .seccao3-cover-block.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content; }
      
      .seccao3-highlights3 {
        display: flex;
        justify-content: center;
        font: 700 16px/2.25 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: white;
        text-align: center;
        letter-spacing: 0px; }
        @media (max-width: 1199px) {
          .seccao3-highlights3 {
            font-size: 15px;
            text-align: center; } }
        @media (max-width: 991px) {
          .seccao3-highlights3 {
            font-size: 14px; } }
        @media (max-width: 767px) {
          .seccao3-highlights3 {
            font-size: 13px; } }
        @media (max-width: 479px) {
          .seccao3-highlights3 {
            font-size: 12px; } }
      
      .seccao3-highlights3.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 4px 7.5px 6px 8.5px; }
        @media (max-width: 1199px) {
          .seccao3-highlights3.layout {
            margin: 5px 7px; } }
        @media (max-width: 991px) {
          .seccao3-highlights3.layout {
            margin: 5px 6px; } }
        @media (max-width: 767px) {
          .seccao3-highlights3.layout {
            margin: 5px; } }
      
      .seccao3-flex2-spacer {
        flex: 0 1 25px; }
      
      .seccao3-highlights31 {
        font: 700 16px/2.25 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: white;
        letter-spacing: 1.6px; }
        @media (max-width: 1199px) {
          .seccao3-highlights31 {
            font-size: 15px;
            text-align: left; } }
        @media (max-width: 991px) {
          .seccao3-highlights31 {
            font-size: 14px; } }
        @media (max-width: 767px) {
          .seccao3-highlights31 {
            font-size: 13px; } }
        @media (max-width: 479px) {
          .seccao3-highlights31 {
            font-size: 12px; } }
      
      .seccao3-highlights31.layout {
        position: relative;
        flex: 0 0 auto;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 4px 0px 6px; }
        @media (max-width: 1199px) {
          .seccao3-highlights31.layout {
            margin: 5px 0px; } }
      
      .seccao3-flex1-spacer {
        flex: 0 0 auto;
        min-height: 50px; }
      
      .seccao3-flex1-spacer1 {
        flex: 0 0 auto; }
      
      .seccao3-flex4 {
        display: flex;
        width: 100%;
        height: 100%; }
      
      .seccao3-flex4-item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 162px; }
      
      .seccao3-flex5 {
        display: flex;
        flex-direction: column; }
      
      .seccao3-flex5.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 61px 0px 0px; }
        @media (max-width: 1199px) {
          .seccao3-flex5.layout {
            margin: 52px 0px 0px; } }
        @media (max-width: 991px) {
          .seccao3-flex5.layout {
            margin: 43px 0px 0px; } }
        @media (max-width: 767px) {
          .seccao3-flex5.layout {
            margin: 35px 0px 0px; } }
        @media (max-width: 575px) {
          .seccao3-flex5.layout {
            margin: 31px 0px 0px; } }
        @media (max-width: 479px) {
          .seccao3-flex5.layout {
            margin: 27px 0px 0px; } }
        @media (max-width: 383px) {
          .seccao3-flex5.layout {
            margin: 24px 0px 0px; } }
      
      .seccao3-flex4-spacer {
        flex: 0 1 46px; }
      
      .seccao3-flex4-item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 152px; }
      
      .seccao3-flex3 {
        display: flex;
        flex-direction: column; }
      
      .seccao3-flex3.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        width: 100%;
        margin: 0px 0px; 
        align-items: flex-end; }
        @media (max-width: 1199px) {
          .seccao3-flex3.layout {
            margin: 5px 0px 0px; } }
      
      .seccao3-icon1 {
        background: var(--src) center center/contain no-repeat;
        width: 27px;
        height: 27px; }
      
      .seccao3-hero-title6 {
        display: flex;
        justify-content: flex-end;
        font: 800 40px/0.6 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: #3df6a8;
        text-align: right;
        letter-spacing: 0px; }
        @media (max-width: 1199px) {
          .seccao3-hero-title6 {
            font-size: 36px;
            text-align: right; } }
        @media (max-width: 991px) {
          .seccao3-hero-title6 {
            font-size: 32px; } }
        @media (max-width: 767px) {
          .seccao3-hero-title6 {
            font-size: 28px; } }
        @media (max-width: 575px) {
          .seccao3-hero-title6 {
            font-size: 26px; } }
        @media (max-width: 479px) {
          .seccao3-hero-title6 {
            font-size: 24px; } }
        @media (max-width: 383px) {
          .seccao3-hero-title6 {
            font-size: 23px; } }
      
      .seccao3-hero-title6.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 31px 0px 0px; }
        @media (max-width: 1199px) {
          .seccao3-hero-title6.layout {
            margin: 27px 0px 0px; } }
        @media (max-width: 991px) {
          .seccao3-hero-title6.layout {
            margin: 23px 0px 0px; } }
        @media (max-width: 767px) {
          .seccao3-hero-title6.layout {
            margin: 19px 0px 0px; } }
        @media (max-width: 575px) {
          .seccao3-hero-title6.layout {
            margin: 18px 0px 0px; } }
        @media (max-width: 479px) {
          .seccao3-hero-title6.layout {
            margin: 16px 0px 0px; } }
        @media (max-width: 383px) {
          .seccao3-hero-title6.layout {
            margin: 15px 0px 0px; } }
      
      .seccao3-flex1-item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 259px; }
      
      .seccao3-flex41 {
        display: flex;
        align-items: flex-end; }
      
      .seccao3-flex41.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content; }
      
      .seccao3-flex51 {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%; }
      
      .seccao3-highlights32 {
        display: flex;
        justify-content: center;
        font: 700 16px/1.5 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: white;
        text-align: center;
        letter-spacing: 0px; }
        @media (max-width: 1199px) {
          .seccao3-highlights32 {
            font-size: 15px;
            text-align: center; } }
        @media (max-width: 991px) {
          .seccao3-highlights32 {
            font-size: 14px; } }
        @media (max-width: 767px) {
          .seccao3-highlights32 {
            font-size: 13px; } }
        @media (max-width: 479px) {
          .seccao3-highlights32 {
            font-size: 12px; } }
      
      .seccao3-highlights32.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 0px 30.5px 3px; }
        @media (max-width: 1199px) {
          .seccao3-highlights32.layout {
            margin: 0px 27px 5px; } }
        @media (max-width: 991px) {
          .seccao3-highlights32.layout {
            margin: 0px 23px 5px; } }
        @media (max-width: 767px) {
          .seccao3-highlights32.layout {
            margin: 0px 19px 5px; } }
        @media (max-width: 575px) {
          .seccao3-highlights32.layout {
            margin: 0px 17px 5px; } }
        @media (max-width: 479px) {
          .seccao3-highlights32.layout {
            margin: 0px 16px 5px; } }
        @media (max-width: 383px) {
          .seccao3-highlights32.layout {
            margin: 0px 14px 5px; } }
      
      .seccao3-box1 {
        background-color: rgba(255, 255, 255, 0.4);
        box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 0.247059);
        border-radius: 2px 2px 2px 2px;
        opacity: 0.4; }
      
      .seccao3-box1.layout {
        position: relative;
        height: 140px; }
      
      .seccao3-highlights4 {
        display: flex;
        justify-content: center;
        font: 16px/1.5 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: white;
        text-align: center;
        letter-spacing: 0px; }
        @media (max-width: 1199px) {
          .seccao3-highlights4 {
            font-size: 15px;
            text-align: center; } }
        @media (max-width: 991px) {
          .seccao3-highlights4 {
            font-size: 14px; } }
        @media (max-width: 767px) {
          .seccao3-highlights4 {
            font-size: 13px; } }
        @media (max-width: 479px) {
          .seccao3-highlights4 {
            font-size: 12px; } }
      
      .seccao3-highlights4.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 7px 0px 0px; }
        @media (max-width: 1199px) {
          .seccao3-highlights4.layout {
            margin: 6px 0px 0px; } }
        @media (max-width: 991px) {
          .seccao3-highlights4.layout {
            margin: 5px 0px 0px; } }
      
      .seccao3-flex4-item2 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 161px; }
      
      .seccao3-flex6 {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%; }
      
      .seccao3-highlights33 {
        display: flex;
        justify-content: center;
        font: 700 16px/1.5 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: white;
        text-align: center;
        letter-spacing: 0px; }
        @media (max-width: 1199px) {
          .seccao3-highlights33 {
            font-size: 15px;
            text-align: center; } }
        @media (max-width: 991px) {
          .seccao3-highlights33 {
            font-size: 14px; } }
        @media (max-width: 767px) {
          .seccao3-highlights33 {
            font-size: 13px; } }
        @media (max-width: 479px) {
          .seccao3-highlights33 {
            font-size: 12px; } }
      
      .seccao3-highlights33.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 0px 29.5px 9px 30.5px; }
        @media (max-width: 1199px) {
          .seccao3-highlights33.layout {
            margin: 0px 26px 8px 27px; } }
        @media (max-width: 991px) {
          .seccao3-highlights33.layout {
            margin: 0px 22px 7px 23px; } }
        @media (max-width: 767px) {
          .seccao3-highlights33.layout {
            margin: 0px 19px 6px; } }
        @media (max-width: 575px) {
          .seccao3-highlights33.layout {
            margin: 0px 17px 5px; } }
        @media (max-width: 479px) {
          .seccao3-highlights33.layout {
            margin: 0px 15px 5px 16px; } }
        @media (max-width: 383px) {
          .seccao3-highlights33.layout {
            margin: 0px 14px 5px; } }
      
      .seccao3-box2 {
        background-color: white;
        box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 0.247059);
        border-radius: 2px 2px 2px 2px; }
      
      .seccao3-box2.layout {
        position: relative;
        height: 194px;
        margin: 1px 0px 0px; }
        @media (max-width: 1199px) {
          .seccao3-box2.layout {
            margin: 5px 0px 0px; } }
      
      .seccao3-highlights34 {
        display: flex;
        justify-content: center;
        font: 700 16px/1.5 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: white;
        text-align: center;
        letter-spacing: 0px; }
        @media (max-width: 1199px) {
          .seccao3-highlights34 {
            font-size: 15px;
            text-align: center; } }
        @media (max-width: 991px) {
          .seccao3-highlights34 {
            font-size: 14px; } }
        @media (max-width: 767px) {
          .seccao3-highlights34 {
            font-size: 13px; } }
        @media (max-width: 479px) {
          .seccao3-highlights34 {
            font-size: 12px; } }
      
      .seccao3-highlights34.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        width: 26.09%;
        margin: 7px auto 0px; }
        @media (max-width: 1199px) {
          .seccao3-highlights34.layout {
            margin: 6px auto 0px; } }
        @media (max-width: 991px) {
          .seccao3-highlights34.layout {
            margin: 5px auto 0px; } }
      
      .seccao3-flex-spacer {
        flex: 0 1 254px; }
        @media (max-width: 1399px) {
          .seccao3-flex-spacer {
            display: none; } }
      
      .seccao3-flex7 {
        display: flex;
        flex-direction: column; }
      
      .seccao3-flex7.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 0px 0px 15px; }
        @media (max-width: 1199px) {
          .seccao3-flex7.layout {
            margin: 0px 0px 13px; } }
        @media (max-width: 991px) {
          .seccao3-flex7.layout {
            margin: 0px 0px 11px; } }
        @media (max-width: 767px) {
          .seccao3-flex7.layout {
            margin: 0px 0px 100px; } }
        @media (max-width: 479px) {
          .seccao3-flex7.layout {
            margin: 0px 0px 8px; } }
        @media (max-width: 383px) {
          .seccao3-flex7.layout {
            margin: 0px 0px 7px; } }
      
      .seccao3-flex8 {
        display: flex;
        width: 100%;
        height: 100%; }
      
      .seccao3-flex8-item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 46px; }
      
      .seccao3-flex8-spacer {
        flex: 0 1 15px; }
      
      .seccao3-highlights35 {
        font: 700 16px/2.25 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: white;
        letter-spacing: 1.6px; }
        @media (max-width: 1199px) {
          .seccao3-highlights35 {
            font-size: 15px;
            text-align: left; } }
        @media (max-width: 991px) {
          .seccao3-highlights35 {
            font-size: 14px; } }
        @media (max-width: 767px) {
          .seccao3-highlights35 {
            font-size: 13px; } }
        @media (max-width: 479px) {
          .seccao3-highlights35 {
            font-size: 12px; } }
      
      .seccao3-highlights35.layout {
        position: relative;
        flex: 0 0 auto;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 4px 0px 6px; }
        @media (max-width: 1199px) {
          .seccao3-highlights35.layout {
            margin: 5px 0px; } }
      
      .seccao3-flex7-spacer {
        flex: 0 0 auto;
        min-height: 50px; }
      
      .seccao3-flex4-item3 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 152px; }
      
      .seccao3-hero-title6.layout1 {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 31px 0px 0px; }
        @media (max-width: 1199px) {
          .seccao3-hero-title6.layout1 {
            margin: 25px 0px 0px; } }
        @media (max-width: 991px) {
          .seccao3-hero-title6.layout1 {
            margin: 21px 0px 0px; } }
        @media (max-width: 767px) {
          .seccao3-hero-title6.layout1 {
            margin: 18px 0px 0px; } }
        @media (max-width: 575px) {
          .seccao3-hero-title6.layout1 {
            margin: 16px 0px 0px; } }
        @media (max-width: 479px) {
          .seccao3-hero-title6.layout1 {
            margin: 14px 0px 0px; } }
        @media (max-width: 383px) {
          .seccao3-hero-title6.layout1 {
            margin: 13px 0px 0px; } }
      
      .seccao3-flex7-spacer1 {
        flex: 0 0 auto; }
      
      .seccao3-flex7-item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 259px; }
      
      .seccao3-flex10 {
        display: flex;
        align-items: flex-end; }
      
      .seccao3-flex10.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content; }
      
      .seccao3-flex10-item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 162px; }
      
      .seccao3-flex11 {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%; }
      
      .seccao3-highlights36 {
        display: flex;
        justify-content: center;
        font: 700 16px/1.5 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: white;
        text-align: center;
        letter-spacing: 0px; }
        @media (max-width: 1199px) {
          .seccao3-highlights36 {
            font-size: 15px;
            text-align: center; } }
        @media (max-width: 991px) {
          .seccao3-highlights36 {
            font-size: 14px; } }
        @media (max-width: 767px) {
          .seccao3-highlights36 {
            font-size: 13px; } }
        @media (max-width: 479px) {
          .seccao3-highlights36 {
            font-size: 12px; } }
      
      .seccao3-highlights36.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 0px 35.5px 8px; }
        @media (max-width: 1199px) {
          .seccao3-highlights36.layout {
            margin: 0px 31px 7px; } }
        @media (max-width: 991px) {
          .seccao3-highlights36.layout {
            margin: 0px 26px 6px; } }
        @media (max-width: 767px) {
          .seccao3-highlights36.layout {
            margin: 0px 22px 5px; } }
        @media (max-width: 575px) {
          .seccao3-highlights36.layout {
            margin: 0px 19px 5px; } }
        @media (max-width: 479px) {
          .seccao3-highlights36.layout {
            margin: 0px 17px 5px; } }
        @media (max-width: 383px) {
          .seccao3-highlights36.layout {
            margin: 0px 16px 5px; } }
      
      .seccao3-flex10-spacer {
        flex: 0 1 46px; }
      
      .seccao3-flex10-item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 161px; }
      
      .seccao3-flex12 {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%; }
      
      .seccao3-highlights37 {
        display: flex;
        justify-content: center;
        font: 700 16px/1.5 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: white;
        text-align: center;
        letter-spacing: 0px; }
        @media (max-width: 1199px) {
          .seccao3-highlights37 {
            font-size: 15px;
            text-align: center; } }
        @media (max-width: 991px) {
          .seccao3-highlights37 {
            font-size: 14px; } }
        @media (max-width: 767px) {
          .seccao3-highlights37 {
            font-size: 13px; } }
        @media (max-width: 479px) {
          .seccao3-highlights37 {
            font-size: 12px; } }
      
      .seccao3-highlights37.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 0px 30.5px 8px 31.5px; }
        @media (max-width: 1199px) {
          .seccao3-highlights37.layout {
            margin: 0px 27px 7px 28px; } }
        @media (max-width: 991px) {
          .seccao3-highlights37.layout {
            margin: 0px 23px 6px; } }
        @media (max-width: 767px) {
          .seccao3-highlights37.layout {
            margin: 0px 19px 5px 20px; } }
        @media (max-width: 575px) {
          .seccao3-highlights37.layout {
            margin: 0px 17px 5px 18px; } }
        @media (max-width: 479px) {
          .seccao3-highlights37.layout {
            margin: 0px 16px 5px; } }
        @media (max-width: 383px) {
          .seccao3-highlights37.layout {
            margin: 0px 14px 5px 15px; } }
      
      .seccao3-box3 {
        background-color: white;
        box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 0.247059);
        border-radius: 2px 2px 2px 2px; }
      
      .seccao3-box3.layout {
        position: relative;
        height: 184px; }
      
      .seccao3-highlights38 {
        display: flex;
        justify-content: center;
        font: 700 16px/1.5 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: white;
        text-align: center;
        letter-spacing: 0px; }
        @media (max-width: 1199px) {
          .seccao3-highlights38 {
            font-size: 15px;
            text-align: center; } }
        @media (max-width: 991px) {
          .seccao3-highlights38 {
            font-size: 14px; } }
        @media (max-width: 767px) {
          .seccao3-highlights38 {
            font-size: 13px; } }
        @media (max-width: 479px) {
          .seccao3-highlights38 {
            font-size: 12px; } }
      
      .seccao3-highlights38.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        width: 26.09%;
        margin: 7px auto 0px; }
        @media (max-width: 1199px) {
          .seccao3-highlights38.layout {
            margin: 6px auto 0px; } }
        @media (max-width: 991px) {
          .seccao3-highlights38.layout {
            margin: 5px auto 0px; } }    
}

.section_ldg_highlight_block_investments {
    background-color: #ffffff;
    color: #031d56;
    font-family: "Be Vietnam Pro", Helvetica, Arial, serif;
    letter-spacing: 0;
    .container {
      /*
      @media (min-width:454px) and (max-width: 1498px) {
        padding-right: var(--bs-gutter-x,62px) !important;
        padding-left: var(--bs-gutter-x,62px) !important;
      }
      */
      .row {
        &.title {
          flex-direction: column;
          justify-content: center;
          text-align: center;
          height: 70px;
          background-color: #F5F5F5;
          align-items: center;
          p {
            margin-bottom: 0;
          }
        }
        p {
          margin-bottom: 0;
          img {
            width: 60%;
            margin: 0px auto;
              @media (max-width: 767px) {
                width: 100%;
              }
          }
        }        
      }
    }

/* SECTION 4 */
.section4-block {
    display: flex;
    flex-direction: column;
    background-color: white; }
  
  .section4-block.layout {
    position: relative;
    overflow: hidden;
    /*min-height: 700px;*/
    flex-shrink: 0; }
  
  .section4-flex {
    display: flex;
    flex-direction: column; }
  
  .section4-flex.layout {
    position: relative;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    width: 75%;
    margin: 0px auto 50px; }
  
  .section4-hero-title3 {
    display: flex;
    justify-content: center;
    font: 700 36px/0.83 "Be Vietnam Pro", Helvetica, Arial, serif;
    color: #031e56;
    text-align: center;
    letter-spacing: 0px; }
  
  .section4-hero-title3.layout {
    position: relative;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    width: 31.59%;
    margin: 0px auto; }
  
  .section4-highlights2-box {
    display: flex;
    justify-content: center; }
  
  .section4-highlights2-box.layout {
    position: relative;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    margin: 47px 4px 0px 0px; }
  
  .section4-highlights2 {
    overflow: visible;
    margin-top: 0px;
    margin-bottom: 0px;
    margin: 0px;
    font: 16px/1.62 "Be Vietnam Pro", Helvetica, Arial, serif;
    color: #031d56;
    text-align: center;
    letter-spacing: 0px;
    white-space: pre-wrap; }
  
    @media (min-width: 992px) {
      .section4-block_image {
        width: 55%;
        margin: 5px auto;
      }
    }
  
    @media (max-width: 991px) {
      .section4-block_image {
        width: 100%;
        margin: 75px auto;
      }
    }
    @media (max-width: 767px) {
      .section4-block_image {
        width: 140%;
        margin: 50px -12%;
      }
    }  
  
    @media (max-width: 479px) {
      .section4-block_image {
        width: 140%;
        margin: 50px -12%;
      }
    }  
  
  .section4-block1 {
    display: flex;
    flex-direction: column;
    background: var(--src) center center/auto no-repeat;
    width: 100%;
    height: 100%; }
  
  .section4-medium-title1 {
    display: flex;
    justify-content: center;
    font: 700 24px/1.33 "Be Vietnam Pro", Helvetica, Arial, serif;
    color: #1b9cf2;
    text-align: center;
    letter-spacing: 0px; }
  
  .section4-medium-title1.layout {
    position: relative;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    margin: 50px 0px 0px; }
    @media (max-width: 991px) {
      .section4-medium-title1.layout {
        margin: 30px 0px 0px; } }
  
  .section4-hero-title1 {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 3rem; //calc(24px + (120 - 24) * (100vw / 1920));
    line-height: 0.2;
    font-family: "Be Vietnam Pro", Helvetica, Arial, serif;
    color: #1b9cf2;
    text-align: center;
    letter-spacing: 0px; }
  
  .section4-hero-title1.layout {
    position: relative;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    width: 98.45%;
    margin: 35px auto; }    
}

.section_ldg_highlight_block_turnover_geography {
    min-height: 700px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #031d56;
    font-family: "Be Vietnam Pro", Helvetica, Arial, serif;
    letter-spacing: 0;
    .overlay {
        position: relative;
        background-color: rgba(3, 21, 59, 0.90);
        min-height: 700px;
        width: 100%;
        display: flex;
        align-items: center;
    }
    .img {
        @include media-breakpoint-down($sm) {
        height: 40px;
        }
    }

    .container {
      .row {
          p {
            img {
                width: 60%;
                margin: 0px auto;
                @media (max-width: 767px) {
                  width: 100%;
                }
            }
          }
      }
    }

    /* SECTION 5 */
    .section5-block {
        display: flex;
        flex-direction: column;
        background: var(--src) center center/cover no-repeat;
    }
      
      .section5-block.layout {
        position: relative;
        overflow: hidden;
        min-height: 701px;
        flex-shrink: 0; 
        /*
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content; */}
      
      .section5-decorator2 {
        width: 100%;
        height: auto;
        vertical-align: top;
        -o-object-fit: contain;
        object-fit: contain;
        -o-object-position: center top;
        object-position: center top; }
      
      .section5-decorator2.layout {
        position: absolute;
        bottom: 0px;
        left: 2px;
        width: 1920px; }
      
      .section5-flex {
        display: flex;
        flex-direction: column; }
      
      .section5-flex.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        min-height: 573px;
        flex-shrink: 0;
        margin: 0px auto; }
        @media (max-width: 1919px) {
          .section5-flex.layout {
            width: 75%; } }
        @media (max-width: 1199px) {
          .section5-flex.layout {
            margin: 0px auto; } }
        @media (max-width: 991px) {
          .section5-flex.layout {
            margin: 0px auto; } }
        @media (max-width: 767px) {
          .section5-flex.layout {
            width: 77.78%;
            margin: 0px auto; } }
        @media (max-width: 575px) {
          .section5-flex.layout {
            width: 72.7%;
            margin: 0px auto; } }
        @media (max-width: 479px) {
          .section5-flex.layout {
            width: 78.02%;
            margin: 0px auto; } }
        @media (max-width: 383px) {
          .section5-flex.layout {
            width: 82.56%;
            margin: 0px auto; } }
      
      .section5-hero-title4 {
        display: flex;
        justify-content: center;
        font: 700 36px/0.83 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: white;
        text-align: center;
        letter-spacing: 0px; }
        @media (max-width: 1199px) {
          .section5-hero-title4 {
            font-size: 32px;
            text-align: center; } }
        @media (max-width: 991px) {
          .section5-hero-title4 {
            font-size: 29px; } }
        @media (max-width: 767px) {
          .section5-hero-title4 {
            font-size: 25px; } }
        @media (max-width: 575px) {
          .section5-hero-title4 {
            font-size: 24px; } }
        @media (max-width: 479px) {
          .section5-hero-title4 {
            font-size: 22px; } }
        @media (max-width: 383px) {
          .section5-hero-title4 {
            font-size: 21px; } }
      
      .section5-hero-title4.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content; }
      
      .section5-group {
        display: flex;
        flex-direction: column;
        background: var(--src) center center/contain no-repeat; }
      
      .section5-group.layout {
        position: relative;
        height: 348px;
        width: 97.5%;
        margin: 143px auto 0px; }

        @media (min-width: 992px) {
            .section5-group_image {
                margin: 0px auto;
            }
        }
        
          @media (max-width: 991px) {
            .section5-group_image {
              width: 100%;
              margin: 5px auto;
            }
          }
          @media (max-width: 767px) {
            .section5-group_image {
              width: 125%;
              margin: 0px 1%;
            }
          }  
        
          @media (max-width: 479px) {
            .section5-group_image {
                width: 125%;
                margin: 0px 1%;
            }
          }          
          
}

.section_ldg_highlight_block_total_volume_business_employees {
    background-color: #ffffff;
    color: #031d56;
    font-family: "Be Vietnam Pro", Helvetica, Arial, serif;
    letter-spacing: 0;
    .container {
        @media (min-width:454px) and (max-width: 1498px) {
            padding-right: var(--bs-gutter-x,62px) !important;
            padding-left: var(--bs-gutter-x,62px) !important;
        }
        .row {
            &.title {
                flex-direction: column;
                justify-content: center;
                text-align: center;
                height: 70px;
                background-color: #F5F5F5;
                align-items: center;
                p {
                    margin-bottom: 0;
                }
            }
        }
    }

    /* SECTION 6 */
    .section6-block {
        display: flex;
        flex-direction: column;
        background-color: white; }
      
      .section6-block.layout {
        position: relative;
        overflow: hidden;
        min-height: 695px;
        flex-shrink: 0; }
      
      .section6-flex {
        display: flex;
        flex-direction: column; }
      
      .section6-flex.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        width: 62.5%;
        margin: 167px auto 50px auto; }
        @media (max-width: 1399px) {
          .section6-flex.layout {
            width: 68.97%; } }
        @media (max-width: 1199px) {
          .section6-flex.layout {
            width: 74.76%;
            margin: 133px auto; } }
        @media (max-width: 991px) {
          .section6-flex.layout {
            width: 79.8%;
            margin: 97px auto; } }
        @media (max-width: 767px) {
          .section6-flex.layout {
            width: 84.04%;
            margin: 65px auto; } }
        @media (max-width: 575px) {
          .section6-flex.layout {
            width: 87.53%;
            margin: 50px auto; } }
        @media (max-width: 479px) {
          .section6-flex.layout {
            width: 90.35%;
            margin: 34px auto; } }
        @media (max-width: 383px) {
          .section6-flex.layout {
            width: 92.58%;
            margin: 23px auto; } }
      
      .section6-flex1 {
        display: flex; }
        @media (max-width: 1399px) {
          .section6-flex1 {
            flex-wrap: wrap;
            row-gap: 16px; } }
      
      .section6-flex1.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content; }
      
      .section6-flex1-item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1 1 112px; 
        align-items: flex-end;
      }
        @media (max-width: 1919px) {
          .section6-flex1-item {
            flex: 1 1 auto; } }
        @media (max-width: 1399px) {
          .section6-flex1-item {
            flex: 0 0 100%; } }
      
      .section6-block1 {
        display: flex;
        flex-direction: column; }
      
      .section6-block1.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        min-height: 111px;
        flex-shrink: 0;
        margin: 16px 0px 0px; }
        @media (max-width: 1199px) {
          .section6-block1.layout {
            margin: 14px 0px 0px; } }
        @media (max-width: 991px) {
          .section6-block1.layout {
            margin: 12px 0px 0px; } }
        @media (max-width: 767px) {
          .section6-block1.layout {
            margin: 10px 0px 0px; } }
        @media (max-width: 575px) {
          .section6-block1.layout {
            margin: 9px 0px 0px; } }
        @media (max-width: 479px) {
          .section6-block1.layout {
            margin: 8px 0px 0px; } }
      
      .section6-icon2 {
        background: var(--src) center center/contain no-repeat; }
      
      .section6-icon2.layout {
        position: relative;
        height: 109px;
        width: 109px;
        min-width: 109px;
        margin: 0px 1px 2px 2px; }
        @media (max-width: 1199px) {
          .section6-icon2.layout {
            margin: 0px 5px 5px; } }
      
      .section6-flex1-spacer {
        flex: 0 1 78px; }
        @media (max-width: 1399px) {
          .section6-flex1-spacer {
            display: none; } }
      
      .section6-flex1-item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 0 auto; }
        @media (max-width: 1919px) {
          .section6-flex1-item1 {
            min-width: unset; } }
        @media (max-width: 1399px) {
          .section6-flex1-item1 {
            flex: 0 0 100%; } }
      
      .section6-hero-title {
        font: 800 120px/0.2 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: #031d56;
        letter-spacing: 0px; }
        @media (max-width: 1199px) {
          .section6-hero-title {
            font-size: 104px;
            text-align: left; } }
        @media (max-width: 991px) {
          .section6-hero-title {
            font-size: 86px; } }
        @media (max-width: 767px) {
          .section6-hero-title {
            font-size: 71px; } }
        @media (max-width: 575px) {
          .section6-hero-title {
            font-size: 64px; } }
        @media (max-width: 479px) {
          .section6-hero-title {
            font-size: 56px; } }
        @media (max-width: 383px) {
          .section6-hero-title {
            font-size: 51px; } }
      
      .section6-hero-title.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        min-height: 24px;
        flex-shrink: 0;
        margin: 31px 0px 72px; }
        @media (max-width: 1199px) {
          .section6-hero-title.layout {
            margin: 27px 0px 62px; } }
        @media (max-width: 991px) {
          .section6-hero-title.layout {
            margin: 23px 0px 50px; } }
        @media (max-width: 767px) {
          .section6-hero-title.layout {
            margin: 19px 0px 41px; } }
        @media (max-width: 575px) {
          .section6-hero-title.layout {
            margin: 18px 0px 36px; } }
        @media (max-width: 479px) {
          .section6-hero-title.layout {
            margin: 16px 0px 31px; } }
        @media (max-width: 383px) {
          .section6-hero-title.layout {
            margin: 15px 0px 28px; } }
      
      .section6-flex1-spacer1 {
        flex: 0 1 48px; }
        @media (max-width: 1399px) {
          .section6-flex1-spacer1 {
            display: none; } }
      
      .section6-flex1-item2 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1 1 324px; }
        @media (max-width: 1399px) {
          .section6-flex1-item2 {
            flex: 0 0 100%; } }
      
      .section6-flex2 {
        display: flex;
        flex-direction: column; }
      
      .section6-flex2.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 0px 0px 23px; }
        @media (max-width: 1199px) {
          .section6-flex2.layout {
            margin: 0px 0px 20px; } }
        @media (max-width: 991px) {
          .section6-flex2.layout {
            margin: 0px 0px 17px; } }
        @media (max-width: 767px) {
          .section6-flex2.layout {
            margin: 0px 0px 14px; } }
        @media (max-width: 575px) {
          .section6-flex2.layout {
            margin: 0px 0px 13px; } }
        @media (max-width: 479px) {
          .section6-flex2.layout {
            margin: 0px 0px 12px; } }
        @media (max-width: 383px) {
          .section6-flex2.layout {
            margin: 0px 0px 11px; } }
      
      .section6-medium-title3 {
        font: 500 24px/1.66 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: #031e56;
        letter-spacing: 0px; }
        @media (max-width: 1199px) {
          .section6-medium-title3 {
            font-size: 22px;
            text-align: left; } }
        @media (max-width: 991px) {
          .section6-medium-title3 {
            font-size: 20px; } }
        @media (max-width: 767px) {
          .section6-medium-title3 {
            font-size: 19px; } }
        @media (max-width: 575px) {
          .section6-medium-title3 {
            font-size: 18px; } }
        @media (max-width: 479px) {
          .section6-medium-title3 {
            font-size: 17px; } }
        @media (max-width: 383px) {
          .section6-medium-title3 {
            font-size: 16px; } }
      
      .section6-medium-title3.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content; }
      
      .section6-medium-title {
        font: 700 24px/1.33 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: #031e56;
        letter-spacing: 0px; }
        @media (max-width: 1199px) {
          .section6-medium-title {
            font-size: 22px;
            text-align: left; } }
        @media (max-width: 991px) {
          .section6-medium-title {
            font-size: 20px; } }
        @media (max-width: 767px) {
          .section6-medium-title {
            font-size: 19px; } }
        @media (max-width: 575px) {
          .section6-medium-title {
            font-size: 18px; } }
        @media (max-width: 479px) {
          .section6-medium-title {
            font-size: 17px; } }
        @media (max-width: 383px) {
          .section6-medium-title {
            font-size: 16px; } }
      
      .section6-medium-title.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content; }
      
      .section6-flex-spacer {
        flex: 0 0 auto;
        min-height: 113px; }
      
      .section6-flex3 {
        display: flex; }
        @media (max-width: 1399px) {
          .section6-flex3 {
            flex-wrap: wrap;
            row-gap: 16px; } }
      
      .section6-flex3.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 9px 0 1px 14.33%;
        /*
        width: 56.75%;
        margin: 9px 3.92% 1px 39.33%; 
      */}
        @media (max-width: 1199px) {
          .section6-flex3.layout {
            /*margin: 8px 3.92% 5px 39.33%;*/ } }
        @media (max-width: 991px) {
          .section6-flex3.layout {
            /*margin: 7px 3.92% 5px 39.33%;*/ } }
        @media (max-width: 767px) {
          .section6-flex3.layout {
            /*margin: 6px 3.92% 5px 39.33%;*/ } }
        @media (max-width: 575px) {
          .section6-flex3.layout {
            width: 93.54%;
            margin: 5px 6.46% 5px 0%; } }
      
      .section6-flex3-item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 112px; 
        align-items: flex-end;
      }
        @media (max-width: 1399px) {
          .section6-flex3-item {
            flex: 0 0 100%; } }
        @media (max-width: 1199px) {
          .section6-flex3-item {
            flex: 0 0 100%; }  }
        @media (max-width: 767px) {
          .section6-flex3-item {
            flex: 0 0 100%; } }
      
      .section6-block1.layout1 {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content; }
        @media (max-width: 575px) {
          .section6-block1.layout1 {
            margin: 9px 0px 0px; } }
      
      .section6-line {
        background-color: #00f890;
        border-radius: 1px; }
      
      .section6-line.layout {
        position: absolute;
        top: 0px;
        height: 2px;
        left: 0px;
        right: 0px;
        margin: 0px 0px 109px; }
        @media (max-width: 1199px) {
          .section6-line.layout {
            margin: 0px 0px 90px; } }
        @media (max-width: 991px) {
          .section6-line.layout {
            margin: 0px 0px 68px; } }
        @media (max-width: 767px) {
          .section6-line.layout {
            margin: 0px 0px 50px; } }
        @media (max-width: 575px) {
          .section6-line.layout {
            margin: 0px 0px 41px; } }
        @media (max-width: 479px) {
          .section6-line.layout {
            margin: 0px 0px 32px; } }
        @media (max-width: 383px) {
          .section6-line.layout {
            margin: 0px 0px 26px; } }
      
      .section6-flex3-spacer {
        flex: 0 1 78px;
        }
        @media (max-width: 1399px) {
          .section6-flex3-spacer {
            display: none; } }
        @media (max-width: 1199px) {
          .section6-flex3-spacer {
            flex: 0 0 16px;
            min-width: unset; } }
        @media (max-width: 767px) {
          .section6-flex3-spacer {
            display: none; } }
      
      .section6-flex3-item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 0 auto; }
        @media (max-width: 1399px) {
          .section6-flex3-item1 {
            flex: 0 0 100%; } }
        @media (max-width: 1199px) {
          .section6-flex3-item1 {
            flex: 0 0 calc(33.33333333333333% - 10.666666666666666px); } }
        @media (max-width: 767px) {
          .section6-flex3-item1 {
            flex: 0 0 100%; } }
      
      .section6-hero-title1 {
        display: flex;
        justify-content: flex-end;
        font: 800 120px/0.2 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: #031d56;
        text-align: right;
        letter-spacing: 0px; }
        @media (max-width: 1399px) {
          .section6-hero-title1 {
            justify-content: flex-start;
            text-align: left; } }
        @media (max-width: 1199px) {
          .section6-hero-title1 {
            font-size: 104px; } }
        @media (max-width: 991px) {
          .section6-hero-title1 {
            font-size: 86px; } }
        @media (max-width: 767px) {
          .section6-hero-title1 {
            font-size: 71px; } }
        @media (max-width: 575px) {
          .section6-hero-title1 {
            font-size: 64px; } }
        @media (max-width: 479px) {
          .section6-hero-title1 {
            font-size: 56px; } }
        @media (max-width: 383px) {
          .section6-hero-title1 {
            font-size: 51px; } }
      
      .section6-hero-title1.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 14px 0px 73px; }
        @media (max-width: 1199px) {
          .section6-hero-title1.layout {
            margin: 12px 0px 62px; } }
        @media (max-width: 991px) {
          .section6-hero-title1.layout {
            margin: 10px 0px 51px; } }
        @media (max-width: 767px) {
          .section6-hero-title1.layout {
            margin: 9px 0px 41px; } }
        @media (max-width: 575px) {
          .section6-hero-title1.layout {
            margin: 18px 0px 36px; } }
        @media (max-width: 479px) {
          .section6-hero-title1.layout {
            margin: 7px 0px 31px; } }
        @media (max-width: 383px) {
          .section6-hero-title1.layout {
            margin: 7px 0px 28px; } }
      
      .section6-flex3-spacer1 {
        flex: 0 1 48px;
        /*
        flex: 1 0 auto;
        min-width: 41px; 
        */
      }
        @media (max-width: 1919px) {
          .section6-flex3-spacer1 {
            flex: 1 1 48px; } }
        @media (max-width: 1399px) {
          .section6-flex3-spacer1 {
            display: none; } }
        @media (max-width: 1199px) {
          .section6-flex3-spacer1 {
            flex: 0 0 16px;
            min-width: unset; } }
        @media (max-width: 767px) {
          .section6-flex3-spacer1 {
            display: none; } }
      
      .section6-flex3-item2 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 177px; }
        @media (max-width: 1399px) {
          .section6-flex3-item2 {
            flex: 0 0 100%; } }
        @media (max-width: 1199px) {
          .section6-flex3-item2 {
            flex: 0 0 100%; } }
        @media (max-width: 767px) {
          .section6-flex3-item2 {
            flex: 0 0 100%; } }
      
      .section6-flex4 {
        display: flex;
        flex-direction: column; }
      
      .section6-flex4.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 14px 0px 25px; }
        @media (max-width: 1199px) {
          .section6-flex4.layout {
            margin: 12px 0px 22px; } }
        @media (max-width: 991px) {
          .section6-flex4.layout {
            margin: 10px 0px 19px; } }
        @media (max-width: 767px) {
          .section6-flex4.layout {
            margin: 9px 0px 16px; } }
        @media (max-width: 575px) {
          .section6-flex4.layout {
            margin: 8px 0px 14px; } }
        @media (max-width: 479px) {
          .section6-flex4.layout {
            margin: 7px 0px 13px; } }
        @media (max-width: 383px) {
          .section6-flex4.layout {
            margin: 7px 0px 12px; } }
      
      .section6-medium-title2 {
        font: 500 24px/1.66 "Be Vietnam Pro Light", Helvetica, Arial, serif;
        color: #031d56;
        letter-spacing: 0px; }
        @media (max-width: 1199px) {
          .section6-medium-title2 {
            font-size: 22px;
            text-align: left; } }
        @media (max-width: 991px) {
          .section6-medium-title2 {
            font-size: 20px; } }
        @media (max-width: 767px) {
          .section6-medium-title2 {
            font-size: 19px; } }
        @media (max-width: 575px) {
          .section6-medium-title2 {
            font-size: 18px; } }
        @media (max-width: 479px) {
          .section6-medium-title2 {
            font-size: 17px; } }
        @media (max-width: 383px) {
          .section6-medium-title2 {
            font-size: 16px; } }
      
      .section6-medium-title2.layout {
          position: relative;
          height: -webkit-min-content;
          height: -moz-min-content;
          height: min-content;
        }
        @media (max-width: 1199px) {
          .section6-medium-title2.layout {
            margin: 0px 8px 0px 0px; } }
        @media (max-width: 991px) {
          .section6-medium-title2.layout {
            margin: 0px 7px 0px 0px; } }
        @media (max-width: 767px) {
          .section6-medium-title2.layout {
            margin: 0px 6px 0px 0px; } }
        @media (max-width: 575px) {
          .section6-medium-title2.layout {
            margin: 0px 5px 0px 0px; } }
          
}

.section_ldg_highlight_block_president_message {
    background-color: #ffffff;
    color: #031d56;
    font-family: "Be Vietnam Pro", Helvetica, Arial, serif;
    letter-spacing: 0;
    .container {
      @media (min-width:454px) and (max-width: 1498px) {
        padding-right: var(--bs-gutter-x,62px) !important;
        padding-left: var(--bs-gutter-x,62px) !important;
      }
      .row {
        &.title {
          flex-direction: column;
          justify-content: center;
          text-align: center;
          height: 70px;
          background-color: #F5F5F5;
          align-items: center;
            p {
                margin-bottom: 0;
            }
            }
        }
    }

    /* SECTION 8 */
    .section8-block {
        display: flex;
        flex-direction: column;
        background-color: white; }
      
      .section8-block.layout {
        position: relative;
        overflow: hidden;
        min-height: 500px;
        flex-shrink: 0; }
      
      .section8-flex {
        display: flex; }
        @media (max-width: 767px) {
          .section8-flex {
            flex-wrap: wrap;
            row-gap: 16px; } }
      
      .section8-flex.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        width: 62.6%;
        margin: 25px auto; }
        @media (max-width: 1399px) {
          .section8-flex.layout {
            width: 69.07%; } }
        @media (max-width: 1199px) {
          .section8-flex.layout {
            width: 74.85%;
            margin: 108px auto; } }
        @media (max-width: 991px) {
          .section8-flex.layout {
            width: 79.87%;
            margin: 81px auto; } }
        @media (max-width: 767px) {
          .section8-flex.layout {
            width: 84.1%;
            margin: 57px auto; } }
        @media (max-width: 575px) {
          .section8-flex.layout {
            width: 87.58%;
            margin: 45px auto; } }
        @media (max-width: 479px) {
          .section8-flex.layout {
            width: 90.39%;
            margin: 33px auto; } }
        @media (max-width: 383px) {
          .section8-flex.layout {
            width: 92.61%;
            margin: 25px auto; } }
      
      .section8-flex-item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1 1 787px; }
        @media (max-width: 767px) {
          .section8-flex-item {
            flex: 0 0 100%; } }
      
      .section8-flex1 {
        display: flex;
        flex-direction: column; }
      
      .section8-flex1.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        min-width: 0px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 81px 0px 81px 2px; }
        @media (max-width: 1199px) {
          .section8-flex1.layout {
            margin: 68px 5px; } }
        @media (max-width: 991px) {
          .section8-flex1.layout {
            margin: 55px 5px; } }
        @media (max-width: 767px) {
          .section8-flex1.layout {
            margin: 43px 5px; } }
        @media (max-width: 575px) {
          .section8-flex1.layout {
            margin: 37px 5px; } }
        @media (max-width: 479px) {
          .section8-flex1.layout {
            margin: 32px 5px; } }
        @media (max-width: 383px) {
          .section8-flex1.layout {
            margin: 28px 5px; } }
      
      .section8-hero-title5 {
        font: 700 36px/0.83 "Be Vietnam Pro Light", Helvetica, Arial, serif;
        color: #031d56;
        letter-spacing: 0px; }
        @media (max-width: 1199px) {
          .section8-hero-title5 {
            font-size: 32px;
            text-align: left; } }
        @media (max-width: 991px) {
          .section8-hero-title5 {
            font-size: 29px; } }
        @media (max-width: 767px) {
          .section8-hero-title5 {
            font-size: 25px; } }
        @media (max-width: 575px) {
          .section8-hero-title5 {
            font-size: 24px; } }
        @media (max-width: 479px) {
          .section8-hero-title5 {
            font-size: 22px; } }
        @media (max-width: 383px) {
          .section8-hero-title5 {
            font-size: 21px; } }
      
      .section8-hero-title5.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content; }
      
      .section8-flex1-spacer {
        flex: 0 0 auto;
        min-height: 48px; }
      
      .section8-highlights2 {
        font: 16px/1.62 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: #031d56;
        letter-spacing: 0px; }
        @media (max-width: 1199px) {
          .section8-highlights2 {
            font-size: 15px;
            text-align: left; } }
        @media (max-width: 991px) {
          .section8-highlights2 {
            font-size: 14px; } }
        @media (max-width: 767px) {
          .section8-highlights2 {
            font-size: 13px; } }
        @media (max-width: 479px) {
          .section8-highlights2 {
            font-size: 12px; } }
      
      .section8-highlights2.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 1px 0px 0px; }
        @media (max-width: 1199px) {
          .section8-highlights2.layout {
            margin: 5px 0px 0px; } }
      
      .section8-flex1-spacer1 {
        flex: 0 0 auto;
        min-height: 43px; }
      
      .section8-cover-block {
        display: flex;
        flex-direction: column;
        background-color: white;
        /*border: 2px solid #1b9cf2;*/ }
      
      .section8-cover-block.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        min-width: 240px;
        margin: 1px auto; }
      
      .section8-text-body {
        display: flex;
        justify-content: left;
        font: 700 14px/1.42 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: #031e56;
        text-align: center;
        letter-spacing: 0px; }
        @media (max-width: 991px) {
          .section8-text-body {
            font-size: 13px;
            text-align: center; } }
        @media (max-width: 575px) {
          .section8-text-body {
            font-size: 12px; } }
      
      .section8-text-body.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        min-height: 20px;
        flex-shrink: 0;
        margin: 13px auto 17px auto; }
        @media (max-width: 1199px) {
          .section8-text-body.layout {
            margin: 11px 32px 15px 30px; } }
        @media (max-width: 991px) {
          .section8-text-body.layout {
            margin: 10px 27px 13px 26px; } }
        @media (max-width: 767px) {
          .section8-text-body.layout {
            margin: 8px 22px 11px 21px; } }
        @media (max-width: 575px) {
          .section8-text-body.layout {
            margin: 7px 20px 10px 19px; } }
        @media (max-width: 479px) {
          .section8-text-body.layout {
            margin: 7px 18px 9px 17px; } }
        @media (max-width: 383px) {
          .section8-text-body.layout {
            margin: 6px 16px 8px; } }
      
      .section8-flex-spacer {
        flex: 0 1 55px; }
        @media (max-width: 767px) {
          .section8-flex-spacer {
            display: none; } }
      
      .section8-flex-item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1 1 360px; }
        @media (max-width: 767px) {
          .section8-flex-item1 {
            flex: 0 0 100%; } }
      
      .section8-image13 {
        background: var(--src) center center/contain no-repeat; }
      
      .section8-image13.layout {
        position: relative;
        flex-grow: 1;
        min-height: 460px;
        flex-shrink: 0;
        margin: 0px 0px 5px; }
      
        .section8-image14.layout {
          
          background-image: url('/img/assets/9646431631d4590bf0c6a3755c9ad695.png');
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          
          position: relative;
          flex-grow: 1;
          min-height: 460px;
          flex-shrink: 0;
          margin: 0px 0px 5px; }
        
      
        .button-read-message:hover {
          background-position: bottom;
          color: #fff;
      }
      a:hover {
          color: #0a58ca;
      }
      .button-read-message {
          background: linear-gradient(
      0deg
      ,#1b9cf2 50%,#fff 0);
          background-position: top;
          background-size: 100% 200%;
          border: 2px solid #1b9cf2;
          border-radius: 0;
          color: #03153b;
          font-family: Be Vietnam Pro Bold,sans-serif;
          font-size: 14px;
          padding: 16px 87px;
          text-decoration: none;
          text-transform: uppercase;
          transition: background-position .3s ease-out;
      }
      
      
      
      .button-download:hover {
        background-position: bottom;
        color: #fff;
      }
      
      .button-download {
        background: linear-gradient(
      0deg
      ,#1b9cf2 50%,#fff 0);
        background-position: top;
        background-size: 100% 200%;
        border: 2px solid #1b9cf2;
        border-radius: 0;
        color: #03153b;
        font-family: Be Vietnam Pro Bold,sans-serif;
        font-size: 14px;
        padding: 16px 60px;
        text-decoration: none;
        text-transform: uppercase;
        transition: background-position .3s ease-out;
      }  
    
}

.section_ldg_highlight_block_see_complete_accounts_report {
    background-color: #f3f3f3;
    color: #031d56;
    font-family: "Be Vietnam Pro", Helvetica, Arial, serif;
    letter-spacing: 0;
    .container {
        @media (min-width:454px) and (max-width: 1498px) {
            padding-right: var(--bs-gutter-x,62px) !important;
            padding-left: var(--bs-gutter-x,62px) !important;
        }
        .row 
        {
            &.title 
            {
                flex-direction: column;
                justify-content: center;
                text-align: center;
                height: 70px;
                background-color: #F5F5F5;
                align-items: center;
                p {
                    margin-bottom: 0;
                }
            }
        }
    }

    /* SECTION 9 */
    .section9-block {
        display: flex;
        flex-direction: column;
        background: var(--src) center center/cover no-repeat;
        background-color: #f3f3f3; }
      
      .section9-block.layout {
        position: relative;
        overflow: hidden;
        min-height: 250px;
        flex-shrink: 0; }
      
      .section9-flex {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        height: 100%; }
      
      .section9-medium-title {
        display: flex;
        justify-content: center;
        font: 700 24px/1.25 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: #031d56;
        text-align: center;
        letter-spacing: 0px; }
        @media (max-width: 1199px) {
          .section9-medium-title {
            font-size: 22px;
            text-align: center; } }
        @media (max-width: 991px) {
          .section9-medium-title {
            font-size: 20px; } }
        @media (max-width: 767px) {
          .section9-medium-title {
            font-size: 19px; } }
        @media (max-width: 575px) {
          .section9-medium-title {
            font-size: 18px; } }
        @media (max-width: 479px) {
          .section9-medium-title {
            font-size: 17px; } }
        @media (max-width: 383px) {
          .section9-medium-title {
            font-size: 16px; } }
      
      .section9-medium-title.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        margin: 3px 0px 0px; }
        @media (max-width: 1199px) {
          .section9-medium-title.layout {
            margin: 5px 0px 0px; } }
      
      .section9-flex-item {
        display: flex;
        flex-direction: column;
        position: relative; }
      
      .section9-flex1-spacer1 {
          flex: 0 0 auto;
          min-height: 43px; }
        
      .section9-cover-block {
        display: flex;
        flex-direction: column;
        background-color: white;
        /*border: 2px solid #1b9cf2; */}
      
      .section9-cover-block.layout {
        position: relative;
        flex-grow: 1;
        min-height: 50px;
        flex-shrink: 0;
        min-width: 207px;
        margin: 1px auto 0px; }
      
      .section9-text-body {
        display: flex;
        justify-content: center;
        font: 700 14px/1.42 "Be Vietnam Pro", Helvetica, Arial, serif;
        color: #031e56;
        text-align: center;
        letter-spacing: 0px; }
        @media (max-width: 991px) {
          .section9-text-body {
            font-size: 13px;
            text-align: center; } }
        @media (max-width: 575px) {
          .section9-text-body {
            font-size: 12px; } }
      
      .section9-text-body.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        min-width: 0px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        /*margin: 13px auto 17px;*/ }
      
      .section9-flex1 {
        display: flex; }
      
      .section9-flex1.layout {
        position: absolute;
        top: 11px;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        bottom: 11px;
        /*left: 24px;*/
        right: 20px; /*24px;*/ }
      
      .section9-flex1-item {
        display: flex;
        flex-direction: column;
        position: relative;
        /*flex: 1 1 192px;*/ }
      
      .section9-group {
        display: flex;
        flex-direction: column; }
      
      .section9-group.layout {
        position: relative;
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
        /*margin: 0px 0px 0px 168px; */ }
      
      .section9-image1 {
        background: var(--src) center center/contain no-repeat; }
      
      .section9-image1.layout {
        position: absolute;
        top: 0px;
        height: 12px;
        left: 0px;
        width: 10px;
        min-width: 10px;
        margin: 16px 0px 0px 14px; }
      
      .section9-image2 {
        background: var(--src) center center/contain no-repeat; }
      
      .section9-image2.layout {
        position: relative;
        height: 28px;
        width: 20px;
        min-width: 20px;
        margin: 0px 4px 0px 0px; }
      
        .button-read-message:hover {
          background-position: bottom;
          color: #fff;
      }
      a:hover {
          color: #0a58ca;
      }
      .button-read-message {
          background: linear-gradient(
      0deg
      ,#1b9cf2 50%,#fff 0);
          background-position: top;
          background-size: 100% 200%;
          border: 2px solid #1b9cf2;
          border-radius: 0;
          color: #03153b;
          font-family: Be Vietnam Pro Bold,sans-serif;
          font-size: 14px;
          padding: 16px 50px;
          text-decoration: none;
          text-transform: uppercase;
          transition: background-position .3s ease-out;
      }
      
      .button-download:hover {
        background-position: bottom;
        color: #fff;
      }
      
      .button-download {
        background: linear-gradient(
      0deg
      ,#1b9cf2 50%,#fff 0);
        background-position: top;
        background-size: 100% 200%;
        border: 2px solid #1b9cf2;
        border-radius: 0;
        color: #03153b;
        font-family: Be Vietnam Pro Bold,sans-serif;
        font-size: 14px;
        padding: 16px 60px;
        text-decoration: none;
        text-transform: uppercase;
        transition: background-position .3s ease-out;
      }      
}

/* SECTION 1 */
.section1-block {
    display: flex;
    flex-direction: column;
    background-color: white; }
  
  .section1-block.layout {
    position: relative;
    overflow: hidden;
    min-height: 500px;
    flex-shrink: 0; }
  
  .section1-flex {
    display: flex;
    flex-direction: column;
    background: var(--src) center center/cover no-repeat; }
  
  .section1-flex.layout {
    position: relative;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content; }
  
  .section1-block2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; }
  
  .section1-block3 {
    display: flex;
    flex-direction: column; }
  
  .section1-block3.layout {
    position: relative;
    height: 8px;
    margin: 16px 8px 16px 10px; }
  
  .section1-box9 {
    background-color: white; }
  
  .section1-box9.layout {
    position: absolute;
    height: 3px;
    bottom: 7px;
    left: 0px;
    width: 22px; }
  
  .section1-box9.layout1 {
    position: absolute;
    top: 7px;
    height: 3px;
    left: 0px;
    width: 22px; }
  
  .section1-flex1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; }
  
  .section1-image7 {
    background: var(--src) center center/contain no-repeat; }
  
  .section1-image7.layout {
    position: relative;
    min-height: 49px;
    flex-shrink: 0;
    width: 28.53%;
    margin: 0px auto; }
    @media (max-width: 479px) {
      .section1-image7.layout {
        width: 91.74%; } }
  
  .section1-hero-title2 {
    display: flex;
    justify-content: center;
    font: 300 40px/1.75 "IBM Plex Mono", Helvetica, Arial, serif;
    color: white;
    text-align: center;
    text-decoration: underline;
    letter-spacing: 0px;
    width: 100%;
    height: 100%; }
    @media (max-width: 1199px) {
      .section1-hero-title2 {
        font-size: 36px;
        text-align: center; } }
    @media (max-width: 991px) {
      .section1-hero-title2 {
        font-size: 32px; } }
    @media (max-width: 767px) {
      .section1-hero-title2 {
        font-size: 28px; } }
    @media (max-width: 575px) {
      .section1-hero-title2 {
        font-size: 26px; } }
    @media (max-width: 479px) {
      .section1-hero-title2 {
        font-size: 24px; } }
    @media (max-width: 383px) {
      .section1-hero-title2 {
        font-size: 23px; } }
  
  .section1-highlights1 {
    display: flex;
    justify-content: center;
    font: 700 18px/1.2 "Be Vietnam Pro", Helvetica, Arial, serif;
    color: #00b2ff;
    text-align: center;
    letter-spacing: 1.8px; }
    @media (max-width: 1199px) {
      .section1-highlights1 {
        font-size: 17px;
        text-align: center; } }
    @media (max-width: 991px) {
      .section1-highlights1 {
        font-size: 16px; } }
    @media (max-width: 767px) {
      .section1-highlights1 {
        font-size: 15px; } }
    @media (max-width: 479px) {
      .section1-highlights1 {
        font-size: 14px; } }
  
  .section1-highlights1.layout {
    position: relative;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    width: 33.8%;
    margin: 23px auto 0px; }
    @media (max-width: 1199px) {
      .section1-highlights1.layout {
        width: 100%;
        margin: 20px 0px 0px; } }
    @media (max-width: 991px) {
      .section1-highlights1.layout {
        margin: 17px 0px 0px; } }
    @media (max-width: 767px) {
      .section1-highlights1.layout {
        margin: 14px 0px 0px; } }
    @media (max-width: 575px) {
      .section1-highlights1.layout {
        margin: 13px 0px 0px; } }
    @media (max-width: 479px) {
      .section1-highlights1.layout {
        margin: 12px 0px 0px; } }
    @media (max-width: 383px) {
      .section1-highlights1.layout {
        margin: 11px 0px 0px; } }
  