.ff-ibmplexmono100 { font-family: 'IBM Plex Mono Thin', sans-serif; }
.ff-ibmplexmono200 { font-family: 'IBM Plex Mono ExtraLight', sans-serif; }
.ff-ibmplexmono300 { font-family: 'IBM Plex Mono Light', sans-serif; }
.ff-ibmplexmono { font-family: 'IBM Plex Mono', sans-serif; }
.ff-ibmplexmono500 { font-family: 'IBM Plex Mono Medium', sans-serif; }
.ff-ibmplexmono600 { font-family: 'IBM Plex Mono SemiBold', sans-serif; }
.ff-ibmplexmono700 { font-family: 'IBM Plex Mono Bold', sans-serif; }

.ff-ibmplexsans100 { font-family: 'IBM Plex Sans Thin', sans-serif; }
.ff-ibmplexsans200 { font-family: 'IBM Plex Sans ExtraLight', sans-serif; }
.ff-ibmplexsans300 { font-family: 'IBM Plex Sans Light', sans-serif; }
.ff-ibmplexsans { font-family: 'IBM Plex Sans', sans-serif; }
.ff-ibmplexsans500 { font-family: 'IBM Plex Sans Medium', sans-serif; }
.ff-ibmplexsans600 { font-family: 'IBM Plex Sans SemiBold', sans-serif; }
.ff-ibmplexsans700 { font-family: 'IBM Plex Sans Bold', sans-serif; }

.ff-bevietmanpro100 { font-family: 'Be Vietnam Pro Thin', sans-serif; }
.ff-bevietmanpro200 { font-family: 'Be Vietnam Pro ExtraLight', sans-serif; }
.ff-bevietmanpro300 { font-family: 'Be Vietnam Pro Light', sans-serif; }
.ff-bevietmanpro { font-family: 'Be Vietnam Pro', sans-serif; }
.ff-bevietmanpro500 { font-family: 'Be Vietnam Pro Medium', sans-serif; }
.ff-bevietmanpro600 { font-family: 'Be Vietnam Pro SemiBold', sans-serif; }
.ff-bevietmanpro700 { font-family: 'Be Vietnam Pro Bold', sans-serif; }
.ff-bevietmanpro800 { font-family: 'Be Vietnam Pro ExtraBold', sans-serif; }
.ff-bevietmanpro900 { font-family: 'Be Vietnam Pro Black', sans-serif; }

.fs-10px { font-size: 10px; &-xs { @media (max-width: $sm) { font-size: 10px !important; } } }
.fs-11px { font-size: 11px; &-xs { @media (max-width: $sm) { font-size: 11px !important; } } }
.fs-12px { font-size: 12px; &-xs { @media (max-width: $sm) { font-size: 12px !important; } } }
.fs-13px { font-size: 13px; &-xs { @media (max-width: $sm) { font-size: 13px !important; } } }
.fs-14px { font-size: 14px; &-xs { @media (max-width: $sm) { font-size: 14px !important; } } }
.fs-15px { font-size: 15px; &-xs { @media (max-width: $sm) { font-size: 15px !important; } } }
.fs-16px { font-size: 16px; &-xs { @media (max-width: $sm) { font-size: 16px !important; } } }
.fs-17px { font-size: 17px; &-xs { @media (max-width: $sm) { font-size: 17px !important; } } }
.fs-18px { font-size: 18px; &-xs { @media (max-width: $sm) { font-size: 18px !important; } } }
.fs-19px { font-size: 19px; &-xs { @media (max-width: $sm) { font-size: 19px !important; } } }
.fs-20px { font-size: 20px; &-xs { @media (max-width: $sm) { font-size: 20px !important; } } }
.fs-21px { font-size: 21px; &-xs { @media (max-width: $sm) { font-size: 21px !important; } } }
.fs-22px { font-size: 22px; &-xs { @media (max-width: $sm) { font-size: 22px !important; } } }
.fs-23px { font-size: 23px; &-xs { @media (max-width: $sm) { font-size: 23px !important; } } }
.fs-24px { font-size: 24px; &-xs { @media (max-width: $sm) { font-size: 24px !important; } } }
.fs-25px { font-size: 25px; &-xs { @media (max-width: $sm) { font-size: 25px !important; } } }
.fs-26px { font-size: 26px; &-xs { @media (max-width: $sm) { font-size: 26px !important; } } }
.fs-27px { font-size: 27px; &-xs { @media (max-width: $sm) { font-size: 27px !important; } } }
.fs-28px { font-size: 28px; &-xs { @media (max-width: $sm) { font-size: 28px !important; } } }
.fs-30px { font-size: 30px; &-xs { @media (max-width: $sm) { font-size: 30px !important; } } }
.fs-31px { font-size: 31px; &-xs { @media (max-width: $sm) { font-size: 31px !important; } } }
.fs-32px { font-size: 32px; &-xs { @media (max-width: $sm) { font-size: 32px !important; } } }
.fs-33px { font-size: 33px; &-xs { @media (max-width: $sm) { font-size: 33px !important; } } }
.fs-34px { font-size: 34px; &-xs { @media (max-width: $sm) { font-size: 34px !important; } } }
.fs-35px { font-size: 35px; &-xs { @media (max-width: $sm) { font-size: 35px !important; } } }
.fs-36px { font-size: 36px; &-xs { @media (max-width: $sm) { font-size: 36px !important; } } }
.fs-37px { font-size: 37px; &-xs { @media (max-width: $sm) { font-size: 37px !important; } } }
.fs-38px { font-size: 38px; &-xs { @media (max-width: $sm) { font-size: 38px !important; } } }
.fs-39px { font-size: 39px; &-xs { @media (max-width: $sm) { font-size: 39px !important; } } }
.fs-40px { font-size: 40px; &-xs { @media (max-width: $sm) { font-size: 40px !important; } } }
.fs-41px { font-size: 41px; &-xs { @media (max-width: $sm) { font-size: 41px !important; } } }
.fs-42px { font-size: 42px; &-xs { @media (max-width: $sm) { font-size: 42px !important; } } }
.fs-43px { font-size: 43px; &-xs { @media (max-width: $sm) { font-size: 43px !important; } } }
.fs-44px { font-size: 44px; &-xs { @media (max-width: $sm) { font-size: 44px !important; } } }
.fs-45px { font-size: 45px; &-xs { @media (max-width: $sm) { font-size: 45px !important; } } }
.fs-46px { font-size: 46px; &-xs { @media (max-width: $sm) { font-size: 46px !important; } } }
.fs-47px { font-size: 47px; &-xs { @media (max-width: $sm) { font-size: 47px !important; } } }
.fs-48px { font-size: 48px; &-xs { @media (max-width: $sm) { font-size: 48px !important; } } }
.fs-49px { font-size: 49px; &-xs { @media (max-width: $sm) { font-size: 49px !important; } } }
.fs-50px { font-size: 50px; &-xs { @media (max-width: $sm) { font-size: 50px !important; } } }
.fs-51px { font-size: 51px; &-xs { @media (max-width: $sm) { font-size: 51px !important; } } }
.fs-52px { font-size: 52px; &-xs { @media (max-width: $sm) { font-size: 52px !important; } } }
.fs-53px { font-size: 53px; &-xs { @media (max-width: $sm) { font-size: 53px !important; } } }
.fs-54px { font-size: 54px; &-xs { @media (max-width: $sm) { font-size: 54px !important; } } }
.fs-55px { font-size: 55px; &-xs { @media (max-width: $sm) { font-size: 55px !important; } } }
.fs-56px { font-size: 56px; &-xs { @media (max-width: $sm) { font-size: 56px !important; } } }
.fs-57px { font-size: 57px; &-xs { @media (max-width: $sm) { font-size: 57px !important; } } }
.fs-58px { font-size: 58px; &-xs { @media (max-width: $sm) { font-size: 58px !important; } } }
.fs-59px { font-size: 59px; &-xs { @media (max-width: $sm) { font-size: 59px !important; } } }
.fs-60px { font-size: 60px; &-xs { @media (max-width: $sm) { font-size: 60px !important; } } }
.fs-61px { font-size: 61px; &-xs { @media (max-width: $sm) { font-size: 61px !important; } } }
.fs-62px { font-size: 62px; &-xs { @media (max-width: $sm) { font-size: 62px !important; } } }
.fs-63px { font-size: 63px; &-xs { @media (max-width: $sm) { font-size: 63px !important; } } }
.fs-64px { font-size: 64px; &-xs { @media (max-width: $sm) { font-size: 64px !important; } } }
.fs-65px { font-size: 65px; &-xs { @media (max-width: $sm) { font-size: 65px !important; } } }
.fs-66px { font-size: 66px; &-xs { @media (max-width: $sm) { font-size: 66px !important; } } }
.fs-67px { font-size: 67px; &-xs { @media (max-width: $sm) { font-size: 67px !important; } } }
.fs-68px { font-size: 68px; &-xs { @media (max-width: $sm) { font-size: 68px !important; } } }
.fs-69px { font-size: 69px; &-xs { @media (max-width: $sm) { font-size: 69px !important; } } }
.fs-70px { font-size: 70px; &-xs { @media (max-width: $sm) { font-size: 70px !important; } } }
.fs-71px { font-size: 71px; &-xs { @media (max-width: $sm) { font-size: 71px !important; } } }
.fs-72px { font-size: 72px; &-xs { @media (max-width: $sm) { font-size: 72px !important; } } }
.fs-73px { font-size: 73px; &-xs { @media (max-width: $sm) { font-size: 73px !important; } } }
.fs-74px { font-size: 74px; &-xs { @media (max-width: $sm) { font-size: 74px !important; } } }
.fs-75px { font-size: 75px; &-xs { @media (max-width: $sm) { font-size: 75px !important; } } }
.fs-76px { font-size: 76px; &-xs { @media (max-width: $sm) { font-size: 76px !important; } } }
.fs-77px { font-size: 77px; &-xs { @media (max-width: $sm) { font-size: 77px !important; } } }
.fs-78px { font-size: 78px; &-xs { @media (max-width: $sm) { font-size: 78px !important; } } }
.fs-79px { font-size: 79px; &-xs { @media (max-width: $sm) { font-size: 79px !important; } } }
.fs-80px { font-size: 80px; &-xs { @media (max-width: $sm) { font-size: 80px !important; } } }
.fs-81px { font-size: 81px; &-xs { @media (max-width: $sm) { font-size: 81px !important; } } }
.fs-82px { font-size: 82px; &-xs { @media (max-width: $sm) { font-size: 82px !important; } } }
.fs-83px { font-size: 83px; &-xs { @media (max-width: $sm) { font-size: 83px !important; } } }
.fs-84px { font-size: 84px; &-xs { @media (max-width: $sm) { font-size: 84px !important; } } }
.fs-85px { font-size: 85px; &-xs { @media (max-width: $sm) { font-size: 85px !important; } } }
.fs-87px { font-size: 86px; &-xs { @media (max-width: $sm) { font-size: 86px !important; } } }
.fs-87px { font-size: 87px; &-xs { @media (max-width: $sm) { font-size: 87px !important; } } }
.fs-88px { font-size: 88px; &-xs { @media (max-width: $sm) { font-size: 88px !important; } } }
.fs-89px { font-size: 89px; &-xs { @media (max-width: $sm) { font-size: 89px !important; } } }
.fs-90px { font-size: 90px; &-xs { @media (max-width: $sm) { font-size: 90px !important; } } }
.fs-91px { font-size: 91px; &-xs { @media (max-width: $sm) { font-size: 91px !important; } } }
.fs-92px { font-size: 92px; &-xs { @media (max-width: $sm) { font-size: 92px !important; } } }
.fs-93px { font-size: 93px; &-xs { @media (max-width: $sm) { font-size: 93px !important; } } }
.fs-94px { font-size: 94px; &-xs { @media (max-width: $sm) { font-size: 94px !important; } } }
.fs-95px { font-size: 95px; &-xs { @media (max-width: $sm) { font-size: 95px !important; } } }
.fs-96px { font-size: 96px; &-xs { @media (max-width: $sm) { font-size: 96px !important; } } }
.fs-97px { font-size: 97px; &-xs { @media (max-width: $sm) { font-size: 97px !important; } } }
.fs-98px { font-size: 98px; &-xs { @media (max-width: $sm) { font-size: 98px !important; } } }
.fs-99px { font-size: 99px; &-xs { @media (max-width: $sm) { font-size: 99px !important; } } }
.fs-100px { font-size: 100px; &-xs { @media (max-width: $sm) { font-size: 100px !important; } } }
.fs-110px { font-size: 110px; &-xs { @media (max-width: $sm) { font-size: 110px !important; } } }
.fs-120px { font-size: 120px; &-xs { @media (max-width: $sm) { font-size: 120px !important; } } }
.fs-130px { font-size: 130px; &-xs { @media (max-width: $sm) { font-size: 130px !important; } } }
.fs-140px { font-size: 140px; &-xs { @media (max-width: $sm) { font-size: 140px !important; } } }
.fs-150px { font-size: 150px; &-xs { @media (max-width: $sm) { font-size: 150px !important; } } }
.fs-160px { font-size: 160px; &-xs { @media (max-width: $sm) { font-size: 160px !important; } } }

.ls-2px { letter-spacing: 2px; &-xs { @media (max-width: $sm) { letter-spacing: 2px !important; } } }
.ls-4px { letter-spacing: 4px; &-xs { @media (max-width: $sm) { letter-spacing: 4px !important; } } }
.ls-6px { letter-spacing: 6px; &-xs { @media (max-width: $sm) { letter-spacing: 6px !important; } } }
.ls-8px { letter-spacing: 8px; &-xs { @media (max-width: $sm) { letter-spacing: 8px !important; } } }
.ls-10px { letter-spacing: 10px; &-xs { @media (max-width: $sm) { letter-spacing: 10px !important; } } }
.ls-12px { letter-spacing: 12px; &-xs { @media (max-width: $sm) { letter-spacing: 12px !important; } } }
.ls-14px { letter-spacing: 14px; &-xs { @media (max-width: $sm) { letter-spacing: 14px !important; } } }
.ls-16px { letter-spacing: 16px; &-xs { @media (max-width: $sm) { letter-spacing: 16px !important; } } }
.ls-18px { letter-spacing: 18px; &-xs { @media (max-width: $sm) { letter-spacing: 18px !important; } } }
.ls-20px { letter-spacing: 20px; &-xs { @media (max-width: $sm) { letter-spacing: 20px !important; } } }
.ls-22px { letter-spacing: 22px; &-xs { @media (max-width: $sm) { letter-spacing: 22px !important; } } }
.ls-24px { letter-spacing: 24px; &-xs { @media (max-width: $sm) { letter-spacing: 24px !important; } } }
.ls-26px { letter-spacing: 26px; &-xs { @media (max-width: $sm) { letter-spacing: 26px !important; } } }
.ls-28px { letter-spacing: 28px; &-xs { @media (max-width: $sm) { letter-spacing: 28px !important; } } }
.ls-30px { letter-spacing: 30px; &-xs { @media (max-width: $sm) { letter-spacing: 30px !important; } } }

.lh-2px { line-height: 2px; &-xs { @media (max-width: $sm) { line-height: 2px !important; } } }
.lh-4px { line-height: 4px; &-xs { @media (max-width: $sm) { line-height: 4px !important; } } }
.lh-6px { line-height: 6px; &-xs { @media (max-width: $sm) { line-height: 6px !important; } } }
.lh-8px { line-height: 8px; &-xs { @media (max-width: $sm) { line-height: 8px !important; } } }
.lh-10px { line-height: 10px; &-xs { @media (max-width: $sm) { line-height: 10px !important; } } }
.lh-12px { line-height: 12px; &-xs { @media (max-width: $sm) { line-height: 12px !important; } } }
.lh-14px { line-height: 14px; &-xs { @media (max-width: $sm) { line-height: 14px !important; } } }
.lh-16px { line-height: 16px; &-xs { @media (max-width: $sm) { line-height: 16px !important; } } }
.lh-18px { line-height: 18px; &-xs { @media (max-width: $sm) { line-height: 18px !important; } } }
.lh-20px { line-height: 20px; &-xs { @media (max-width: $sm) { line-height: 20px !important; } } }
.lh-22px { line-height: 22px; &-xs { @media (max-width: $sm) { line-height: 22px !important; } } }
.lh-24px { line-height: 24px; &-xs { @media (max-width: $sm) { line-height: 24px !important; } } }
.lh-26px { line-height: 26px; &-xs { @media (max-width: $sm) { line-height: 26px !important; } } }
.lh-28px { line-height: 28px; &-xs { @media (max-width: $sm) { line-height: 28px !important; } } }
.lh-30px { line-height: 30px; &-xs { @media (max-width: $sm) { line-height: 30px !important; } } }
.lh-32px { line-height: 32px; &-xs { @media (max-width: $sm) { line-height: 32px !important; } } }
.lh-34px { line-height: 34px; &-xs { @media (max-width: $sm) { line-height: 34px !important; } } }
.lh-100px { line-height: 100px; &-xs { @media (max-width: $sm) { line-height: 100px !important; } } }

.uppercase { text-transform: uppercase; }
.capitalize { text-transform: capitalize; }
