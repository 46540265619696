//Parallax
.bg-paralax {
  height: 100%;
  width: 100%;
  position: fixed;
  background-attachment: fixed;
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @include media-breakpoint-down($sm) {
    height: 100%;
    background-position: center center;
  }
}

//Main Slider
.section_about_slider {
  .container-large {
    @media (min-width:454px) and (max-width: 1674px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
  }
  background-color: rgba(3, 21, 59, 0.85);
  &.green {
    background-color: rgba(5, 58, 60, 0.80);
  }
  .d-lg-flex{
    padding-right: 0;
  }

  .button-main-slider.bg-blue{
    background-color: #1B9CF2;
  }

  @include media-breakpoint-down($lg){
    .button-main-slider{
      margin-bottom: 10px;
    }
  }
}

.splide {
  &.about {
    margin-top: -70px;
    .splide__track {
      .splide__list {
        .splide__slide {
          min-height: 543px;
          .row {
            height: 100%;
            >div:nth-of-type(1) {
              display: flex;
              flex-direction: column;
              align-self: center;
              align-items: center;
              h6, h4 {
                width: 100%;
                @include media-breakpoint-up($sm) {
                  width: 567px;
                }
              }

              @media (min-width:454px) and (max-width: 1300px) {
                padding-left: 62px;
              }
              @include media-breakpoint-down($xl){
                align-items: start;
                h4{
                  font-size: 18px;
                }
              }
              @include media-breakpoint-down($sm){
                h4{
                  font-size: 16px;
                }
              }
            }
            >div:nth-of-type(2) {
              img {
                max-width: 930px;
                width: 100%;
              }
              .content {
                line-height: 0;
                .counters {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 2.2vw;
                  font-size: 6vw;
                  &:last-of-type {
                    margin-bottom: 0;
                  }
                  .c-number, .c-number-t {
                    display: inline-block;
                    font-family: 'IBM Plex Sans Thin', sans-serif;
                    text-transform: uppercase;
                    color: #ffffff;
                    line-height: 62px;
                  }
                  .c-text {
                    font-family: 'Be Vietnam Pro', sans-serif;
                    text-transform: uppercase;
                    color: #1B9CF2;
                  }
                }
              }
            }
            >div:nth-of-type(3) {
              >.container-large {
                >.row {
                  >div {
                    padding-right: 15px;
                    padding-left: 0;
                    &:last-of-type {
                      padding-right: 0;
                    }
                    @include media-breakpoint-down($lg) {
                      padding-right: 0;
                    }
                  }
                }
              }
            }
          }
          .hr-white {
            background: #ffffff;
            height: 88px;
            margin-top: -87px;
            @include media-breakpoint-down($md) {
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

//Sticky Element
.sticky {
  &.container {
    padding-right: var(--bs-gutter-x,62px) !important;
    padding-left: var(--bs-gutter-x,62px) !important;
  }
  @include media-breakpoint-down($sm) {
    display: none;
  }
  position: relative;
  margin-top: -24px;
  z-index: 2;
  width: auto;
  a {
    font-family: 'Be Vietnam Pro Bold', sans-serif;
    font-size: calc(10px + 0.21vw);
    color: #03153B;
    padding: 1.682vw 2.102vw;
    border: 1px solid #1B9CF2;
    text-transform: uppercase;
    text-decoration: none;
    background: linear-gradient(to top, #1B9CF2 50%, #fff 50%);
    background-size: 100% 200%;
    background-position: top;
    transition: background-position 0.3s ease-out;
    &:hover {
      background-position: bottom;
      color: #FFFFFF;
    }
  }
  //Sticky Element Mobile Fixed Footer
  &.fixed {
    display: none;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: #102144;
    margin-top: 0;
    transition: height 0.3s ease;
    @include media-breakpoint-down($sm) {
      display: block;
    }
  }
}

//Highlights Slider
.section_highlights_slider {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  background-color: #ffffff;
}
.splide {
  &.highlights {
    width: 100%;
    max-width: 960px;
    .splide__track {
      padding-right: 136px;
      .splide__list {
        .splide__slide {
          overflow: hidden;
          >.row {
            >.col {
              @media (min-width:454px) and (max-width: 1026px) {
                padding-left: 62px;
                padding-right: 62px;
              }
            }
          }
        }
      }
    }
    .splide__pagination {
      top: unset;
      right: 2em;
      li {
        line-height: 3;
        .splide__pagination__page {
          background: #ffffff;
          height: 21px;
          width: 21px;
          opacity: 1;
          transition: all 0.3s ease-in-out;
          &::before {
            content: "";
            background: #FFFFFF;
            width: 19px;
            height: 19px;
            display: block;
            position: absolute;
            border-radius: 50%;
            margin-top: -9px;
            margin-left: 1px;
          }
          &::after {
            content: "";
            background: #032672;
            width: 3px;
            height: 3px;
            display: block;
            position: absolute;
            border-radius: 50%;
            margin-left: 9px;
            margin-top: -1px;
          }
          &.is-active {
            background: rgba(3, 38, 114, 0.4);
            transform: unset;
          }
          &:hover {
            background: rgba(3, 38, 114, 0.4);
            transform: unset;
          }
        }
      }
    }
  }
}

//Objectives Block
.section_objectives {
  background-color: #ffffff;
  .container {
    background-position: center center;
    background-repeat: no-repeat;
    background-blend-mode: luminosity;
    background-color: #0A1734;
    .row {
      height: 100%;
      background-color: rgba(10, 23, 52, .85);
      margin: 0;
      .col {
        display: flex;
        flex-direction: column;
        align-items: center;
        //align-self: center;

        h1{
          @include media-breakpoint-down($sm){
            font-size: 40px;
          }
        }
        .tabs {
          height: 100%;
          width: 924px;
          @media (min-width:454px) and (max-width: 1026px) {
            padding-left: 62px;
            padding-right: 62px;
          }
          @include media-breakpoint-down($lg) {
            width: 100%;
          }
          .nav {
            .nav-item {
              font-family: 'Be Vietnam Pro Bold', sans-serif;
              font-size: 13px;
              letter-spacing: 2px;
              text-transform: uppercase;
              text-align: left;
              .nav-link {
                padding: 15px 12px;
                color: rgba(255, 255, 255, 0.4);
                border-radius: 0;
                &:hover {
                  color: rgba(255, 255, 255, 1);
                }
                &.active {
                  color: rgba(255, 255, 255, 1);
                  background: unset;
                  +.slider {
                    width: 100%;
                  }
                }
              }
              .pre-slider {
                display:block;
                height: 1px;
                background-color: rgba(255, 255, 255, .15);
                width: 100%;
              }
              .slider {
                display:block;
                height: 1px;
                background-color: #ffffff;
                width: 0;
                transition: width 0.3s ease-out;
              }
              &:hover {
                .slider {
                  width: 100%;
                }
              }
            }
          }
          .tab-content {
            margin-top: 86px;
            .tab-pane {
              font-family: 'Be Vietnam Pro Light', sans-serif;
              font-size: 18px;
              color: #ffffff;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
}

//Structure Block
.section_structure {
  background-color: #ffffff;
  .container {
    @media (min-width:454px) and (max-width: 1498px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
    .row {
      &.title {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        height: 70px;
        background-color: #F5F5F5;
        align-items: center;
        p {
          margin-bottom: 0;
        }
      }
      &.cards {
        font-family: 'Be Vietnam Pro Light', sans-serif;
        font-size: 14px;
        color: #03153B;
        margin: 0 -12px;
        @include media-breakpoint-down($sm) {
          height: 550px;
          overflow: scroll;
          display: flex;
          flex-direction: column;
          &:first-of-type {
            padding-left: 0;
          }
          &:last-of-type {
            padding-right: 0;
          }
        }
        @include media-breakpoint-down(400px){
          flex-direction: column;
          margin:0;
        }
        .card {
          margin-top: 30px;
          padding: 10px 10px 20px 36px;
          background-color: #F5F5F5;
          border: 0;
          border-radius: 0;
          .card-body {
            display: flex;
            flex-direction: column;
            white-space: nowrap;
            color: #032672;
            .flag {
              text-align: right;
              display: inline;
              margin-bottom: 8px;
              > img {
                height: 30px;
                width: 30px;
              }
            }
            a {
              white-space: pre-line;
              text-decoration: none;
              color: #1b9cf2;
              &:hover {
                text-decoration: underline;
              }
            }
            span {
              padding: 13px 0;
            }
          }
        }
      }
      &.info {
        div:nth-of-type(2) {
          text-align: right;
        }
      }
    }
  }

}

//Testimonial Block
.section_testimonial {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 550px;
  //background-color: #CDD1D9;
  color: #ffffff;
  font-family: 'Be Vietnam Pro', sans-serif;
  line-height: 28px;
  > .main {
    max-width: 1080px;
    > .text {
      > .quotes {
        &-start {
          //
        }
        &-end {
          float: right;
          margin-top: 140px;

          @include media-breakpoint-down($lg){
            margin-right: 30px;
          }

          @include media-breakpoint-down(400px){
            margin-right: 20px;
          }
        }
      }
      > .sentence {
        max-width: 926px;
        display: inline-block;
        margin-left: 20px;
      }
    }
    > .name {
      margin-top: 40px;
      margin-left: 11rem;
      line-height: 14px;
      @include media-breakpoint-down(400px){
        margin-left: 8rem;
      }
    }
    > .job {
      margin-left: 11rem;

      @include media-breakpoint-down(400px){
        margin-left: 8rem;
      }
    }
  }
}

//History Block
.section_history {
  background-color: #ffffff;
  .container {
    padding-top: 200px;
    padding-bottom: 240px;
    @media (min-width:454px) and (max-width: 1498px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
    .row.title {
      .col {
        h1 {
          font-family: 'Be Vietnam Pro Bold', sans-serif;
          font-size: 40px;
          color: #03153B;
          width: 100%;
          text-align: right;
        }
      }
    }
    .row.cards {
      //margin-top: 90px;
      .col {
        overflow-x: auto;
        white-space: nowrap;
        height: 446px;
        .card {
          width: 195px;
          border: 0;
          border-radius: 0;
          display: inline-flex;
          float: none;
          margin-right: 45px;
          &:last-of-type {
            margin-right: 0;
          }
          .year {
            font-family: 'Be Vietnam Pro Bold', sans-serif;
            font-size: 60px;
            line-height: 60px;
            font-style: italic;
            color: #F5F5F5;
          }
          .img {
            height: 150px;
            background-size: 195px 150px;
            background-repeat: no-repeat;
            background-position: top left;
          }
          .card-body {
            padding: 1rem 0;
            white-space: normal;
          }
        }
      }
    }
  }
}

//adding overlay to quote
.section_testimonial{
  background-color: rgba(3, 21, 59, .85);
}

//About Main Slider Full Internal Slider (only text vertical)
.splide.internal {
  > .splide__track{
    padding-top: 8px!important;
  }
}
