.px-0 { &-xs { @media (max-width: $sm) { padding-left: 0 !important; padding-right: 0 !important; } } }
.pt-0 { &-xs { @media (max-width: $sm) { padding-top: 0 !important; } } }
.pt-10px { padding-top: 10px; &-xs { @media (max-width: $sm) { padding-top: 10px !important; } } }
.pt-12px { padding-top: 12px; &-xs { @media (max-width: $sm) { padding-top: 12px !important; } } }
.pt-15px { padding-top: 15px; &-xs { @media (max-width: $sm) { padding-top: 15px !important; } } }
.pt-20px { padding-top: 20px; &-xs { @media (max-width: $sm) { padding-top: 20px !important; } } }
.pt-24px { padding-top: 24px; &-xs { @media (max-width: $sm) { padding-top: 24px !important; } } }
.pt-30px { padding-top: 30px; &-xs { @media (max-width: $sm) { padding-top: 30px !important; } } }
.pt-40px { padding-top: 40px; &-xs { @media (max-width: $sm) { padding-top: 40px !important; } } }
.pt-50px { padding-top: 50px; &-xs { @media (max-width: $sm) { padding-top: 50px !important; } } }
.pt-60px { padding-top: 60px; &-xs { @media (max-width: $sm) { padding-top: 60px !important; } } }
.pt-70px { padding-top: 70px; &-xs { @media (max-width: $sm) { padding-top: 70px !important; } } }
.pt-80px { padding-top: 80px; &-xs { @media (max-width: $sm) { padding-top: 80px !important; } } }
.pt-90px { padding-top: 90px; &-xs { @media (max-width: $sm) { padding-top: 90px !important; } } }
.pt-100px { padding-top: 100px; &-xs { @media (max-width: $sm) { padding-top: 100px !important; } } }
.pt-110px { padding-top: 110px; &-xs { @media (max-width: $sm) { padding-top: 110px !important; } } }
.pt-120px { padding-top: 120px; &-xs { @media (max-width: $sm) { padding-top: 120px !important; } } }
.pt-130px { padding-top: 130px; &-xs { @media (max-width: $sm) { padding-top: 130px !important; } } }
.pt-135px { padding-top: 135px; &-xs { @media (max-width: $sm) { padding-top: 135px !important; } } }
.pt-140px { padding-top: 140px; &-xs { @media (max-width: $sm) { padding-top: 140px !important; } } }
.pt-150px { padding-top: 150px; &-xs { @media (max-width: $sm) { padding-top: 150px !important; } } }
.pt-160px { padding-top: 160px; &-xs { @media (max-width: $sm) { padding-top: 160px !important; } } }
.pt-170px { padding-top: 170px; &-xs { @media (max-width: $sm) { padding-top: 170px !important; } } }
.pt-180px { padding-top: 180px; &-xs { @media (max-width: $sm) { padding-top: 180px !important; } } }
.pt-190px { padding-top: 190px; &-xs { @media (max-width: $sm) { padding-top: 190px !important; } } }
.pt-200px { padding-top: 200px; &-xs { @media (max-width: $sm) { padding-top: 200px !important; } } }
.pt-210px { padding-top: 210px; &-xs { @media (max-width: $sm) { padding-top: 210px !important; } } }
.pt-220px { padding-top: 220px; &-xs { @media (max-width: $sm) { padding-top: 220px !important; } } }
.pt-230px { padding-top: 230px; &-xs { @media (max-width: $sm) { padding-top: 230px !important; } } }
.pt-240px { padding-top: 240px; &-xs { @media (max-width: $sm) { padding-top: 240px !important; } } }
.pt-250px { padding-top: 250px; &-xs { @media (max-width: $sm) { padding-top: 250px !important; } } }
.pt-260px { padding-top: 260px; &-xs { @media (max-width: $sm) { padding-top: 260px !important; } } }
.pt-270px { padding-top: 270px; &-xs { @media (max-width: $sm) { padding-top: 270px !important; } } }
.pt-280px { padding-top: 280px; &-xs { @media (max-width: $sm) { padding-top: 280px !important; } } }
.pt-290px { padding-top: 290px; &-xs { @media (max-width: $sm) { padding-top: 290px !important; } } }
.pt-460px { padding-top: 460px; &-xs { @media (max-width: $sm) { padding-top: 460px !important; } } }
.pt-660px { padding-top: 34.682vw; &-xs { @media (max-width: $sm) { padding-top: 34.682vw !important; } } }
.pb-0 { &-xs { @media (max-width: $sm) { padding-bottom: 0 !important; } } }
.pb-10px { padding-bottom: 10px; &-xs { @media (max-width: $sm) { padding-bottom: 10px !important; } } }
.pb-12px { padding-bottom: 12px; &-xs { @media (max-width: $sm) { padding-bottom: 12px !important; } } }
.pb-15px { padding-bottom: 15px; &-xs { @media (max-width: $sm) { padding-bottom: 15px !important; } } }
.pb-20px { padding-bottom: 20px; &-xs { @media (max-width: $sm) { padding-bottom: 20px !important; } } }
.pb-24px { padding-bottom: 24px; &-xs { @media (max-width: $sm) { padding-bottom: 24px !important; } } }
.pb-30px { padding-bottom: 30px; &-xs { @media (max-width: $sm) { padding-bottom: 30px !important; } } }
.pb-40px { padding-bottom: 40px; &-xs { @media (max-width: $sm) { padding-bottom: 40px !important; } } }
.pb-50px { padding-bottom: 50px; &-xs { @media (max-width: $sm) { padding-bottom: 50px !important; } } }
.pb-60px { padding-bottom: 60px; &-xs { @media (max-width: $sm) { padding-bottom: 60px !important; } } }
.pb-70px { padding-bottom: 70px; &-xs { @media (max-width: $sm) { padding-bottom: 70px !important; } } }
.pb-80px { padding-bottom: 80px; &-xs { @media (max-width: $sm) { padding-bottom: 80px !important; } } }
.pb-90px { padding-bottom: 90px; &-xs { @media (max-width: $sm) { padding-bottom: 90px !important; } } }
.pb-100px { padding-bottom: 100px; &-xs { @media (max-width: $sm) { padding-bottom: 100px !important; } } }
.pb-110px { padding-bottom: 110px; &-xs { @media (max-width: $sm) { padding-bottom: 110px !important; } } }
.pb-120px { padding-bottom: 120px; &-xs { @media (max-width: $sm) { padding-bottom: 120px !important; } } }
.pb-130px { padding-bottom: 130px; &-xs { @media (max-width: $sm) { padding-bottom: 130px !important; } } }
.pb-140px { padding-bottom: 140px; &-xs { @media (max-width: $sm) { padding-bottom: 140px !important; } } }
.pb-150px { padding-bottom: 150px; &-xs { @media (max-width: $sm) { padding-bottom: 150px !important; } } }
.pb-160px { padding-bottom: 160px; &-xs { @media (max-width: $sm) { padding-bottom: 160px !important; } } }
.pb-170px { padding-bottom: 170px; &-xs { @media (max-width: $sm) { padding-bottom: 170px !important; } } }
.pb-180px { padding-bottom: 180px; &-xs { @media (max-width: $sm) { padding-bottom: 180px !important; } } }
.pb-190px { padding-bottom: 190px; &-xs { @media (max-width: $sm) { padding-bottom: 190px !important; } } }
.pb-200px { padding-bottom: 200px; &-xs { @media (max-width: $sm) { padding-bottom: 200px !important; } } }
.pb-210px { padding-bottom: 210px; &-xs { @media (max-width: $sm) { padding-bottom: 210px !important; } } }
.pb-220px { padding-bottom: 220px; &-xs { @media (max-width: $sm) { padding-bottom: 220px !important; } } }
.pb-230px { padding-bottom: 230px; &-xs { @media (max-width: $sm) { padding-bottom: 230px !important; } } }
.pb-270px { padding-bottom: 270px; &-xs { @media (max-width: $sm) { padding-bottom: 270px !important; } } }
.pl-0 { &-xs { @media (max-width: $sm) { padding-left: 0 !important; } } }
.pl-10px { padding-left: 10px; &-xs { @media (max-width: $sm) { padding-left: 10px !important; } } }
.pl-15px { padding-left: 15px; &-xs { @media (max-width: $sm) { padding-left: 15px !important; } } }
.pl-20px { padding-left: 20px; &-xs { @media (max-width: $sm) { padding-left: 20px !important; } } }
.pl-30px { padding-left: 30px; &-xs { @media (max-width: $sm) { padding-left: 30px !important; } } }
.pl-40px { padding-left: 40px; &-xs { @media (max-width: $sm) { padding-left: 40px !important; } } }
.pl-50px { padding-left: 50px; &-xs { @media (max-width: $sm) { padding-left: 50px !important; } } }
.pl-60px { padding-left: 60px; &-xs { @media (max-width: $sm) { padding-left: 60px !important; } } }
.pl-70px { padding-left: 70px; &-xs { @media (max-width: $sm) { padding-left: 70px !important; } } }
.pl-80px { padding-left: 80px; &-xs { @media (max-width: $sm) { padding-left: 80px !important; } } }
.pl-90px { padding-left: 90px; &-xs { @media (max-width: $sm) { padding-left: 90px !important; } } }
.pl-95px { padding-left: 95px; &-xs { @media (max-width: $sm) { padding-left: 95px !important; } } }
.pl-100px { padding-left: 100px; &-xs { @media (max-width: $sm) { padding-left: 100px !important; } } }
.pl-110px { padding-left: 110px; &-xs { @media (max-width: $sm) { padding-left: 110px !important; } } }
.pl-120px { padding-left: 120px; &-xs { @media (max-width: $sm) { padding-left: 120px !important; } } }
.pl-130px { padding-left: 130px; &-xs { @media (max-width: $sm) { padding-left: 130px !important; } } }
.pl-140px { padding-left: 140px; &-xs { @media (max-width: $sm) { padding-left: 140px !important; } } }
.pl-150px { padding-left: 150px; &-xs { @media (max-width: $sm) { padding-left: 150px !important; } } }
.pl-160px { padding-left: 160px; &-xs { @media (max-width: $sm) { padding-left: 160px !important; } } }
.pl-170px { padding-left: 170px; &-xs { @media (max-width: $sm) { padding-left: 170px !important; } } }
.pl-180px { padding-left: 180px; &-xs { @media (max-width: $sm) { padding-left: 180px !important; } } }
.pl-190px { padding-left: 190px; &-xs { @media (max-width: $sm) { padding-left: 190px !important; } } }
.pl-200px { padding-left: 200px; &-xs { @media (max-width: $sm) { padding-left: 200px !important; } } }
.pr-0 { &-xs { @media (max-width: $sm) { padding-right: 0 !important; } } }
.pr-10px { padding-right: 10px; &-xs { @media (max-width: $sm) { padding-right: 10px !important; } } }
.pr-15px { padding-right: 15px; &-xs { @media (max-width: $sm) { padding-right: 15px !important; } } }
.pr-20px { padding-right: 20px; &-xs { @media (max-width: $sm) { padding-right: 20px !important; } } }
.pr-30px { padding-right: 30px !important; &-xs { @media (max-width: $sm) { padding-right: 30px !important; } } }
.pr-40px { padding-right: 40px; &-xs { @media (max-width: $sm) { padding-right: 40px !important; } } }
.pr-50px { padding-right: 50px; &-xs { @media (max-width: $sm) { padding-right: 50px !important; } } }
.pr-60px { padding-right: 60px; &-xs { @media (max-width: $sm) { padding-right: 60px !important; } } }
.pr-70px { padding-right: 70px; &-xs { @media (max-width: $sm) { padding-right: 70px !important; } } }
.pr-80px { padding-right: 80px; &-xs { @media (max-width: $sm) { padding-right: 80px !important; } } }
.pr-90px { padding-right: 90px; &-xs { @media (max-width: $sm) { padding-right: 90px !important; } } }
.pr-100px { padding-right: 100px; &-xs { @media (max-width: $sm) { padding-right: 100px !important; } } }
.pr-110px { padding-right: 110px; &-xs { @media (max-width: $sm) { padding-right: 110px !important; } } }
.pr-120px { padding-right: 120px; &-xs { @media (max-width: $sm) { padding-right: 120px !important; } } }
.pr-130px { padding-right: 130px; &-xs { @media (max-width: $sm) { padding-right: 130px !important; } } }
.pr-140px { padding-right: 140px; &-xs { @media (max-width: $sm) { padding-right: 140px !important; } } }
.pr-150px { padding-right: 150px; &-xs { @media (max-width: $sm) { padding-right: 150px !important; } } }
.pr-160px { padding-right: 160px; &-xs { @media (max-width: $sm) { padding-right: 160px !important; } } }
.pr-170px { padding-right: 170px; &-xs { @media (max-width: $sm) { padding-right: 170px !important; } } }
.pr-180px { padding-right: 180px; &-xs { @media (max-width: $sm) { padding-right: 180px !important; } } }
.pr-190px { padding-right: 190px; &-xs { @media (max-width: $sm) { padding-right: 190px !important; } } }
.pr-200px { padding-right: 200px; &-xs { @media (max-width: $sm) { padding-right: 200px !important; } } }
