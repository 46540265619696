.witdh-0 { width: 0; &-xs { @media (width: $sm) { width: 0 !important; } } }
.witdh-5 { width: 5%; &-xs { @media (width: $sm) { width: 5% !important; } } }
.witdh-10 { width: 10%; &-xs { @media (width: $sm) { width: 10% !important; } } }
.witdh-15 { width: 15%; &-xs { @media (width: $sm) { width: 15% !important; } } }
.witdh-20 { width: 20%; &-xs { @media (width: $sm) { width: 20% !important; } } }
.witdh-25 { width: 25%; &-xs { @media (width: $sm) { width: 25% !important; } } }
.witdh-30 { width: 30%; &-xs { @media (width: $sm) { width: 30% !important; } } }
.witdh-35 { width: 35%; &-xs { @media (width: $sm) { width: 35% !important; } } }
.witdh-40 { width: 40%; &-xs { @media (width: $sm) { width: 40% !important; } } }
.witdh-45 { width: 45%; &-xs { @media (width: $sm) { width: 45% !important; } } }
.witdh-50 { width: 50%; &-xs { @media (width: $sm) { width: 50% !important; } } }
.witdh-55 { width: 55%; &-xs { @media (width: $sm) { width: 55% !important; } } }
.witdh-60 { width: 60%; &-xs { @media (width: $sm) { width: 60% !important; } } }
.witdh-65 { width: 65%; &-xs { @media (width: $sm) { width: 65% !important; } } }
.witdh-70 { width: 70%; &-xs { @media (width: $sm) { width: 70% !important; } } }
.witdh-75 { width: 75%; &-xs { @media (width: $sm) { width: 75% !important; } } }
.witdh-80 { width: 80%; &-xs { @media (width: $sm) { width: 80% !important; } } }
.witdh-85 { width: 85%; &-xs { @media (width: $sm) { width: 85% !important; } } }
.witdh-90 { width: 90%; &-xs { @media (width: $sm) { width: 90% !important; } } }
.witdh-95 { width: 95%; &-xs { @media (width: $sm) { width: 95% !important; } } }
.witdh-100 { width: 100%; &-xs { @media (width: $sm) { width: 100% !important; } } }
.height-0 { height: 0; &-xs { @media (width: $sm) { height: 0 !important; } } }
.height-5 { height: 5%; &-xs { @media (width: $sm) { height: 5% !important; } } }
.height-10 { height: 10%; &-xs { @media (width: $sm) { height: 10% !important; } } }
.height-15 { height: 15%; &-xs { @media (width: $sm) { height: 15% !important; } } }
.height-20 { height: 20%; &-xs { @media (width: $sm) { height: 20% !important; } } }
.height-25 { height: 25%; &-xs { @media (width: $sm) { height: 25% !important; } } }
.height-30 { height: 30%; &-xs { @media (width: $sm) { height: 30% !important; } } }
.height-35 { height: 35%; &-xs { @media (width: $sm) { height: 35% !important; } } }
.height-40 { height: 40%; &-xs { @media (width: $sm) { height: 40% !important; } } }
.height-45 { height: 45%; &-xs { @media (width: $sm) { height: 45% !important; } } }
.height-50 { height: 50%; &-xs { @media (width: $sm) { height: 50% !important; } } }
.height-55 { height: 55%; &-xs { @media (width: $sm) { height: 55% !important; } } }
.height-60 { height: 60%; &-xs { @media (width: $sm) { height: 60% !important; } } }
.height-65 { height: 65%; &-xs { @media (width: $sm) { height: 65% !important; } } }
.height-70 { height: 70%; &-xs { @media (width: $sm) { height: 70% !important; } } }
.height-75 { height: 75%; &-xs { @media (width: $sm) { height: 75% !important; } } }
.height-80 { height: 80%; &-xs { @media (width: $sm) { height: 80% !important; } } }
.height-85 { height: 85%; &-xs { @media (width: $sm) { height: 85% !important; } } }
.height-90 { height: 90%; &-xs { @media (width: $sm) { height: 90% !important; } } }
.height-95 { height: 95%; &-xs { @media (width: $sm) { height: 95% !important; } } }
.height-100 { height: 100%; &-xs { @media (width: $sm) { height: 100% !important; } } }
