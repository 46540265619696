//Main Slider
.section_contacts_slider {
  @media (min-width:454px) and (max-width: 991px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
  background-color: #ffffff;
  figure {
    max-width: 50%;
  }

  .d-xl-flex{
    padding-right: 0;
  }
}

.splide {
  &.contacts {
    .splide__track {
      .splide__list {
        .splide__slide {
          min-height: 543px;
          > .row {
            height: 100%;
            .col-2, .col-3 {
              min-height: 320px;
              flex-direction: column;
              align-self: center;
            }
            > .col-6:nth-of-type(2) {
              img {
                max-width: 930px;
              }
            }
          }
        }
      }
    }
  }
}

//Contact Form
.section_contact_form {
  .container-small {
    @media (min-width:454px) and (max-width: 1250px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
  }
  .container-xsmall {
    @media (min-width:454px) and (max-width: 1026px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
  }
  padding-top: 118px;
  padding-bottom: 140px;
  @include media-breakpoint-down($sm) {
    padding-top: 0;
    padding-bottom: 90px;
  }
  form {
    label {
      font-family: 'Be Vietnam Pro Light', sans-serif;
      font-size: 14px;
      color: #03153B;
    }
    input, textarea, .form-check-input[type=checkbox] {
      border-radius: 0;
      border: 1px solid #EBEBEB;
    }
    button[type="submit"] {
      font-family: 'Be Vietnam Pro Bold', sans-serif;
      font-size: 14px;
      color: #03153B;
      padding: 16px 90px;
      border: 2px solid #1B9CF2;
      text-transform: uppercase;
      text-decoration: none;
      background: linear-gradient(to top, #1B9CF2 50%, #fff 50%);
      background-size: 100% 200%;
      background-position: top;
      transition: background-position 0.3s ease-out;
      &:hover {
        background-position: bottom;
        color: #FFFFFF;
      }
    }
  }
}
