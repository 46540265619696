.section_group_block {
  margin-bottom: -310px;
  @include media-breakpoint-down($sm) {
    margin-bottom: -100px;
  }
}
.section_principles_block {
  .container {
    @media (min-width:454px) and (max-width: 1498px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
  }
}
.section_commitment_block {
  .container {
    @media (min-width:454px) and (max-width: 1498px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
  }
}

.splide {
  &.commitment, &.principles, &.structure, &.history {
    .splide__pagination {
      bottom: unset;
      left: unset;
      padding: unset;
      position: unset;
      right: unset;
      z-index: 1;
      .splide__pagination__page {
        background: #ffffff;
        height: 21px;
        width: 21px;
        opacity: 1;
        transition: all 0.3s ease-in-out;
        &::before {
          content: "";
          background: #FFFFFF;
          width: 19px;
          height: 19px;
          display: block;
          position: absolute;
          border-radius: 50%;
          margin-top: -9px;
          margin-left: 1px;
        }
        &::after {
          content: "";
          background: #032672;
          width: 3px;
          height: 3px;
          display: block;
          position: absolute;
          border-radius: 50%;
          margin-left: 9px;
          margin-top: -1px;
        }
        &.is-active {
          background: rgba(3, 38, 114, 0.4);
          transform: unset;
        }
        &:hover {
          background: rgba(3, 38, 114, 0.4);
          transform: unset;
        }
      }
    }
  }
}
