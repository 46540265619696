//Detail Page
.section_news_detail_slider {
  .container-xsmall {
    @media (min-width:454px) and (max-width: 1026px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
  }
  background-color: #03153B;
  height: 473px;
}
.splide {
  &.news.detail {
    display: block;
    max-width: 750px;
    .splide__arrows {
      .splide__arrow {
        opacity: 1;
        border-radius: 0;
        width: 50px;
        height: 50px;
        margin: 0 -16px;
        background: #F5F8F8;
        transition: opacity 0.2s ease-in;
        &:hover {
          background: #FFFFFF;
        }
        &.splide__arrow--prev {
          justify-content: start;
          padding-left: 15px;
          transition: all 0.2s ease-in;
          &:hover {
            padding-left: 0;
          }
        }
        &.splide__arrow--next {
          justify-content: flex-end;
          padding-right: 15px;
          transition: all 0.2s ease-in;
          &:hover {
            padding-right: 0;
          }
        }
      }
    }
    .splide__pagination {
      bottom: -30px;
      .splide__pagination__page {
        background: #ffffff;
        width: 10px;
        height: 10px;
        border: 1px solid #0A1734;
        opacity: 1;
        &.is-active {
          background: #0A1734;
          transform: unset;
          width: 10px;
          height: 10px;
          border: 1px solid #0A1734;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
    .splide__track {
      margin-left: 0;
      margin-right: 0;
      .splide__list {
        .splide__slide {
          height: auto;
          padding-top: 0;
          .row {
            height: 100%;
            width: 100%;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}

.news_prev {
  color: #CBCBCB;
  text-decoration: none;
  .bullet, svg {
    margin-right: 10px;
    margin-left: 0;
    transition: all 0.3s ease-out;
    g {
      stroke: #CBCBCB;
    }
  }
  &:hover {
    color: #03153B;
    .bullet, svg {
      margin-right: 20px;
      margin-left: -10px;
      g {
        stroke: #03143b;
      }
    }
  }
}
.news_next {
  color: #CBCBCB;
  text-decoration: none;
  .bullet, svg {
    margin-left: 10px;
    transition: all 0.3s ease-out;
    g {
      stroke: #CBCBCB;
    }
  }
  &:hover {
    color: #03153B;
    .bullet, svg {
      margin-left: 25px;
      margin-right: -15px;
      g {
        stroke: #03143b;
      }
    }
  }
}
.bullet {
  transition: all 0.3s ease-out;
  padding-left: 0;
  &.invert {
    transform: rotate(180deg);
  }
}

.section_news_detail_block{
  .container-xsmall {
    max-width: 750px;
    @media (min-width:454px) and (max-width: 1026px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
  }
  @include media-breakpoint-down($sm){
    padding-top: 20px;
    .row{
      margin-left: 0;
      .col{
        .fs-30px{
          font-size: 22px;
        }
        .fs-16px{
          font-size: 14px;
        }
      }
    }
  }
}
