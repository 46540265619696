.section_academy_slider {
  background-color: #03153B;
  height: 473px;
  .container {
    @media (min-width:454px) and (max-width: 1526px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
  }
  .track {
    margin-left: 0;
    margin-right: 0;
    .list {
      list-style: none;
      margin: 0;
      padding: 0;
      .slide {
        height: auto;
        padding-top: 0;
        .row {
          height: 100%;
          width: 100%;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

.section_academy_steps_block {
  height: 700px;
  background-position: center 38%;
  @include media-breakpoint-down($sm) {
    height: 430px;
  }
  .overlay {
    position: absolute;
    background-color: rgba(3, 21, 59, 0.90);
    height: 700px;
    width: 100%;
    display: flex;
    align-items: center;
    @include media-breakpoint-down($sm) {
      height: 430px;
    }
  }
  .img {
    @include media-breakpoint-down($sm) {
      height: 40px;
    }
  }
  .splide {
    &.steps {
      .splide__track {
        .splide__list {
          .splide__slide {
            .row {
              .col {
                text-align: center;
                display: flex;
                align-items: center;
                flex-direction: column;
                word-break: break-word;
                span {
                  max-width: 80%;
                }
              }
            }
          }
        }
      }
      .splide__pagination {
        bottom: -30px;
        .splide__pagination__page {
          background: transparent;
          width: 10px;
          height: 10px;
          border: 1px solid #ffffff;
          opacity: 1;
          &.is-active {
            background: #ffffff;
            transform: unset;
            width: 10px;
            height: 10px;
            border: 1px solid #ffffff;
          }
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

.section_detail_grid_block {
  .container-medium {
    @media (min-width:454px) and (max-width: 1748px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
  }
  div {
    .row {
      @include media-breakpoint-down($sm) {
        height: 100%;
      }
    }
  }
}

.section_academy_detail_block{
  .container-xsmall {
    @media (min-width:454px) and (max-width: 1026px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
  }
}
