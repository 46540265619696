.section_credits {
  padding-top: 35px;
  padding-bottom: 15px;
  position: relative;
  z-index: 3;
  .container{
    @media (min-width:454px) and (max-width: 1498px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
    .row{
      a {
        color: #ffffff;
        text-decoration: none;
        text-transform: uppercase;
        padding-right: 10px;
        &:first-of-type {
          padding-right: 20px;
          @include media-breakpoint-down($sm) {
            padding-right: 0;
          }
        }
        &:hover {
          text-decoration: underline;
        }
      }
      &:last-of-type {
        text-align: right;
      }
    }

    @include media-breakpoint-down($md) {
      .footer_license{
        display: flex;
        flex-direction: column;
        .footer_conditions{
          display: flex;
          margin-bottom: 20px;
          justify-content: center;
          align-items: center;



          a {
            &.socials {
              margin-bottom: 16px;
            }
          }
        }
        .footer_logo {
          text-align: center;
          @include media-breakpoint-down($sm){
          text-align: left;
          padding:0;
          }

          img{
            max-width: 222px;
          }
        }
      }
    }

    @include media-breakpoint-down($sm) {
      .footer_license{

        .footer_conditions{
          align-items: start;
          flex-direction: column;

          a{
            margin-bottom: 16px;
          }

        }
      }
    }
  }
}
