footer {
  position: relative;
  z-index: 3;
  .container {
    padding-top: 84px;
    padding-bottom: 86px;
    @media (min-width:454px) and (max-width: 1498px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
    @include media-breakpoint-down($sm) {
      padding-top: 0;
      padding-bottom: 0;
      word-break: break-all;
    }
    .row {
      padding-top: 18px;
      padding-bottom: 18px;
      border-bottom: 1px solid #3B4E76;
      &:first-of-type {
        border-top: 1px solid #3B4E76;
      }
      .col {
        padding: 0;
        color: #ffffff;
        img {
          margin-right: 16px;
        }
        a {
          color: #ffffff;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .col-mobile {
    padding-left: 0;
    padding-right: 0;
    >.row {
      padding: 0 30px;
      .col {
        .accordion-button {
          padding: 18px 0;
          background-color: transparent;
          color: #fff;
          &::after {
            flex-shrink: 0;
            width: 12px;
            height: 23px;
            margin-left: auto;
            content: "";
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17.139" height="32.864" viewBox="0 0 17.139 32.864"><path d="M0,0,16.078,16.078,32.157,0" transform="translate(0.354 32.51) rotate(-90)" fill="none" stroke="#ffffff" stroke-width="2"/></svg>');
            background-repeat: no-repeat;
            background-size: 8px;
            transition: transform .2s ease-in-out;
            transform: rotate(90deg);
            margin-right: 6px;
          }
          &:not(.collapsed)::after {
            transform: rotate(-90deg);
          }
          &:focus {
            border-color: transparent;
            box-shadow: none;
          }
        }
      }
    }
  }
}
