.section_job_offers_table {
  background-color: #ffffff;
  .container-large {
    @media (min-width:454px) and (max-width: 1674px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
  }
  .row {
    border-bottom: 1px solid #ECECEC;
  }
}
