.section_detail_grid_block {
  background-color: #ffffff;
  div {
    .row {

      .col-content{

        .content{
          width: 100%;
        }

        @include media-breakpoint-down($xxl){
          &.pl-170px{
            padding-left: 100px;
          }

          &.pr-170px{
            padding-right: 100px;
          }

          .content{
            padding-top: 30px;
          }

        }

        @include media-breakpoint-down($lg){
          &.pl-170px{
            padding-left: 20px;
          }

          &.pr-170px{
            padding-right: 20px;
          }

        }


        @include media-breakpoint-down($sm){
          max-width: 100%;
        }
      }
    }

    @include media-breakpoint-down($lg){
      justify-content: flex-start !important;
      padding-top:0px;
      .col-img{
        order:1;
      }

      .col-content{
        order:2;
        padding-bottom:100px;
      }
    }
  }
  .accordion {
    width: 560px;
    max-width: 100%;
    .accordion-button {
      font-family: 'Be Vietnam Pro', sans-serif;
      font-size: 22px;
      color: #03153B;
      padding: 1rem 30px 1rem 0;
      &.collapsed {
        &::after {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          margin-bottom: 3px;
        }
      }
      &:not(.collapsed){
        background-color: transparent;
      }
      &:focus{
        border-color: transparent;
        box-shadow: none;
      }
      &::before{
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        border: 1px solid #03153B;
        position: absolute;
        right: 0;
      }
      &::after {
        background: none;
        border: solid #03153B;
        border-width: 0 2px 2px 0;
        display: inline-block;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        width: 8px;
        height: 8px;
        margin-left: unset;
        position: absolute;
        right: 8px;
        margin-bottom: -4px;
      }
    }

    @include media-breakpoint-down($xl){
      width: 460px;
    }
  }
  @include media-breakpoint-down($lg){
    padding-top: 20px;

    .container-medium{
        .pl-170px, .pr-170px{
          padding-left:20px;
          padding-right: 20px;

          .content{
            max-width: 100%;
          }
        }
    }

  }

}
