//Highlight Block
.section_highlight_block {
  .container {
    @media (min-width:454px) and (max-width: 1498px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
    .row {
      .col {
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        .content {
          max-width: 960px;
          word-break: break-word;
        }
      }
    }
  }

  @include media-breakpoint-down($sm){
    padding-top:20px;
    padding-bottom:100px;
  }
}

//Feature Block
.section_feature_block {
  height: 400px;
  display: flex;
  align-items: center;
  @include media-breakpoint-down($sm) {
    background-position-x: 75%;

    h4{
      font-size: 22px;
    }
  }
  .container {
    .row {
      .col {
        .content {
          a {
            color: #ffffff;
            font-family: 'Be Vietnam Pro Bold', sans-serif;
            font-size: 14px;
            text-transform: uppercase;
            text-decoration: none;
            background-color: transparent;
            border: 1px solid #ffffff;
            padding: 26px 22px;
          }
        }
      }
    }
  }
}

//Points Block
.section_points_block {
  background-position: center center;
  background-repeat: no-repeat;
  background-blend-mode: luminosity;
  .row {
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    .col-right {
      .tabs {
        width: 924px;
        @include media-breakpoint-down($sm) {
          width: 100%;
        }
        .nav {
          .nav-item {
            font-family: 'Be Vietnam Pro Bold', sans-serif;
            font-size: 13px;
            letter-spacing: 2px;
            text-transform: uppercase;
            text-align: left;
            .nav-link {
              padding: 15px 12px;
              color: #CBCBCB;
              border-radius: 0;
              &:hover {
                color: #1B9CF2;
              }
              &.active {
                color: #1B9CF2;
                background: unset;
                +.slider {
                  width: 100%;
                }
              }
            }
            .pre-slider {
              display:block;
              height: 1px;
              background-color: #E6E6E6;
              width: 100%;
            }
            .slider {
              display:block;
              height: 1px;
              background-color: #1B9CF2;
              width: 0;
              transition: width 0.3s ease-out;
            }
            &:hover {
              .slider {
                width: 100%;
              }
            }
          }
        }
        .tab-content {
          .tab-pane {
            font-family: 'Be Vietnam Pro Light', sans-serif;
            font-size: 16px;
            color: #03153B;
            line-height: 26px;
          }
        }
      }
    }
  }
}
