.section_partners_block {
  background-color: #ffffff;

  .container{
    @media (min-width:454px) and (max-width: 1498px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
    .row{
      .row{
        @include media-breakpoint-down($sm){
          .col-4{
            img{
              width: 100%;
              max-width: 206px;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down($xl){
    padding-top: 100px;
  }

  @include media-breakpoint-down($md){
    padding-top: 60px;
  }

  @include media-breakpoint-down($sm){
    padding-top: 20px;
  }
}
