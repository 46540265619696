/* ACIMA DE "X" PIXEIS*/
@mixin media-breakpoint-up($px) {
  @media (min-width: $px) {
    @content
  }
}

/* ABAIXO DE "X" PIXEIS */
@mixin media-breakpoint-down($px) {
  @media (max-width: $px) {
    @content
  }
}
