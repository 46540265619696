.mx-0 { &-xs { @media (max-width: $sm) { margin-right: 0 !important; margin-left: 0 !important; } } }
.mt-0 { &-xs { @media (max-width: $sm) { margin-top: 0 !important; } } }
.mt-6px { margin-top: 6px; &-xs { @media (max-width: $sm) { margin-top: 6px !important; } } }
.mt-10px { margin-top: 10px; &-xs { @media (max-width: $sm) { margin-top: 10px !important; } } }
.mt-15px { margin-top: 15px; &-xs { @media (max-width: $sm) { margin-top: 15px !important; } } }
.mt-20px { margin-top: 20px; &-xs { @media (max-width: $sm) { margin-top: 20px !important; } } }
.mt-30px { margin-top: 30px; &-xs { @media (max-width: $sm) { margin-top: 30px !important; } } }
.mt-40px { margin-top: 40px; &-xs { @media (max-width: $sm) { margin-top: 40px !important; } } }
.mt-50px { margin-top: 50px; &-xs { @media (max-width: $sm) { margin-top: 50px !important; } } }
.mt-60px { margin-top: 60px; &-xs { @media (max-width: $sm) { margin-top: 60px !important; } } }
.mt-70px { margin-top: 70px; &-xs { @media (max-width: $sm) { margin-top: 70px !important; } } }
.mt-80px { margin-top: 80px; &-xs { @media (max-width: $sm) { margin-top: 80px !important; } } }
.mt-90px { margin-top: 90px; &-xs { @media (max-width: $sm) { margin-top: 90px !important; } } }
.mt-100px { margin-top: 100px; &-xs { @media (max-width: $sm) { margin-top: 100px !important; } } }
.mt-110px { margin-top: 110px; &-xs { @media (max-width: $sm) { margin-top: 110px !important; } } }
.mt-120px { margin-top: 120px; &-xs { @media (max-width: $sm) { margin-top: 120px !important; } } }
.mt-130px { margin-top: 130px; &-xs { @media (max-width: $sm) { margin-top: 130px !important; } } }
.mt-135px { margin-top: 135px; &-xs { @media (max-width: $sm) { margin-top: 135px !important; } } }
.mt-140px { margin-top: 140px; &-xs { @media (max-width: $sm) { margin-top: 140px !important; } } }
.mt-150px { margin-top: 150px; &-xs { @media (max-width: $sm) { margin-top: 150px !important; } } }
.mt-160px { margin-top: 160px; &-xs { @media (max-width: $sm) { margin-top: 160px !important; } } }
.mt-170px { margin-top: 170px; &-xs { @media (max-width: $sm) { margin-top: 170px !important; } } }
.mt-180px { margin-top: 180px; &-xs { @media (max-width: $sm) { margin-top: 180px !important; } } }
.mt-190px { margin-top: 190px; &-xs { @media (max-width: $sm) { margin-top: 190px !important; } } }
.mt-200px { margin-top: 200px; &-xs { @media (max-width: $sm) { margin-top: 200px !important; } } }
.mt-210px { margin-top: 210px; &-xs { @media (max-width: $sm) { margin-top: 210px !important; } } }
.mt-220px { margin-top: 220px; &-xs { @media (max-width: $sm) { margin-top: 220px !important; } } }
.mb-0 { &-xs { @media (max-width: $sm) { margin-bottom: 0 !important; } } }
.mb-6px { margin-bottom: 6px; &-xs { @media (max-width: $sm) { margin-bottom: 6px !important; } } }
.mb-10px { margin-bottom: 10px; &-xs { @media (max-width: $sm) { margin-bottom: 10px !important; } } }
.mb-20px { margin-bottom: 20px; &-xs { @media (max-width: $sm) { margin-bottom: 20px !important; } } }
.mb-28px { margin-bottom: 28px; &-xs { @media (max-width: $sm) { margin-bottom: 28px !important; } } }
.mb-30px { margin-bottom: 30px; &-xs { @media (max-width: $sm) { margin-bottom: 30px !important; } } }
.mb-40px { margin-bottom: 40px; &-xs { @media (max-width: $sm) { margin-bottom: 40px !important; } } }
.mb-50px { margin-bottom: 50px; &-xs { @media (max-width: $sm) { margin-bottom: 50px !important; } } }
.mb-60px { margin-bottom: 60px; &-xs { @media (max-width: $sm) { margin-bottom: 60px !important; } } }
.mb-70px { margin-bottom: 70px; &-xs { @media (max-width: $sm) { margin-bottom: 70px !important; } } }
.mb-80px { margin-bottom: 80px; &-xs { @media (max-width: $sm) { margin-bottom: 80px !important; } } }
.mb-90px { margin-bottom: 90px; &-xs { @media (max-width: $sm) { margin-bottom: 90px !important; } } }
.mb-100px { margin-bottom: 100px; &-xs { @media (max-width: $sm) { margin-bottom: 100px !important; } } }
.mb-110px { margin-bottom: 110px; &-xs { @media (max-width: $sm) { margin-bottom: 110px !important; } } }
.mb-120px { margin-bottom: 120px; &-xs { @media (max-width: $sm) { margin-bottom: 120px !important; } } }
.mb-130px { margin-bottom: 130px; &-xs { @media (max-width: $sm) { margin-bottom: 130px !important; } } }
.mb-140px { margin-bottom: 140px; &-xs { @media (max-width: $sm) { margin-bottom: 140px !important; } } }
.mb-150px { margin-bottom: 150px; &-xs { @media (max-width: $sm) { margin-bottom: 150px !important; } } }
.mb-160px { margin-bottom: 160px; &-xs { @media (max-width: $sm) { margin-bottom: 160px !important; } } }
.mb-170px { margin-bottom: 170px; &-xs { @media (max-width: $sm) { margin-bottom: 170px !important; } } }
.mb-180px { margin-bottom: 180px; &-xs { @media (max-width: $sm) { margin-bottom: 180px !important; } } }
.mb-190px { margin-bottom: 190px; &-xs { @media (max-width: $sm) { margin-bottom: 190px !important; } } }
.mb-200px { margin-bottom: 200px; &-xs { @media (max-width: $sm) { margin-bottom: 200px !important; } } }
.ml-0 { &-xs { @media (max-width: $sm) { margin-left: 0 !important; } } }
.ml-10px { margin-left: 10px; &-xs { @media (max-width: $sm) { margin-left: 10px !important; } } }
.ml-20px { margin-left: 20px; &-xs { @media (max-width: $sm) { margin-left: 20px !important; } } }
.ml-30px { margin-left: 30px; &-xs { @media (max-width: $sm) { margin-left: 30px !important; } } }
.ml-40px { margin-left: 40px; &-xs { @media (max-width: $sm) { margin-left: 40px !important; } } }
.ml-50px { margin-left: 50px; &-xs { @media (max-width: $sm) { margin-left: 50px !important; } } }
.ml-60px { margin-left: 60px; &-xs { @media (max-width: $sm) { margin-left: 60px !important; } } }
.ml-70px { margin-left: 70px; &-xs { @media (max-width: $sm) { margin-left: 70px !important; } } }
.ml-80px { margin-left: 80px; &-xs { @media (max-width: $sm) { margin-left: 80px !important; } } }
.ml-90px { margin-left: 90px; &-xs { @media (max-width: $sm) { margin-left: 90px !important; } } }
.ml-100px { margin-left: 100px; &-xs { @media (max-width: $sm) { margin-left: 100px !important; } } }
.ml-110px { margin-left: 110px; &-xs { @media (max-width: $sm) { margin-left: 110px !important; } } }
.ml-120px { margin-left: 120px; &-xs { @media (max-width: $sm) { margin-left: 120px !important; } } }
.ml-130px { margin-left: 130px; &-xs { @media (max-width: $sm) { margin-left: 130px !important; } } }
.ml-140px { margin-left: 140px; &-xs { @media (max-width: $sm) { margin-left: 140px !important; } } }
.ml-150px { margin-left: 150px; &-xs { @media (max-width: $sm) { margin-left: 150px !important; } } }
.ml-160px { margin-left: 160px; &-xs { @media (max-width: $sm) { margin-left: 160px !important; } } }
.ml-170px { margin-left: 170px; &-xs { @media (max-width: $sm) { margin-left: 170px !important; } } }
.ml-180px { margin-left: 180px; &-xs { @media (max-width: $sm) { margin-left: 180px !important; } } }
.ml-190px { margin-left: 190px; &-xs { @media (max-width: $sm) { margin-left: 190px !important; } } }
.ml-200px { margin-left: 200px; &-xs { @media (max-width: $sm) { margin-left: 200px !important; } } }
.mr-0 { &-xs { @media (max-width: $sm) { margin-right: 0 !important; } } }
.mr-10px { margin-right: 10px; &-xs { @media (max-width: $sm) { margin-right: 10px !important; } } }
.mr-20px { margin-right: 20px; &-xs { @media (max-width: $sm) { margin-right: 20px !important; } } }
.mr-30px { margin-right: 30px; &-xs { @media (max-width: $sm) { margin-right: 30px !important; } } }
.mr-40px { margin-right: 40px; &-xs { @media (max-width: $sm) { margin-right: 40px !important; } } }
.mr-50px { margin-right: 50px; &-xs { @media (max-width: $sm) { margin-right: 50px !important; } } }
.mr-60px { margin-right: 60px; &-xs { @media (max-width: $sm) { margin-right: 60px !important; } } }
.mr-70px { margin-right: 70px; &-xs { @media (max-width: $sm) { margin-right: 70px !important; } } }
.mr-80px { margin-right: 80px; &-xs { @media (max-width: $sm) { margin-right: 80px !important; } } }
.mr-90px { margin-right: 90px; &-xs { @media (max-width: $sm) { margin-right: 90px !important; } } }
.mr-100px { margin-right: 100px; &-xs { @media (max-width: $sm) { margin-right: 100px !important; } } }
.mr-110px { margin-right: 110px; &-xs { @media (max-width: $sm) { margin-right: 110px !important; } } }
.mr-120px { margin-right: 120px; &-xs { @media (max-width: $sm) { margin-right: 120px !important; } } }
.mr-130px { margin-right: 130px; &-xs { @media (max-width: $sm) { margin-right: 130px !important; } } }
.mr-140px { margin-right: 140px; &-xs { @media (max-width: $sm) { margin-right: 140px !important; } } }
.mr-150px { margin-right: 150px; &-xs { @media (max-width: $sm) { margin-right: 150px !important; } } }
.mr-160px { margin-right: 160px; &-xs { @media (max-width: $sm) { margin-right: 160px !important; } } }
.mr-170px { margin-right: 170px; &-xs { @media (max-width: $sm) { margin-right: 170px !important; } } }
.mr-180px { margin-right: 180px; &-xs { @media (max-width: $sm) { margin-right: 180px !important; } } }
.mr-190px { margin-right: 190px; &-xs { @media (max-width: $sm) { margin-right: 190px !important; } } }
.mr-200px { margin-right: 200px; &-xs { @media (max-width: $sm) { margin-right: 200px !important; } } }
