.collapseTopWhite {
  height: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: -1;
  position: absolute;
  top: 0;
  &.show {
    z-index: 1;
    height: 420px;
  }
}
.navbar {
  height: $navbarHeight;
  background-color: rgba(3, 21, 59, 1);
  z-index: 10;
  position: sticky;
  top: 0;
  transition: all 1s ease;
  @include media-breakpoint-down($lg) {
    padding-top: 0;
  }
  .container-fluid {
    z-index: 2;
    height: 70px;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    padding: 0 62px;
    @include media-breakpoint-down($sm){ padding: 0 60px; }
    @include media-breakpoint-down($xs){ padding: 0 40px; }
    @include media-breakpoint-down(405px) { padding: 0 15px; }

    .navbar-brand {
      > img {
        &.logo {
          width: 222px;
          height: auto;
        }
      }
    }
    .navbar-toggler {
      border: 0;
      padding: 0;
      font-size: 20px;
      line-height: 0;
      border-radius: 0;
      transition: unset;
      &:focus {
        box-shadow: none;
      }
    }
    .navbar-collapse {
      height: $navbarHeight;
      .navbar-nav {
        height: $navbarHeight;
        align-items: center;
        .nav-item {
          background-color: transparent;
          &.last {
            padding-right: 14px;
          }
          .nav-link {
            font-family: 'Be Vietnam Pro Bold', sans-serif;
            font-size: 13px;
            color: #ffffff;
            padding: 1.261vw 0.42vw;
            background-color: transparent;
            border-width: 0;
            .iconify {
              transition: transform 0.3s ease-out;
            }
            &:hover, &.open {
              color: #1B9CF2 !important;
              .iconify {
                color: #1B9CF2;
                transform: rotate(90deg);
              }
            }
            &.sites {
              padding: 0 15px 0 15px;
              background-color: #1B9CF2;
              width: 150px;
              white-space: normal;
              height: 50px;
              align-items: center;
              @include media-breakpoint-down(1136px) { width: auto; }
              &::after {
                margin: auto;
                transform: rotate(-90deg);
                transition: all 0.3s ease;
              }
              &:hover, &.show {
                color: #ffffff !important;
                background-color: unset;
                &.blue_gb {
                  background-color: #03153B;
                }
                &::after {
                  transform: rotate(0deg);
                }
              }
              .c-text {
                padding-left: 6px;
                @include media-breakpoint-down(1136px) { padding-right: 10px; }
              }
            }
            &.langs {
              background-color: #344361;
              padding:0;
              width: 50px;
              height: 50px;
              align-items: center;
              display: flex;
              justify-content: center;
              &::after {
                transform: rotate(-90deg);
                transition: all 0.3s ease;
              }
              &:hover, &.show {
                color: #ffffff;
                background-color: transparent;
                &.blue_gb {
                  color: #ffffff !important;
                  background-color: #03153B !important;
                }
                &::after {
                  transform: rotate(0deg);
                }
              }
            }
            @include media-breakpoint-down(1180px) { font-size: 12px; }
            @include media-breakpoint-down(1106px) { font-size: 11px; }
            @include media-breakpoint-down(1060px) { font-size: 10px; }
            @include media-breakpoint-down(1012px) { font-size: 9px; }
          }
          .collapsing, .collapse {
            transition: height 0s ease;
            position: absolute;
            ul {
              list-style: none;
              padding-left: 1.5rem;
              li {
                margin-bottom: 15px;
                a {
                  font-family: 'Be Vietnam Pro Bold', sans-serif;
                  font-size: 13px;
                  color: #03153B;
                  text-decoration: none;
                  &:hover {
                    color: #1B9CF2;
                  }
                }
              }
            }
          }
          .dropdown-menu {
            display: none;
            font-family: 'Be Vietnam Pro', sans-serif;
            margin-top: 0;
            border: 0;
            border-radius: 0;
            min-width: unset;
            padding: 0;
            opacity: 1;
            z-index: 1;
            &.sites {
              width: 150px;
            }
            &.langs {
              width: 50px;
            }
            li {
              .dropdown-item {
                padding: 9px 16px;
                color: #032776;
                font-size: 11px;
                font-weight: 700;
                &:hover {
                  background-color: #E5E6E5;
                }
                img {
                  height: 20px;
                  width: 20px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
