html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Be Vietnam Pro Bold";
  src: url("../fonts/BeVietnamPro-Bold.eot");
  src: url("../fonts/BeVietnamPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/BeVietnamPro-Bold.woff2") format("woff2"), url("../fonts/BeVietnamPro-Bold.woff") format("woff"), url("../fonts/BeVietnamPro-Bold.svg#BeVietnamPro-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Be Vietnam Pro Black Italic";
  src: url("../fonts/BeVietnamPro-BlackItalic.eot");
  src: url("../fonts/BeVietnamPro-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/BeVietnamPro-BlackItalic.woff2") format("woff2"), url("../fonts/BeVietnamPro-BlackItalic.woff") format("woff"), url("../fonts/BeVietnamPro-BlackItalic.svg#BeVietnamPro-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Be Vietnam Pro ExtraBold Italic";
  src: url("../fonts/BeVietnamPro-ExtraBoldItalic.eot");
  src: url("../fonts/BeVietnamPro-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/BeVietnamPro-ExtraBoldItalic.woff2") format("woff2"), url("../fonts/BeVietnamPro-ExtraBoldItalic.woff") format("woff"), url("../fonts/BeVietnamPro-ExtraBoldItalic.svg#BeVietnamPro-ExtraBoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Be Vietnam Pro ExtraBold";
  src: url("../fonts/BeVietnamPro-ExtraBold.eot");
  src: url("../fonts/BeVietnamPro-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/BeVietnamPro-ExtraBold.woff2") format("woff2"), url("../fonts/BeVietnamPro-ExtraBold.woff") format("woff"), url("../fonts/BeVietnamPro-ExtraBold.svg#BeVietnamPro-ExtraBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Be Vietnam Pro ExtraLight";
  src: url("../fonts/BeVietnamPro-ExtraLight.eot");
  src: url("../fonts/BeVietnamPro-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/BeVietnamPro-ExtraLight.woff2") format("woff2"), url("../fonts/BeVietnamPro-ExtraLight.woff") format("woff"), url("../fonts/BeVietnamPro-ExtraLight.svg#BeVietnamPro-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Be Vietnam Pro Bold Italic";
  src: url("../fonts/BeVietnamPro-BoldItalic.eot");
  src: url("../fonts/BeVietnamPro-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/BeVietnamPro-BoldItalic.woff2") format("woff2"), url("../fonts/BeVietnamPro-BoldItalic.woff") format("woff"), url("../fonts/BeVietnamPro-BoldItalic.svg#BeVietnamPro-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Be Vietnam Pro Italic";
  src: url("../fonts/BeVietnamPro-Italic.eot");
  src: url("../fonts/BeVietnamPro-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/BeVietnamPro-Italic.woff2") format("woff2"), url("../fonts/BeVietnamPro-Italic.woff") format("woff"), url("../fonts/BeVietnamPro-Italic.svg#BeVietnamPro-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Be Vietnam Pro ExtraLight Italic";
  src: url("../fonts/BeVietnamPro-ExtraLightItalic.eot");
  src: url("../fonts/BeVietnamPro-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/BeVietnamPro-ExtraLightItalic.woff2") format("woff2"), url("../fonts/BeVietnamPro-ExtraLightItalic.woff") format("woff"), url("../fonts/BeVietnamPro-ExtraLightItalic.svg#BeVietnamPro-ExtraLightItalic") format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Be Vietnam Pro Medium Italic";
  src: url("../fonts/BeVietnamPro-MediumItalic.eot");
  src: url("../fonts/BeVietnamPro-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/BeVietnamPro-MediumItalic.woff2") format("woff2"), url("../fonts/BeVietnamPro-MediumItalic.woff") format("woff"), url("../fonts/BeVietnamPro-MediumItalic.svg#BeVietnamPro-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Be Vietnam Pro Light Italic";
  src: url("../fonts/BeVietnamPro-LightItalic.eot");
  src: url("../fonts/BeVietnamPro-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/BeVietnamPro-LightItalic.woff2") format("woff2"), url("../fonts/BeVietnamPro-LightItalic.woff") format("woff"), url("../fonts/BeVietnamPro-LightItalic.svg#BeVietnamPro-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Be Vietnam Pro Medium";
  src: url("../fonts/BeVietnamPro-Medium.eot");
  src: url("../fonts/BeVietnamPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/BeVietnamPro-Medium.woff2") format("woff2"), url("../fonts/BeVietnamPro-Medium.woff") format("woff"), url("../fonts/BeVietnamPro-Medium.svg#BeVietnamPro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Be Vietnam Pro Light";
  src: url("../fonts/BeVietnamPro-Light.eot");
  src: url("../fonts/BeVietnamPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/BeVietnamPro-Light.woff2") format("woff2"), url("../fonts/BeVietnamPro-Light.woff") format("woff"), url("../fonts/BeVietnamPro-Light.svg#BeVietnamPro-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../fonts/BeVietnamPro-Regular.eot");
  src: url("../fonts/BeVietnamPro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/BeVietnamPro-Regular.woff2") format("woff2"), url("../fonts/BeVietnamPro-Regular.woff") format("woff"), url("../fonts/BeVietnamPro-Regular.svg#BeVietnamPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Be Vietnam Pro SemiBold";
  src: url("../fonts/BeVietnamPro-SemiBold.eot");
  src: url("../fonts/BeVietnamPro-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/BeVietnamPro-SemiBold.woff2") format("woff2"), url("../fonts/BeVietnamPro-SemiBold.woff") format("woff"), url("../fonts/BeVietnamPro-SemiBold.svg#BeVietnamPro-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Be Vietnam Pro Thin";
  src: url("../fonts/BeVietnamPro-Thin.eot");
  src: url("../fonts/BeVietnamPro-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/BeVietnamPro-Thin.woff2") format("woff2"), url("../fonts/BeVietnamPro-Thin.woff") format("woff"), url("../fonts/BeVietnamPro-Thin.svg#BeVietnamPro-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Be Vietnam Pro Thin Italic";
  src: url("../fonts/BeVietnamPro-ThinItalic.eot");
  src: url("../fonts/BeVietnamPro-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/BeVietnamPro-ThinItalic.woff2") format("woff2"), url("../fonts/BeVietnamPro-ThinItalic.woff") format("woff"), url("../fonts/BeVietnamPro-ThinItalic.svg#BeVietnamPro-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Be Vietnam Pro SemiBold Italic";
  src: url("../fonts/BeVietnamPro-SemiBoldItalic.eot");
  src: url("../fonts/BeVietnamPro-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/BeVietnamPro-SemiBoldItalic.woff2") format("woff2"), url("../fonts/BeVietnamPro-SemiBoldItalic.woff") format("woff"), url("../fonts/BeVietnamPro-SemiBoldItalic.svg#BeVietnamPro-SemiBoldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Be Vietnam Pro Black";
  src: url("../fonts/BeVietnamPro-Black.eot");
  src: url("../fonts/BeVietnamPro-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/BeVietnamPro-Black.woff2") format("woff2"), url("../fonts/BeVietnamPro-Black.woff") format("woff"), url("../fonts/BeVietnamPro-Black.svg#BeVietnamPro-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono Bold";
  src: url("../fonts/IBMPlexMono-Bold.eot");
  src: url("../fonts/IBMPlexMono-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexMono-Bold.woff2") format("woff2"), url("../fonts/IBMPlexMono-Bold.woff") format("woff"), url("../fonts/IBMPlexMono-Bold.svg#IBMPlexMono-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono ExtraLight Italic";
  src: url("../fonts/IBMPlexMono-ExtraLightItalic.eot");
  src: url("../fonts/IBMPlexMono-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexMono-ExtraLightItalic.woff2") format("woff2"), url("../fonts/IBMPlexMono-ExtraLightItalic.woff") format("woff"), url("../fonts/IBMPlexMono-ExtraLightItalic.svg#IBMPlexMono-ExtraLightItalic") format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono ExtraLight";
  src: url("../fonts/IBMPlexMono-ExtraLight.eot");
  src: url("../fonts/IBMPlexMono-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexMono-ExtraLight.woff2") format("woff2"), url("../fonts/IBMPlexMono-ExtraLight.woff") format("woff"), url("../fonts/IBMPlexMono-ExtraLight.svg#IBMPlexMono-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono Light";
  src: url("../fonts/IBMPlexMono-Light.eot");
  src: url("../fonts/IBMPlexMono-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexMono-Light.woff2") format("woff2"), url("../fonts/IBMPlexMono-Light.woff") format("woff"), url("../fonts/IBMPlexMono-Light.svg#IBMPlexMono-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono Italic";
  src: url("../fonts/IBMPlexMono-Italic.eot");
  src: url("../fonts/IBMPlexMono-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexMono-Italic.woff2") format("woff2"), url("../fonts/IBMPlexMono-Italic.woff") format("woff"), url("../fonts/IBMPlexMono-Italic.svg#IBMPlexMono-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono Bold Italic";
  src: url("../fonts/IBMPlexMono-BoldItalic.eot");
  src: url("../fonts/IBMPlexMono-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexMono-BoldItalic.woff2") format("woff2"), url("../fonts/IBMPlexMono-BoldItalic.woff") format("woff"), url("../fonts/IBMPlexMono-BoldItalic.svg#IBMPlexMono-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono Light Italic";
  src: url("../fonts/IBMPlexMono-LightItalic.eot");
  src: url("../fonts/IBMPlexMono-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexMono-LightItalic.woff2") format("woff2"), url("../fonts/IBMPlexMono-LightItalic.woff") format("woff"), url("../fonts/IBMPlexMono-LightItalic.svg#IBMPlexMono-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono SemiBold Italic";
  src: url("../fonts/IBMPlexMono-SemiBoldItalic.eot");
  src: url("../fonts/IBMPlexMono-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexMono-SemiBoldItalic.woff2") format("woff2"), url("../fonts/IBMPlexMono-SemiBoldItalic.woff") format("woff"), url("../fonts/IBMPlexMono-SemiBoldItalic.svg#IBMPlexMono-SemiBoldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono Thin";
  src: url("../fonts/IBMPlexMono-Thin.eot");
  src: url("../fonts/IBMPlexMono-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexMono-Thin.woff2") format("woff2"), url("../fonts/IBMPlexMono-Thin.woff") format("woff"), url("../fonts/IBMPlexMono-Thin.svg#IBMPlexMono-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono";
  src: url("../fonts/IBMPlexMono-Regular.eot");
  src: url("../fonts/IBMPlexMono-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexMono-Regular.woff2") format("woff2"), url("../fonts/IBMPlexMono-Regular.woff") format("woff"), url("../fonts/IBMPlexMono-Regular.svg#IBMPlexMono-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono Medium Italic";
  src: url("../fonts/IBMPlexMono-MediumItalic.eot");
  src: url("../fonts/IBMPlexMono-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexMono-MediumItalic.woff2") format("woff2"), url("../fonts/IBMPlexMono-MediumItalic.woff") format("woff"), url("../fonts/IBMPlexMono-MediumItalic.svg#IBMPlexMono-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono SemiBold";
  src: url("../fonts/IBMPlexMono-SemiBold.eot");
  src: url("../fonts/IBMPlexMono-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexMono-SemiBold.woff2") format("woff2"), url("../fonts/IBMPlexMono-SemiBold.woff") format("woff"), url("../fonts/IBMPlexMono-SemiBold.svg#IBMPlexMono-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono Medium";
  src: url("../fonts/IBMPlexMono-Medium.eot");
  src: url("../fonts/IBMPlexMono-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexMono-Medium.woff2") format("woff2"), url("../fonts/IBMPlexMono-Medium.woff") format("woff"), url("../fonts/IBMPlexMono-Medium.svg#IBMPlexMono-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Sans Bold";
  src: url("../fonts/IBMPlexSans-Bold.eot");
  src: url("../fonts/IBMPlexSans-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-Bold.woff2") format("woff2"), url("../fonts/IBMPlexSans-Bold.woff") format("woff"), url("../fonts/IBMPlexSans-Bold.svg#IBMPlexSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Sans Bold Italic";
  src: url("../fonts/IBMPlexSans-BoldItalic.eot");
  src: url("../fonts/IBMPlexSans-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-BoldItalic.woff2") format("woff2"), url("../fonts/IBMPlexSans-BoldItalic.woff") format("woff"), url("../fonts/IBMPlexSans-BoldItalic.svg#IBMPlexSans-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono Thin Italic";
  src: url("../fonts/IBMPlexMono-ThinItalic.eot");
  src: url("../fonts/IBMPlexMono-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexMono-ThinItalic.woff2") format("woff2"), url("../fonts/IBMPlexMono-ThinItalic.woff") format("woff"), url("../fonts/IBMPlexMono-ThinItalic.svg#IBMPlexMono-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Sans ExtraLight";
  src: url("../fonts/IBMPlexSans-ExtraLight.eot");
  src: url("../fonts/IBMPlexSans-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-ExtraLight.woff2") format("woff2"), url("../fonts/IBMPlexSans-ExtraLight.woff") format("woff"), url("../fonts/IBMPlexSans-ExtraLight.svg#IBMPlexSans-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Sans Light";
  src: url("../fonts/IBMPlexSans-Light.eot");
  src: url("../fonts/IBMPlexSans-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-Light.woff2") format("woff2"), url("../fonts/IBMPlexSans-Light.woff") format("woff"), url("../fonts/IBMPlexSans-Light.svg#IBMPlexSans-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Sans ExtraLight Italic";
  src: url("../fonts/IBMPlexSans-ExtraLightItalic.eot");
  src: url("../fonts/IBMPlexSans-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-ExtraLightItalic.woff2") format("woff2"), url("../fonts/IBMPlexSans-ExtraLightItalic.woff") format("woff"), url("../fonts/IBMPlexSans-ExtraLightItalic.svg#IBMPlexSans-ExtraLightItalic") format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Sans Italic";
  src: url("../fonts/IBMPlexSans-Italic.eot");
  src: url("../fonts/IBMPlexSans-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-Italic.woff2") format("woff2"), url("../fonts/IBMPlexSans-Italic.woff") format("woff"), url("../fonts/IBMPlexSans-Italic.svg#IBMPlexSans-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Sans Medium Italic";
  src: url("../fonts/IBMPlexSans-MediumItalic.eot");
  src: url("../fonts/IBMPlexSans-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-MediumItalic.woff2") format("woff2"), url("../fonts/IBMPlexSans-MediumItalic.woff") format("woff"), url("../fonts/IBMPlexSans-MediumItalic.svg#IBMPlexSans-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Sans Medium";
  src: url("../fonts/IBMPlexSans-Medium.eot");
  src: url("../fonts/IBMPlexSans-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-Medium.woff2") format("woff2"), url("../fonts/IBMPlexSans-Medium.woff") format("woff"), url("../fonts/IBMPlexSans-Medium.svg#IBMPlexSans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Sans Light Italic";
  src: url("../fonts/IBMPlexSans-LightItalic.eot");
  src: url("../fonts/IBMPlexSans-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-LightItalic.woff2") format("woff2"), url("../fonts/IBMPlexSans-LightItalic.woff") format("woff"), url("../fonts/IBMPlexSans-LightItalic.svg#IBMPlexSans-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Sans Thin";
  src: url("../fonts/IBMPlexSans-Thin.eot");
  src: url("../fonts/IBMPlexSans-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-Thin.woff2") format("woff2"), url("../fonts/IBMPlexSans-Thin.woff") format("woff"), url("../fonts/IBMPlexSans-Thin.svg#IBMPlexSans-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Sans SemiBold";
  src: url("../fonts/IBMPlexSans-SemiBold.eot");
  src: url("../fonts/IBMPlexSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-SemiBold.woff2") format("woff2"), url("../fonts/IBMPlexSans-SemiBold.woff") format("woff"), url("../fonts/IBMPlexSans-SemiBold.svg#IBMPlexSans-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Sans";
  src: url("../fonts/IBMPlexSans-Regular.eot");
  src: url("../fonts/IBMPlexSans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-Regular.woff2") format("woff2"), url("../fonts/IBMPlexSans-Regular.woff") format("woff"), url("../fonts/IBMPlexSans-Regular.svg#IBMPlexSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Sans SemiBold Italic";
  src: url("../fonts/IBMPlexSans-SemiBoldItalic.eot");
  src: url("../fonts/IBMPlexSans-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-SemiBoldItalic.woff2") format("woff2"), url("../fonts/IBMPlexSans-SemiBoldItalic.woff") format("woff"), url("../fonts/IBMPlexSans-SemiBoldItalic.svg#IBMPlexSans-SemiBoldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Sans Thin Italic";
  src: url("../fonts/IBMPlexSans-ThinItalic.eot");
  src: url("../fonts/IBMPlexSans-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexSans-ThinItalic.woff2") format("woff2"), url("../fonts/IBMPlexSans-ThinItalic.woff") format("woff"), url("../fonts/IBMPlexSans-ThinItalic.svg#IBMPlexSans-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
/* ACIMA DE "X" PIXEIS*/
/* ABAIXO DE "X" PIXEIS */
.ff-ibmplexmono100 {
  font-family: "IBM Plex Mono Thin", sans-serif;
}

.ff-ibmplexmono200 {
  font-family: "IBM Plex Mono ExtraLight", sans-serif;
}

.ff-ibmplexmono300 {
  font-family: "IBM Plex Mono Light", sans-serif;
}

.ff-ibmplexmono {
  font-family: "IBM Plex Mono", sans-serif;
}

.ff-ibmplexmono500 {
  font-family: "IBM Plex Mono Medium", sans-serif;
}

.ff-ibmplexmono600 {
  font-family: "IBM Plex Mono SemiBold", sans-serif;
}

.ff-ibmplexmono700 {
  font-family: "IBM Plex Mono Bold", sans-serif;
}

.ff-ibmplexsans100 {
  font-family: "IBM Plex Sans Thin", sans-serif;
}

.ff-ibmplexsans200 {
  font-family: "IBM Plex Sans ExtraLight", sans-serif;
}

.ff-ibmplexsans300 {
  font-family: "IBM Plex Sans Light", sans-serif;
}

.ff-ibmplexsans {
  font-family: "IBM Plex Sans", sans-serif;
}

.ff-ibmplexsans500 {
  font-family: "IBM Plex Sans Medium", sans-serif;
}

.ff-ibmplexsans600 {
  font-family: "IBM Plex Sans SemiBold", sans-serif;
}

.ff-ibmplexsans700 {
  font-family: "IBM Plex Sans Bold", sans-serif;
}

.ff-bevietmanpro100 {
  font-family: "Be Vietnam Pro Thin", sans-serif;
}

.ff-bevietmanpro200 {
  font-family: "Be Vietnam Pro ExtraLight", sans-serif;
}

.ff-bevietmanpro300 {
  font-family: "Be Vietnam Pro Light", sans-serif;
}

.ff-bevietmanpro {
  font-family: "Be Vietnam Pro", sans-serif;
}

.ff-bevietmanpro500 {
  font-family: "Be Vietnam Pro Medium", sans-serif;
}

.ff-bevietmanpro600 {
  font-family: "Be Vietnam Pro SemiBold", sans-serif;
}

.ff-bevietmanpro700 {
  font-family: "Be Vietnam Pro Bold", sans-serif;
}

.ff-bevietmanpro800 {
  font-family: "Be Vietnam Pro ExtraBold", sans-serif;
}

.ff-bevietmanpro900 {
  font-family: "Be Vietnam Pro Black", sans-serif;
}

.fs-10px {
  font-size: 10px;
}
@media (max-width: 575px) {
  .fs-10px-xs {
    font-size: 10px !important;
  }
}

.fs-11px {
  font-size: 11px;
}
@media (max-width: 575px) {
  .fs-11px-xs {
    font-size: 11px !important;
  }
}

.fs-12px {
  font-size: 12px;
}
@media (max-width: 575px) {
  .fs-12px-xs {
    font-size: 12px !important;
  }
}

.fs-13px {
  font-size: 13px;
}
@media (max-width: 575px) {
  .fs-13px-xs {
    font-size: 13px !important;
  }
}

.fs-14px {
  font-size: 14px;
}
@media (max-width: 575px) {
  .fs-14px-xs {
    font-size: 14px !important;
  }
}

.fs-15px {
  font-size: 15px;
}
@media (max-width: 575px) {
  .fs-15px-xs {
    font-size: 15px !important;
  }
}

.fs-16px {
  font-size: 16px;
}
@media (max-width: 575px) {
  .fs-16px-xs {
    font-size: 16px !important;
  }
}

.fs-17px {
  font-size: 17px;
}
@media (max-width: 575px) {
  .fs-17px-xs {
    font-size: 17px !important;
  }
}

.fs-18px {
  font-size: 18px;
}
@media (max-width: 575px) {
  .fs-18px-xs {
    font-size: 18px !important;
  }
}

.fs-19px {
  font-size: 19px;
}
@media (max-width: 575px) {
  .fs-19px-xs {
    font-size: 19px !important;
  }
}

.fs-20px {
  font-size: 20px;
}
@media (max-width: 575px) {
  .fs-20px-xs {
    font-size: 20px !important;
  }
}

.fs-21px {
  font-size: 21px;
}
@media (max-width: 575px) {
  .fs-21px-xs {
    font-size: 21px !important;
  }
}

.fs-22px {
  font-size: 22px;
}
@media (max-width: 575px) {
  .fs-22px-xs {
    font-size: 22px !important;
  }
}

.fs-23px {
  font-size: 23px;
}
@media (max-width: 575px) {
  .fs-23px-xs {
    font-size: 23px !important;
  }
}

.fs-24px {
  font-size: 24px;
}
@media (max-width: 575px) {
  .fs-24px-xs {
    font-size: 24px !important;
  }
}

.fs-25px {
  font-size: 25px;
}
@media (max-width: 575px) {
  .fs-25px-xs {
    font-size: 25px !important;
  }
}

.fs-26px {
  font-size: 26px;
}
@media (max-width: 575px) {
  .fs-26px-xs {
    font-size: 26px !important;
  }
}

.fs-27px {
  font-size: 27px;
}
@media (max-width: 575px) {
  .fs-27px-xs {
    font-size: 27px !important;
  }
}

.fs-28px {
  font-size: 28px;
}
@media (max-width: 575px) {
  .fs-28px-xs {
    font-size: 28px !important;
  }
}

.fs-30px {
  font-size: 30px;
}
@media (max-width: 575px) {
  .fs-30px-xs {
    font-size: 30px !important;
  }
}

.fs-31px {
  font-size: 31px;
}
@media (max-width: 575px) {
  .fs-31px-xs {
    font-size: 31px !important;
  }
}

.fs-32px {
  font-size: 32px;
}
@media (max-width: 575px) {
  .fs-32px-xs {
    font-size: 32px !important;
  }
}

.fs-33px {
  font-size: 33px;
}
@media (max-width: 575px) {
  .fs-33px-xs {
    font-size: 33px !important;
  }
}

.fs-34px {
  font-size: 34px;
}
@media (max-width: 575px) {
  .fs-34px-xs {
    font-size: 34px !important;
  }
}

.fs-35px {
  font-size: 35px;
}
@media (max-width: 575px) {
  .fs-35px-xs {
    font-size: 35px !important;
  }
}

.fs-36px {
  font-size: 36px;
}
@media (max-width: 575px) {
  .fs-36px-xs {
    font-size: 36px !important;
  }
}

.fs-37px {
  font-size: 37px;
}
@media (max-width: 575px) {
  .fs-37px-xs {
    font-size: 37px !important;
  }
}

.fs-38px {
  font-size: 38px;
}
@media (max-width: 575px) {
  .fs-38px-xs {
    font-size: 38px !important;
  }
}

.fs-39px {
  font-size: 39px;
}
@media (max-width: 575px) {
  .fs-39px-xs {
    font-size: 39px !important;
  }
}

.fs-40px {
  font-size: 40px;
}
@media (max-width: 575px) {
  .fs-40px-xs {
    font-size: 40px !important;
  }
}

.fs-41px {
  font-size: 41px;
}
@media (max-width: 575px) {
  .fs-41px-xs {
    font-size: 41px !important;
  }
}

.fs-42px {
  font-size: 42px;
}
@media (max-width: 575px) {
  .fs-42px-xs {
    font-size: 42px !important;
  }
}

.fs-43px {
  font-size: 43px;
}
@media (max-width: 575px) {
  .fs-43px-xs {
    font-size: 43px !important;
  }
}

.fs-44px {
  font-size: 44px;
}
@media (max-width: 575px) {
  .fs-44px-xs {
    font-size: 44px !important;
  }
}

.fs-45px {
  font-size: 45px;
}
@media (max-width: 575px) {
  .fs-45px-xs {
    font-size: 45px !important;
  }
}

.fs-46px {
  font-size: 46px;
}
@media (max-width: 575px) {
  .fs-46px-xs {
    font-size: 46px !important;
  }
}

.fs-47px {
  font-size: 47px;
}
@media (max-width: 575px) {
  .fs-47px-xs {
    font-size: 47px !important;
  }
}

.fs-48px {
  font-size: 48px;
}
@media (max-width: 575px) {
  .fs-48px-xs {
    font-size: 48px !important;
  }
}

.fs-49px {
  font-size: 49px;
}
@media (max-width: 575px) {
  .fs-49px-xs {
    font-size: 49px !important;
  }
}

.fs-50px {
  font-size: 50px;
}
@media (max-width: 575px) {
  .fs-50px-xs {
    font-size: 50px !important;
  }
}

.fs-51px {
  font-size: 51px;
}
@media (max-width: 575px) {
  .fs-51px-xs {
    font-size: 51px !important;
  }
}

.fs-52px {
  font-size: 52px;
}
@media (max-width: 575px) {
  .fs-52px-xs {
    font-size: 52px !important;
  }
}

.fs-53px {
  font-size: 53px;
}
@media (max-width: 575px) {
  .fs-53px-xs {
    font-size: 53px !important;
  }
}

.fs-54px {
  font-size: 54px;
}
@media (max-width: 575px) {
  .fs-54px-xs {
    font-size: 54px !important;
  }
}

.fs-55px {
  font-size: 55px;
}
@media (max-width: 575px) {
  .fs-55px-xs {
    font-size: 55px !important;
  }
}

.fs-56px {
  font-size: 56px;
}
@media (max-width: 575px) {
  .fs-56px-xs {
    font-size: 56px !important;
  }
}

.fs-57px {
  font-size: 57px;
}
@media (max-width: 575px) {
  .fs-57px-xs {
    font-size: 57px !important;
  }
}

.fs-58px {
  font-size: 58px;
}
@media (max-width: 575px) {
  .fs-58px-xs {
    font-size: 58px !important;
  }
}

.fs-59px {
  font-size: 59px;
}
@media (max-width: 575px) {
  .fs-59px-xs {
    font-size: 59px !important;
  }
}

.fs-60px {
  font-size: 60px;
}
@media (max-width: 575px) {
  .fs-60px-xs {
    font-size: 60px !important;
  }
}

.fs-61px {
  font-size: 61px;
}
@media (max-width: 575px) {
  .fs-61px-xs {
    font-size: 61px !important;
  }
}

.fs-62px {
  font-size: 62px;
}
@media (max-width: 575px) {
  .fs-62px-xs {
    font-size: 62px !important;
  }
}

.fs-63px {
  font-size: 63px;
}
@media (max-width: 575px) {
  .fs-63px-xs {
    font-size: 63px !important;
  }
}

.fs-64px {
  font-size: 64px;
}
@media (max-width: 575px) {
  .fs-64px-xs {
    font-size: 64px !important;
  }
}

.fs-65px {
  font-size: 65px;
}
@media (max-width: 575px) {
  .fs-65px-xs {
    font-size: 65px !important;
  }
}

.fs-66px {
  font-size: 66px;
}
@media (max-width: 575px) {
  .fs-66px-xs {
    font-size: 66px !important;
  }
}

.fs-67px {
  font-size: 67px;
}
@media (max-width: 575px) {
  .fs-67px-xs {
    font-size: 67px !important;
  }
}

.fs-68px {
  font-size: 68px;
}
@media (max-width: 575px) {
  .fs-68px-xs {
    font-size: 68px !important;
  }
}

.fs-69px {
  font-size: 69px;
}
@media (max-width: 575px) {
  .fs-69px-xs {
    font-size: 69px !important;
  }
}

.fs-70px {
  font-size: 70px;
}
@media (max-width: 575px) {
  .fs-70px-xs {
    font-size: 70px !important;
  }
}

.fs-71px {
  font-size: 71px;
}
@media (max-width: 575px) {
  .fs-71px-xs {
    font-size: 71px !important;
  }
}

.fs-72px {
  font-size: 72px;
}
@media (max-width: 575px) {
  .fs-72px-xs {
    font-size: 72px !important;
  }
}

.fs-73px {
  font-size: 73px;
}
@media (max-width: 575px) {
  .fs-73px-xs {
    font-size: 73px !important;
  }
}

.fs-74px {
  font-size: 74px;
}
@media (max-width: 575px) {
  .fs-74px-xs {
    font-size: 74px !important;
  }
}

.fs-75px {
  font-size: 75px;
}
@media (max-width: 575px) {
  .fs-75px-xs {
    font-size: 75px !important;
  }
}

.fs-76px {
  font-size: 76px;
}
@media (max-width: 575px) {
  .fs-76px-xs {
    font-size: 76px !important;
  }
}

.fs-77px {
  font-size: 77px;
}
@media (max-width: 575px) {
  .fs-77px-xs {
    font-size: 77px !important;
  }
}

.fs-78px {
  font-size: 78px;
}
@media (max-width: 575px) {
  .fs-78px-xs {
    font-size: 78px !important;
  }
}

.fs-79px {
  font-size: 79px;
}
@media (max-width: 575px) {
  .fs-79px-xs {
    font-size: 79px !important;
  }
}

.fs-80px {
  font-size: 80px;
}
@media (max-width: 575px) {
  .fs-80px-xs {
    font-size: 80px !important;
  }
}

.fs-81px {
  font-size: 81px;
}
@media (max-width: 575px) {
  .fs-81px-xs {
    font-size: 81px !important;
  }
}

.fs-82px {
  font-size: 82px;
}
@media (max-width: 575px) {
  .fs-82px-xs {
    font-size: 82px !important;
  }
}

.fs-83px {
  font-size: 83px;
}
@media (max-width: 575px) {
  .fs-83px-xs {
    font-size: 83px !important;
  }
}

.fs-84px {
  font-size: 84px;
}
@media (max-width: 575px) {
  .fs-84px-xs {
    font-size: 84px !important;
  }
}

.fs-85px {
  font-size: 85px;
}
@media (max-width: 575px) {
  .fs-85px-xs {
    font-size: 85px !important;
  }
}

.fs-87px {
  font-size: 86px;
}
@media (max-width: 575px) {
  .fs-87px-xs {
    font-size: 86px !important;
  }
}

.fs-87px {
  font-size: 87px;
}
@media (max-width: 575px) {
  .fs-87px-xs {
    font-size: 87px !important;
  }
}

.fs-88px {
  font-size: 88px;
}
@media (max-width: 575px) {
  .fs-88px-xs {
    font-size: 88px !important;
  }
}

.fs-89px {
  font-size: 89px;
}
@media (max-width: 575px) {
  .fs-89px-xs {
    font-size: 89px !important;
  }
}

.fs-90px {
  font-size: 90px;
}
@media (max-width: 575px) {
  .fs-90px-xs {
    font-size: 90px !important;
  }
}

.fs-91px {
  font-size: 91px;
}
@media (max-width: 575px) {
  .fs-91px-xs {
    font-size: 91px !important;
  }
}

.fs-92px {
  font-size: 92px;
}
@media (max-width: 575px) {
  .fs-92px-xs {
    font-size: 92px !important;
  }
}

.fs-93px {
  font-size: 93px;
}
@media (max-width: 575px) {
  .fs-93px-xs {
    font-size: 93px !important;
  }
}

.fs-94px {
  font-size: 94px;
}
@media (max-width: 575px) {
  .fs-94px-xs {
    font-size: 94px !important;
  }
}

.fs-95px {
  font-size: 95px;
}
@media (max-width: 575px) {
  .fs-95px-xs {
    font-size: 95px !important;
  }
}

.fs-96px {
  font-size: 96px;
}
@media (max-width: 575px) {
  .fs-96px-xs {
    font-size: 96px !important;
  }
}

.fs-97px {
  font-size: 97px;
}
@media (max-width: 575px) {
  .fs-97px-xs {
    font-size: 97px !important;
  }
}

.fs-98px {
  font-size: 98px;
}
@media (max-width: 575px) {
  .fs-98px-xs {
    font-size: 98px !important;
  }
}

.fs-99px {
  font-size: 99px;
}
@media (max-width: 575px) {
  .fs-99px-xs {
    font-size: 99px !important;
  }
}

.fs-100px {
  font-size: 100px;
}
@media (max-width: 575px) {
  .fs-100px-xs {
    font-size: 100px !important;
  }
}

.fs-110px {
  font-size: 110px;
}
@media (max-width: 575px) {
  .fs-110px-xs {
    font-size: 110px !important;
  }
}

.fs-120px {
  font-size: 120px;
}
@media (max-width: 575px) {
  .fs-120px-xs {
    font-size: 120px !important;
  }
}

.fs-130px {
  font-size: 130px;
}
@media (max-width: 575px) {
  .fs-130px-xs {
    font-size: 130px !important;
  }
}

.fs-140px {
  font-size: 140px;
}
@media (max-width: 575px) {
  .fs-140px-xs {
    font-size: 140px !important;
  }
}

.fs-150px {
  font-size: 150px;
}
@media (max-width: 575px) {
  .fs-150px-xs {
    font-size: 150px !important;
  }
}

.fs-160px {
  font-size: 160px;
}
@media (max-width: 575px) {
  .fs-160px-xs {
    font-size: 160px !important;
  }
}

.ls-2px {
  letter-spacing: 2px;
}
@media (max-width: 575px) {
  .ls-2px-xs {
    letter-spacing: 2px !important;
  }
}

.ls-4px {
  letter-spacing: 4px;
}
@media (max-width: 575px) {
  .ls-4px-xs {
    letter-spacing: 4px !important;
  }
}

.ls-6px {
  letter-spacing: 6px;
}
@media (max-width: 575px) {
  .ls-6px-xs {
    letter-spacing: 6px !important;
  }
}

.ls-8px {
  letter-spacing: 8px;
}
@media (max-width: 575px) {
  .ls-8px-xs {
    letter-spacing: 8px !important;
  }
}

.ls-10px {
  letter-spacing: 10px;
}
@media (max-width: 575px) {
  .ls-10px-xs {
    letter-spacing: 10px !important;
  }
}

.ls-12px {
  letter-spacing: 12px;
}
@media (max-width: 575px) {
  .ls-12px-xs {
    letter-spacing: 12px !important;
  }
}

.ls-14px {
  letter-spacing: 14px;
}
@media (max-width: 575px) {
  .ls-14px-xs {
    letter-spacing: 14px !important;
  }
}

.ls-16px {
  letter-spacing: 16px;
}
@media (max-width: 575px) {
  .ls-16px-xs {
    letter-spacing: 16px !important;
  }
}

.ls-18px {
  letter-spacing: 18px;
}
@media (max-width: 575px) {
  .ls-18px-xs {
    letter-spacing: 18px !important;
  }
}

.ls-20px {
  letter-spacing: 20px;
}
@media (max-width: 575px) {
  .ls-20px-xs {
    letter-spacing: 20px !important;
  }
}

.ls-22px {
  letter-spacing: 22px;
}
@media (max-width: 575px) {
  .ls-22px-xs {
    letter-spacing: 22px !important;
  }
}

.ls-24px {
  letter-spacing: 24px;
}
@media (max-width: 575px) {
  .ls-24px-xs {
    letter-spacing: 24px !important;
  }
}

.ls-26px {
  letter-spacing: 26px;
}
@media (max-width: 575px) {
  .ls-26px-xs {
    letter-spacing: 26px !important;
  }
}

.ls-28px {
  letter-spacing: 28px;
}
@media (max-width: 575px) {
  .ls-28px-xs {
    letter-spacing: 28px !important;
  }
}

.ls-30px {
  letter-spacing: 30px;
}
@media (max-width: 575px) {
  .ls-30px-xs {
    letter-spacing: 30px !important;
  }
}

.lh-2px {
  line-height: 2px;
}
@media (max-width: 575px) {
  .lh-2px-xs {
    line-height: 2px !important;
  }
}

.lh-4px {
  line-height: 4px;
}
@media (max-width: 575px) {
  .lh-4px-xs {
    line-height: 4px !important;
  }
}

.lh-6px {
  line-height: 6px;
}
@media (max-width: 575px) {
  .lh-6px-xs {
    line-height: 6px !important;
  }
}

.lh-8px {
  line-height: 8px;
}
@media (max-width: 575px) {
  .lh-8px-xs {
    line-height: 8px !important;
  }
}

.lh-10px {
  line-height: 10px;
}
@media (max-width: 575px) {
  .lh-10px-xs {
    line-height: 10px !important;
  }
}

.lh-12px {
  line-height: 12px;
}
@media (max-width: 575px) {
  .lh-12px-xs {
    line-height: 12px !important;
  }
}

.lh-14px {
  line-height: 14px;
}
@media (max-width: 575px) {
  .lh-14px-xs {
    line-height: 14px !important;
  }
}

.lh-16px {
  line-height: 16px;
}
@media (max-width: 575px) {
  .lh-16px-xs {
    line-height: 16px !important;
  }
}

.lh-18px {
  line-height: 18px;
}
@media (max-width: 575px) {
  .lh-18px-xs {
    line-height: 18px !important;
  }
}

.lh-20px {
  line-height: 20px;
}
@media (max-width: 575px) {
  .lh-20px-xs {
    line-height: 20px !important;
  }
}

.lh-22px {
  line-height: 22px;
}
@media (max-width: 575px) {
  .lh-22px-xs {
    line-height: 22px !important;
  }
}

.lh-24px {
  line-height: 24px;
}
@media (max-width: 575px) {
  .lh-24px-xs {
    line-height: 24px !important;
  }
}

.lh-26px {
  line-height: 26px;
}
@media (max-width: 575px) {
  .lh-26px-xs {
    line-height: 26px !important;
  }
}

.lh-28px {
  line-height: 28px;
}
@media (max-width: 575px) {
  .lh-28px-xs {
    line-height: 28px !important;
  }
}

.lh-30px {
  line-height: 30px;
}
@media (max-width: 575px) {
  .lh-30px-xs {
    line-height: 30px !important;
  }
}

.lh-32px {
  line-height: 32px;
}
@media (max-width: 575px) {
  .lh-32px-xs {
    line-height: 32px !important;
  }
}

.lh-34px {
  line-height: 34px;
}
@media (max-width: 575px) {
  .lh-34px-xs {
    line-height: 34px !important;
  }
}

.lh-100px {
  line-height: 100px;
}
@media (max-width: 575px) {
  .lh-100px-xs {
    line-height: 100px !important;
  }
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.cl-white {
  color: #FFFFFF;
}

.cl-darkwhite {
  color: #F5F5F5;
}

.cl-darkerwhite {
  color: #E6E6E6;
}

.cl-blue {
  color: #1B9CF2;
}

.cl-darkblue {
  color: #032672;
}

.cl-xtradarkblue {
  color: #03153B;
}

.cl-lightgrey {
  color: #919191;
}

.cl-steam {
  color: #DDDDDD;
}

.bg-frost {
  background-color: #EAF0F0;
}

.bg-blue {
  background-color: #2EA5EA;
}

.bg-blueest {
  background-color: #1892D8;
}

.bg-dark {
  background-color: #03153B !important;
}

@media (max-width: 575px) {
  .mx-0-xs {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

@media (max-width: 575px) {
  .mt-0-xs {
    margin-top: 0 !important;
  }
}

.mt-6px {
  margin-top: 6px;
}
@media (max-width: 575px) {
  .mt-6px-xs {
    margin-top: 6px !important;
  }
}

.mt-10px {
  margin-top: 10px;
}
@media (max-width: 575px) {
  .mt-10px-xs {
    margin-top: 10px !important;
  }
}

.mt-15px {
  margin-top: 15px;
}
@media (max-width: 575px) {
  .mt-15px-xs {
    margin-top: 15px !important;
  }
}

.mt-20px {
  margin-top: 20px;
}
@media (max-width: 575px) {
  .mt-20px-xs {
    margin-top: 20px !important;
  }
}

.mt-30px {
  margin-top: 30px;
}
@media (max-width: 575px) {
  .mt-30px-xs {
    margin-top: 30px !important;
  }
}

.mt-40px {
  margin-top: 40px;
}
@media (max-width: 575px) {
  .mt-40px-xs {
    margin-top: 40px !important;
  }
}

.mt-50px {
  margin-top: 50px;
}
@media (max-width: 575px) {
  .mt-50px-xs {
    margin-top: 50px !important;
  }
}

.mt-60px {
  margin-top: 60px;
}
@media (max-width: 575px) {
  .mt-60px-xs {
    margin-top: 60px !important;
  }
}

.mt-70px {
  margin-top: 70px;
}
@media (max-width: 575px) {
  .mt-70px-xs {
    margin-top: 70px !important;
  }
}

.mt-80px {
  margin-top: 80px;
}
@media (max-width: 575px) {
  .mt-80px-xs {
    margin-top: 80px !important;
  }
}

.mt-90px {
  margin-top: 90px;
}
@media (max-width: 575px) {
  .mt-90px-xs {
    margin-top: 90px !important;
  }
}

.mt-100px {
  margin-top: 100px;
}
@media (max-width: 575px) {
  .mt-100px-xs {
    margin-top: 100px !important;
  }
}

.mt-110px {
  margin-top: 110px;
}
@media (max-width: 575px) {
  .mt-110px-xs {
    margin-top: 110px !important;
  }
}

.mt-120px {
  margin-top: 120px;
}
@media (max-width: 575px) {
  .mt-120px-xs {
    margin-top: 120px !important;
  }
}

.mt-130px {
  margin-top: 130px;
}
@media (max-width: 575px) {
  .mt-130px-xs {
    margin-top: 130px !important;
  }
}

.mt-135px {
  margin-top: 135px;
}
@media (max-width: 575px) {
  .mt-135px-xs {
    margin-top: 135px !important;
  }
}

.mt-140px {
  margin-top: 140px;
}
@media (max-width: 575px) {
  .mt-140px-xs {
    margin-top: 140px !important;
  }
}

.mt-150px {
  margin-top: 150px;
}
@media (max-width: 575px) {
  .mt-150px-xs {
    margin-top: 150px !important;
  }
}

.mt-160px {
  margin-top: 160px;
}
@media (max-width: 575px) {
  .mt-160px-xs {
    margin-top: 160px !important;
  }
}

.mt-170px {
  margin-top: 170px;
}
@media (max-width: 575px) {
  .mt-170px-xs {
    margin-top: 170px !important;
  }
}

.mt-180px {
  margin-top: 180px;
}
@media (max-width: 575px) {
  .mt-180px-xs {
    margin-top: 180px !important;
  }
}

.mt-190px {
  margin-top: 190px;
}
@media (max-width: 575px) {
  .mt-190px-xs {
    margin-top: 190px !important;
  }
}

.mt-200px {
  margin-top: 200px;
}
@media (max-width: 575px) {
  .mt-200px-xs {
    margin-top: 200px !important;
  }
}

.mt-210px {
  margin-top: 210px;
}
@media (max-width: 575px) {
  .mt-210px-xs {
    margin-top: 210px !important;
  }
}

.mt-220px {
  margin-top: 220px;
}
@media (max-width: 575px) {
  .mt-220px-xs {
    margin-top: 220px !important;
  }
}

@media (max-width: 575px) {
  .mb-0-xs {
    margin-bottom: 0 !important;
  }
}

.mb-6px {
  margin-bottom: 6px;
}
@media (max-width: 575px) {
  .mb-6px-xs {
    margin-bottom: 6px !important;
  }
}

.mb-10px {
  margin-bottom: 10px;
}
@media (max-width: 575px) {
  .mb-10px-xs {
    margin-bottom: 10px !important;
  }
}

.mb-20px {
  margin-bottom: 20px;
}
@media (max-width: 575px) {
  .mb-20px-xs {
    margin-bottom: 20px !important;
  }
}

.mb-28px {
  margin-bottom: 28px;
}
@media (max-width: 575px) {
  .mb-28px-xs {
    margin-bottom: 28px !important;
  }
}

.mb-30px {
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .mb-30px-xs {
    margin-bottom: 30px !important;
  }
}

.mb-40px {
  margin-bottom: 40px;
}
@media (max-width: 575px) {
  .mb-40px-xs {
    margin-bottom: 40px !important;
  }
}

.mb-50px {
  margin-bottom: 50px;
}
@media (max-width: 575px) {
  .mb-50px-xs {
    margin-bottom: 50px !important;
  }
}

.mb-60px {
  margin-bottom: 60px;
}
@media (max-width: 575px) {
  .mb-60px-xs {
    margin-bottom: 60px !important;
  }
}

.mb-70px {
  margin-bottom: 70px;
}
@media (max-width: 575px) {
  .mb-70px-xs {
    margin-bottom: 70px !important;
  }
}

.mb-80px {
  margin-bottom: 80px;
}
@media (max-width: 575px) {
  .mb-80px-xs {
    margin-bottom: 80px !important;
  }
}

.mb-90px {
  margin-bottom: 90px;
}
@media (max-width: 575px) {
  .mb-90px-xs {
    margin-bottom: 90px !important;
  }
}

.mb-100px {
  margin-bottom: 100px;
}
@media (max-width: 575px) {
  .mb-100px-xs {
    margin-bottom: 100px !important;
  }
}

.mb-110px {
  margin-bottom: 110px;
}
@media (max-width: 575px) {
  .mb-110px-xs {
    margin-bottom: 110px !important;
  }
}

.mb-120px {
  margin-bottom: 120px;
}
@media (max-width: 575px) {
  .mb-120px-xs {
    margin-bottom: 120px !important;
  }
}

.mb-130px {
  margin-bottom: 130px;
}
@media (max-width: 575px) {
  .mb-130px-xs {
    margin-bottom: 130px !important;
  }
}

.mb-140px {
  margin-bottom: 140px;
}
@media (max-width: 575px) {
  .mb-140px-xs {
    margin-bottom: 140px !important;
  }
}

.mb-150px {
  margin-bottom: 150px;
}
@media (max-width: 575px) {
  .mb-150px-xs {
    margin-bottom: 150px !important;
  }
}

.mb-160px {
  margin-bottom: 160px;
}
@media (max-width: 575px) {
  .mb-160px-xs {
    margin-bottom: 160px !important;
  }
}

.mb-170px {
  margin-bottom: 170px;
}
@media (max-width: 575px) {
  .mb-170px-xs {
    margin-bottom: 170px !important;
  }
}

.mb-180px {
  margin-bottom: 180px;
}
@media (max-width: 575px) {
  .mb-180px-xs {
    margin-bottom: 180px !important;
  }
}

.mb-190px {
  margin-bottom: 190px;
}
@media (max-width: 575px) {
  .mb-190px-xs {
    margin-bottom: 190px !important;
  }
}

.mb-200px {
  margin-bottom: 200px;
}
@media (max-width: 575px) {
  .mb-200px-xs {
    margin-bottom: 200px !important;
  }
}

@media (max-width: 575px) {
  .ml-0-xs {
    margin-left: 0 !important;
  }
}

.ml-10px {
  margin-left: 10px;
}
@media (max-width: 575px) {
  .ml-10px-xs {
    margin-left: 10px !important;
  }
}

.ml-20px {
  margin-left: 20px;
}
@media (max-width: 575px) {
  .ml-20px-xs {
    margin-left: 20px !important;
  }
}

.ml-30px {
  margin-left: 30px;
}
@media (max-width: 575px) {
  .ml-30px-xs {
    margin-left: 30px !important;
  }
}

.ml-40px {
  margin-left: 40px;
}
@media (max-width: 575px) {
  .ml-40px-xs {
    margin-left: 40px !important;
  }
}

.ml-50px {
  margin-left: 50px;
}
@media (max-width: 575px) {
  .ml-50px-xs {
    margin-left: 50px !important;
  }
}

.ml-60px {
  margin-left: 60px;
}
@media (max-width: 575px) {
  .ml-60px-xs {
    margin-left: 60px !important;
  }
}

.ml-70px {
  margin-left: 70px;
}
@media (max-width: 575px) {
  .ml-70px-xs {
    margin-left: 70px !important;
  }
}

.ml-80px {
  margin-left: 80px;
}
@media (max-width: 575px) {
  .ml-80px-xs {
    margin-left: 80px !important;
  }
}

.ml-90px {
  margin-left: 90px;
}
@media (max-width: 575px) {
  .ml-90px-xs {
    margin-left: 90px !important;
  }
}

.ml-100px {
  margin-left: 100px;
}
@media (max-width: 575px) {
  .ml-100px-xs {
    margin-left: 100px !important;
  }
}

.ml-110px {
  margin-left: 110px;
}
@media (max-width: 575px) {
  .ml-110px-xs {
    margin-left: 110px !important;
  }
}

.ml-120px {
  margin-left: 120px;
}
@media (max-width: 575px) {
  .ml-120px-xs {
    margin-left: 120px !important;
  }
}

.ml-130px {
  margin-left: 130px;
}
@media (max-width: 575px) {
  .ml-130px-xs {
    margin-left: 130px !important;
  }
}

.ml-140px {
  margin-left: 140px;
}
@media (max-width: 575px) {
  .ml-140px-xs {
    margin-left: 140px !important;
  }
}

.ml-150px {
  margin-left: 150px;
}
@media (max-width: 575px) {
  .ml-150px-xs {
    margin-left: 150px !important;
  }
}

.ml-160px {
  margin-left: 160px;
}
@media (max-width: 575px) {
  .ml-160px-xs {
    margin-left: 160px !important;
  }
}

.ml-170px {
  margin-left: 170px;
}
@media (max-width: 575px) {
  .ml-170px-xs {
    margin-left: 170px !important;
  }
}

.ml-180px {
  margin-left: 180px;
}
@media (max-width: 575px) {
  .ml-180px-xs {
    margin-left: 180px !important;
  }
}

.ml-190px {
  margin-left: 190px;
}
@media (max-width: 575px) {
  .ml-190px-xs {
    margin-left: 190px !important;
  }
}

.ml-200px {
  margin-left: 200px;
}
@media (max-width: 575px) {
  .ml-200px-xs {
    margin-left: 200px !important;
  }
}

@media (max-width: 575px) {
  .mr-0-xs {
    margin-right: 0 !important;
  }
}

.mr-10px {
  margin-right: 10px;
}
@media (max-width: 575px) {
  .mr-10px-xs {
    margin-right: 10px !important;
  }
}

.mr-20px {
  margin-right: 20px;
}
@media (max-width: 575px) {
  .mr-20px-xs {
    margin-right: 20px !important;
  }
}

.mr-30px {
  margin-right: 30px;
}
@media (max-width: 575px) {
  .mr-30px-xs {
    margin-right: 30px !important;
  }
}

.mr-40px {
  margin-right: 40px;
}
@media (max-width: 575px) {
  .mr-40px-xs {
    margin-right: 40px !important;
  }
}

.mr-50px {
  margin-right: 50px;
}
@media (max-width: 575px) {
  .mr-50px-xs {
    margin-right: 50px !important;
  }
}

.mr-60px {
  margin-right: 60px;
}
@media (max-width: 575px) {
  .mr-60px-xs {
    margin-right: 60px !important;
  }
}

.mr-70px {
  margin-right: 70px;
}
@media (max-width: 575px) {
  .mr-70px-xs {
    margin-right: 70px !important;
  }
}

.mr-80px {
  margin-right: 80px;
}
@media (max-width: 575px) {
  .mr-80px-xs {
    margin-right: 80px !important;
  }
}

.mr-90px {
  margin-right: 90px;
}
@media (max-width: 575px) {
  .mr-90px-xs {
    margin-right: 90px !important;
  }
}

.mr-100px {
  margin-right: 100px;
}
@media (max-width: 575px) {
  .mr-100px-xs {
    margin-right: 100px !important;
  }
}

.mr-110px {
  margin-right: 110px;
}
@media (max-width: 575px) {
  .mr-110px-xs {
    margin-right: 110px !important;
  }
}

.mr-120px {
  margin-right: 120px;
}
@media (max-width: 575px) {
  .mr-120px-xs {
    margin-right: 120px !important;
  }
}

.mr-130px {
  margin-right: 130px;
}
@media (max-width: 575px) {
  .mr-130px-xs {
    margin-right: 130px !important;
  }
}

.mr-140px {
  margin-right: 140px;
}
@media (max-width: 575px) {
  .mr-140px-xs {
    margin-right: 140px !important;
  }
}

.mr-150px {
  margin-right: 150px;
}
@media (max-width: 575px) {
  .mr-150px-xs {
    margin-right: 150px !important;
  }
}

.mr-160px {
  margin-right: 160px;
}
@media (max-width: 575px) {
  .mr-160px-xs {
    margin-right: 160px !important;
  }
}

.mr-170px {
  margin-right: 170px;
}
@media (max-width: 575px) {
  .mr-170px-xs {
    margin-right: 170px !important;
  }
}

.mr-180px {
  margin-right: 180px;
}
@media (max-width: 575px) {
  .mr-180px-xs {
    margin-right: 180px !important;
  }
}

.mr-190px {
  margin-right: 190px;
}
@media (max-width: 575px) {
  .mr-190px-xs {
    margin-right: 190px !important;
  }
}

.mr-200px {
  margin-right: 200px;
}
@media (max-width: 575px) {
  .mr-200px-xs {
    margin-right: 200px !important;
  }
}

@media (max-width: 575px) {
  .px-0-xs {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (max-width: 575px) {
  .pt-0-xs {
    padding-top: 0 !important;
  }
}

.pt-10px {
  padding-top: 10px;
}
@media (max-width: 575px) {
  .pt-10px-xs {
    padding-top: 10px !important;
  }
}

.pt-12px {
  padding-top: 12px;
}
@media (max-width: 575px) {
  .pt-12px-xs {
    padding-top: 12px !important;
  }
}

.pt-15px {
  padding-top: 15px;
}
@media (max-width: 575px) {
  .pt-15px-xs {
    padding-top: 15px !important;
  }
}

.pt-20px {
  padding-top: 20px;
}
@media (max-width: 575px) {
  .pt-20px-xs {
    padding-top: 20px !important;
  }
}

.pt-24px {
  padding-top: 24px;
}
@media (max-width: 575px) {
  .pt-24px-xs {
    padding-top: 24px !important;
  }
}

.pt-30px {
  padding-top: 30px;
}
@media (max-width: 575px) {
  .pt-30px-xs {
    padding-top: 30px !important;
  }
}

.pt-40px {
  padding-top: 40px;
}
@media (max-width: 575px) {
  .pt-40px-xs {
    padding-top: 40px !important;
  }
}

.pt-50px {
  padding-top: 50px;
}
@media (max-width: 575px) {
  .pt-50px-xs {
    padding-top: 50px !important;
  }
}

.pt-60px {
  padding-top: 60px;
}
@media (max-width: 575px) {
  .pt-60px-xs {
    padding-top: 60px !important;
  }
}

.pt-70px {
  padding-top: 70px;
}
@media (max-width: 575px) {
  .pt-70px-xs {
    padding-top: 70px !important;
  }
}

.pt-80px {
  padding-top: 80px;
}
@media (max-width: 575px) {
  .pt-80px-xs {
    padding-top: 80px !important;
  }
}

.pt-90px {
  padding-top: 90px;
}
@media (max-width: 575px) {
  .pt-90px-xs {
    padding-top: 90px !important;
  }
}

.pt-100px {
  padding-top: 100px;
}
@media (max-width: 575px) {
  .pt-100px-xs {
    padding-top: 100px !important;
  }
}

.pt-110px {
  padding-top: 110px;
}
@media (max-width: 575px) {
  .pt-110px-xs {
    padding-top: 110px !important;
  }
}

.pt-120px {
  padding-top: 120px;
}
@media (max-width: 575px) {
  .pt-120px-xs {
    padding-top: 120px !important;
  }
}

.pt-130px {
  padding-top: 130px;
}
@media (max-width: 575px) {
  .pt-130px-xs {
    padding-top: 130px !important;
  }
}

.pt-135px {
  padding-top: 135px;
}
@media (max-width: 575px) {
  .pt-135px-xs {
    padding-top: 135px !important;
  }
}

.pt-140px {
  padding-top: 140px;
}
@media (max-width: 575px) {
  .pt-140px-xs {
    padding-top: 140px !important;
  }
}

.pt-150px {
  padding-top: 150px;
}
@media (max-width: 575px) {
  .pt-150px-xs {
    padding-top: 150px !important;
  }
}

.pt-160px {
  padding-top: 160px;
}
@media (max-width: 575px) {
  .pt-160px-xs {
    padding-top: 160px !important;
  }
}

.pt-170px {
  padding-top: 170px;
}
@media (max-width: 575px) {
  .pt-170px-xs {
    padding-top: 170px !important;
  }
}

.pt-180px {
  padding-top: 180px;
}
@media (max-width: 575px) {
  .pt-180px-xs {
    padding-top: 180px !important;
  }
}

.pt-190px {
  padding-top: 190px;
}
@media (max-width: 575px) {
  .pt-190px-xs {
    padding-top: 190px !important;
  }
}

.pt-200px {
  padding-top: 200px;
}
@media (max-width: 575px) {
  .pt-200px-xs {
    padding-top: 200px !important;
  }
}

.pt-210px {
  padding-top: 210px;
}
@media (max-width: 575px) {
  .pt-210px-xs {
    padding-top: 210px !important;
  }
}

.pt-220px {
  padding-top: 220px;
}
@media (max-width: 575px) {
  .pt-220px-xs {
    padding-top: 220px !important;
  }
}

.pt-230px {
  padding-top: 230px;
}
@media (max-width: 575px) {
  .pt-230px-xs {
    padding-top: 230px !important;
  }
}

.pt-240px {
  padding-top: 240px;
}
@media (max-width: 575px) {
  .pt-240px-xs {
    padding-top: 240px !important;
  }
}

.pt-250px {
  padding-top: 250px;
}
@media (max-width: 575px) {
  .pt-250px-xs {
    padding-top: 250px !important;
  }
}

.pt-260px {
  padding-top: 260px;
}
@media (max-width: 575px) {
  .pt-260px-xs {
    padding-top: 260px !important;
  }
}

.pt-270px {
  padding-top: 270px;
}
@media (max-width: 575px) {
  .pt-270px-xs {
    padding-top: 270px !important;
  }
}

.pt-280px {
  padding-top: 280px;
}
@media (max-width: 575px) {
  .pt-280px-xs {
    padding-top: 280px !important;
  }
}

.pt-290px {
  padding-top: 290px;
}
@media (max-width: 575px) {
  .pt-290px-xs {
    padding-top: 290px !important;
  }
}

.pt-460px {
  padding-top: 460px;
}
@media (max-width: 575px) {
  .pt-460px-xs {
    padding-top: 460px !important;
  }
}

.pt-660px {
  padding-top: 34.682vw;
}
@media (max-width: 575px) {
  .pt-660px-xs {
    padding-top: 34.682vw !important;
  }
}

@media (max-width: 575px) {
  .pb-0-xs {
    padding-bottom: 0 !important;
  }
}

.pb-10px {
  padding-bottom: 10px;
}
@media (max-width: 575px) {
  .pb-10px-xs {
    padding-bottom: 10px !important;
  }
}

.pb-12px {
  padding-bottom: 12px;
}
@media (max-width: 575px) {
  .pb-12px-xs {
    padding-bottom: 12px !important;
  }
}

.pb-15px {
  padding-bottom: 15px;
}
@media (max-width: 575px) {
  .pb-15px-xs {
    padding-bottom: 15px !important;
  }
}

.pb-20px {
  padding-bottom: 20px;
}
@media (max-width: 575px) {
  .pb-20px-xs {
    padding-bottom: 20px !important;
  }
}

.pb-24px {
  padding-bottom: 24px;
}
@media (max-width: 575px) {
  .pb-24px-xs {
    padding-bottom: 24px !important;
  }
}

.pb-30px {
  padding-bottom: 30px;
}
@media (max-width: 575px) {
  .pb-30px-xs {
    padding-bottom: 30px !important;
  }
}

.pb-40px {
  padding-bottom: 40px;
}
@media (max-width: 575px) {
  .pb-40px-xs {
    padding-bottom: 40px !important;
  }
}

.pb-50px {
  padding-bottom: 50px;
}
@media (max-width: 575px) {
  .pb-50px-xs {
    padding-bottom: 50px !important;
  }
}

.pb-60px {
  padding-bottom: 60px;
}
@media (max-width: 575px) {
  .pb-60px-xs {
    padding-bottom: 60px !important;
  }
}

.pb-70px {
  padding-bottom: 70px;
}
@media (max-width: 575px) {
  .pb-70px-xs {
    padding-bottom: 70px !important;
  }
}

.pb-80px {
  padding-bottom: 80px;
}
@media (max-width: 575px) {
  .pb-80px-xs {
    padding-bottom: 80px !important;
  }
}

.pb-90px {
  padding-bottom: 90px;
}
@media (max-width: 575px) {
  .pb-90px-xs {
    padding-bottom: 90px !important;
  }
}

.pb-100px {
  padding-bottom: 100px;
}
@media (max-width: 575px) {
  .pb-100px-xs {
    padding-bottom: 100px !important;
  }
}

.pb-110px {
  padding-bottom: 110px;
}
@media (max-width: 575px) {
  .pb-110px-xs {
    padding-bottom: 110px !important;
  }
}

.pb-120px {
  padding-bottom: 120px;
}
@media (max-width: 575px) {
  .pb-120px-xs {
    padding-bottom: 120px !important;
  }
}

.pb-130px {
  padding-bottom: 130px;
}
@media (max-width: 575px) {
  .pb-130px-xs {
    padding-bottom: 130px !important;
  }
}

.pb-140px {
  padding-bottom: 140px;
}
@media (max-width: 575px) {
  .pb-140px-xs {
    padding-bottom: 140px !important;
  }
}

.pb-150px {
  padding-bottom: 150px;
}
@media (max-width: 575px) {
  .pb-150px-xs {
    padding-bottom: 150px !important;
  }
}

.pb-160px {
  padding-bottom: 160px;
}
@media (max-width: 575px) {
  .pb-160px-xs {
    padding-bottom: 160px !important;
  }
}

.pb-170px {
  padding-bottom: 170px;
}
@media (max-width: 575px) {
  .pb-170px-xs {
    padding-bottom: 170px !important;
  }
}

.pb-180px {
  padding-bottom: 180px;
}
@media (max-width: 575px) {
  .pb-180px-xs {
    padding-bottom: 180px !important;
  }
}

.pb-190px {
  padding-bottom: 190px;
}
@media (max-width: 575px) {
  .pb-190px-xs {
    padding-bottom: 190px !important;
  }
}

.pb-200px {
  padding-bottom: 200px;
}
@media (max-width: 575px) {
  .pb-200px-xs {
    padding-bottom: 200px !important;
  }
}

.pb-210px {
  padding-bottom: 210px;
}
@media (max-width: 575px) {
  .pb-210px-xs {
    padding-bottom: 210px !important;
  }
}

.pb-220px {
  padding-bottom: 220px;
}
@media (max-width: 575px) {
  .pb-220px-xs {
    padding-bottom: 220px !important;
  }
}

.pb-230px {
  padding-bottom: 230px;
}
@media (max-width: 575px) {
  .pb-230px-xs {
    padding-bottom: 230px !important;
  }
}

.pb-270px {
  padding-bottom: 270px;
}
@media (max-width: 575px) {
  .pb-270px-xs {
    padding-bottom: 270px !important;
  }
}

@media (max-width: 575px) {
  .pl-0-xs {
    padding-left: 0 !important;
  }
}

.pl-10px {
  padding-left: 10px;
}
@media (max-width: 575px) {
  .pl-10px-xs {
    padding-left: 10px !important;
  }
}

.pl-15px {
  padding-left: 15px;
}
@media (max-width: 575px) {
  .pl-15px-xs {
    padding-left: 15px !important;
  }
}

.pl-20px {
  padding-left: 20px;
}
@media (max-width: 575px) {
  .pl-20px-xs {
    padding-left: 20px !important;
  }
}

.pl-30px {
  padding-left: 30px;
}
@media (max-width: 575px) {
  .pl-30px-xs {
    padding-left: 30px !important;
  }
}

.pl-40px {
  padding-left: 40px;
}
@media (max-width: 575px) {
  .pl-40px-xs {
    padding-left: 40px !important;
  }
}

.pl-50px {
  padding-left: 50px;
}
@media (max-width: 575px) {
  .pl-50px-xs {
    padding-left: 50px !important;
  }
}

.pl-60px {
  padding-left: 60px;
}
@media (max-width: 575px) {
  .pl-60px-xs {
    padding-left: 60px !important;
  }
}

.pl-70px {
  padding-left: 70px;
}
@media (max-width: 575px) {
  .pl-70px-xs {
    padding-left: 70px !important;
  }
}

.pl-80px {
  padding-left: 80px;
}
@media (max-width: 575px) {
  .pl-80px-xs {
    padding-left: 80px !important;
  }
}

.pl-90px {
  padding-left: 90px;
}
@media (max-width: 575px) {
  .pl-90px-xs {
    padding-left: 90px !important;
  }
}

.pl-95px {
  padding-left: 95px;
}
@media (max-width: 575px) {
  .pl-95px-xs {
    padding-left: 95px !important;
  }
}

.pl-100px {
  padding-left: 100px;
}
@media (max-width: 575px) {
  .pl-100px-xs {
    padding-left: 100px !important;
  }
}

.pl-110px {
  padding-left: 110px;
}
@media (max-width: 575px) {
  .pl-110px-xs {
    padding-left: 110px !important;
  }
}

.pl-120px {
  padding-left: 120px;
}
@media (max-width: 575px) {
  .pl-120px-xs {
    padding-left: 120px !important;
  }
}

.pl-130px {
  padding-left: 130px;
}
@media (max-width: 575px) {
  .pl-130px-xs {
    padding-left: 130px !important;
  }
}

.pl-140px {
  padding-left: 140px;
}
@media (max-width: 575px) {
  .pl-140px-xs {
    padding-left: 140px !important;
  }
}

.pl-150px {
  padding-left: 150px;
}
@media (max-width: 575px) {
  .pl-150px-xs {
    padding-left: 150px !important;
  }
}

.pl-160px {
  padding-left: 160px;
}
@media (max-width: 575px) {
  .pl-160px-xs {
    padding-left: 160px !important;
  }
}

.pl-170px {
  padding-left: 170px;
}
@media (max-width: 575px) {
  .pl-170px-xs {
    padding-left: 170px !important;
  }
}

.pl-180px {
  padding-left: 180px;
}
@media (max-width: 575px) {
  .pl-180px-xs {
    padding-left: 180px !important;
  }
}

.pl-190px {
  padding-left: 190px;
}
@media (max-width: 575px) {
  .pl-190px-xs {
    padding-left: 190px !important;
  }
}

.pl-200px {
  padding-left: 200px;
}
@media (max-width: 575px) {
  .pl-200px-xs {
    padding-left: 200px !important;
  }
}

@media (max-width: 575px) {
  .pr-0-xs {
    padding-right: 0 !important;
  }
}

.pr-10px {
  padding-right: 10px;
}
@media (max-width: 575px) {
  .pr-10px-xs {
    padding-right: 10px !important;
  }
}

.pr-15px {
  padding-right: 15px;
}
@media (max-width: 575px) {
  .pr-15px-xs {
    padding-right: 15px !important;
  }
}

.pr-20px {
  padding-right: 20px;
}
@media (max-width: 575px) {
  .pr-20px-xs {
    padding-right: 20px !important;
  }
}

.pr-30px {
  padding-right: 30px !important;
}
@media (max-width: 575px) {
  .pr-30px-xs {
    padding-right: 30px !important;
  }
}

.pr-40px {
  padding-right: 40px;
}
@media (max-width: 575px) {
  .pr-40px-xs {
    padding-right: 40px !important;
  }
}

.pr-50px {
  padding-right: 50px;
}
@media (max-width: 575px) {
  .pr-50px-xs {
    padding-right: 50px !important;
  }
}

.pr-60px {
  padding-right: 60px;
}
@media (max-width: 575px) {
  .pr-60px-xs {
    padding-right: 60px !important;
  }
}

.pr-70px {
  padding-right: 70px;
}
@media (max-width: 575px) {
  .pr-70px-xs {
    padding-right: 70px !important;
  }
}

.pr-80px {
  padding-right: 80px;
}
@media (max-width: 575px) {
  .pr-80px-xs {
    padding-right: 80px !important;
  }
}

.pr-90px {
  padding-right: 90px;
}
@media (max-width: 575px) {
  .pr-90px-xs {
    padding-right: 90px !important;
  }
}

.pr-100px {
  padding-right: 100px;
}
@media (max-width: 575px) {
  .pr-100px-xs {
    padding-right: 100px !important;
  }
}

.pr-110px {
  padding-right: 110px;
}
@media (max-width: 575px) {
  .pr-110px-xs {
    padding-right: 110px !important;
  }
}

.pr-120px {
  padding-right: 120px;
}
@media (max-width: 575px) {
  .pr-120px-xs {
    padding-right: 120px !important;
  }
}

.pr-130px {
  padding-right: 130px;
}
@media (max-width: 575px) {
  .pr-130px-xs {
    padding-right: 130px !important;
  }
}

.pr-140px {
  padding-right: 140px;
}
@media (max-width: 575px) {
  .pr-140px-xs {
    padding-right: 140px !important;
  }
}

.pr-150px {
  padding-right: 150px;
}
@media (max-width: 575px) {
  .pr-150px-xs {
    padding-right: 150px !important;
  }
}

.pr-160px {
  padding-right: 160px;
}
@media (max-width: 575px) {
  .pr-160px-xs {
    padding-right: 160px !important;
  }
}

.pr-170px {
  padding-right: 170px;
}
@media (max-width: 575px) {
  .pr-170px-xs {
    padding-right: 170px !important;
  }
}

.pr-180px {
  padding-right: 180px;
}
@media (max-width: 575px) {
  .pr-180px-xs {
    padding-right: 180px !important;
  }
}

.pr-190px {
  padding-right: 190px;
}
@media (max-width: 575px) {
  .pr-190px-xs {
    padding-right: 190px !important;
  }
}

.pr-200px {
  padding-right: 200px;
}
@media (max-width: 575px) {
  .pr-200px-xs {
    padding-right: 200px !important;
  }
}

.bb_1x_silver {
  border-bottom: 1px solid #E6E6E6;
}

.witdh-0 {
  width: 0;
}
@media (width: 575px) {
  .witdh-0-xs {
    width: 0 !important;
  }
}

.witdh-5 {
  width: 5%;
}
@media (width: 575px) {
  .witdh-5-xs {
    width: 5% !important;
  }
}

.witdh-10 {
  width: 10%;
}
@media (width: 575px) {
  .witdh-10-xs {
    width: 10% !important;
  }
}

.witdh-15 {
  width: 15%;
}
@media (width: 575px) {
  .witdh-15-xs {
    width: 15% !important;
  }
}

.witdh-20 {
  width: 20%;
}
@media (width: 575px) {
  .witdh-20-xs {
    width: 20% !important;
  }
}

.witdh-25 {
  width: 25%;
}
@media (width: 575px) {
  .witdh-25-xs {
    width: 25% !important;
  }
}

.witdh-30 {
  width: 30%;
}
@media (width: 575px) {
  .witdh-30-xs {
    width: 30% !important;
  }
}

.witdh-35 {
  width: 35%;
}
@media (width: 575px) {
  .witdh-35-xs {
    width: 35% !important;
  }
}

.witdh-40 {
  width: 40%;
}
@media (width: 575px) {
  .witdh-40-xs {
    width: 40% !important;
  }
}

.witdh-45 {
  width: 45%;
}
@media (width: 575px) {
  .witdh-45-xs {
    width: 45% !important;
  }
}

.witdh-50 {
  width: 50%;
}
@media (width: 575px) {
  .witdh-50-xs {
    width: 50% !important;
  }
}

.witdh-55 {
  width: 55%;
}
@media (width: 575px) {
  .witdh-55-xs {
    width: 55% !important;
  }
}

.witdh-60 {
  width: 60%;
}
@media (width: 575px) {
  .witdh-60-xs {
    width: 60% !important;
  }
}

.witdh-65 {
  width: 65%;
}
@media (width: 575px) {
  .witdh-65-xs {
    width: 65% !important;
  }
}

.witdh-70 {
  width: 70%;
}
@media (width: 575px) {
  .witdh-70-xs {
    width: 70% !important;
  }
}

.witdh-75 {
  width: 75%;
}
@media (width: 575px) {
  .witdh-75-xs {
    width: 75% !important;
  }
}

.witdh-80 {
  width: 80%;
}
@media (width: 575px) {
  .witdh-80-xs {
    width: 80% !important;
  }
}

.witdh-85 {
  width: 85%;
}
@media (width: 575px) {
  .witdh-85-xs {
    width: 85% !important;
  }
}

.witdh-90 {
  width: 90%;
}
@media (width: 575px) {
  .witdh-90-xs {
    width: 90% !important;
  }
}

.witdh-95 {
  width: 95%;
}
@media (width: 575px) {
  .witdh-95-xs {
    width: 95% !important;
  }
}

.witdh-100 {
  width: 100%;
}
@media (width: 575px) {
  .witdh-100-xs {
    width: 100% !important;
  }
}

.height-0 {
  height: 0;
}
@media (width: 575px) {
  .height-0-xs {
    height: 0 !important;
  }
}

.height-5 {
  height: 5%;
}
@media (width: 575px) {
  .height-5-xs {
    height: 5% !important;
  }
}

.height-10 {
  height: 10%;
}
@media (width: 575px) {
  .height-10-xs {
    height: 10% !important;
  }
}

.height-15 {
  height: 15%;
}
@media (width: 575px) {
  .height-15-xs {
    height: 15% !important;
  }
}

.height-20 {
  height: 20%;
}
@media (width: 575px) {
  .height-20-xs {
    height: 20% !important;
  }
}

.height-25 {
  height: 25%;
}
@media (width: 575px) {
  .height-25-xs {
    height: 25% !important;
  }
}

.height-30 {
  height: 30%;
}
@media (width: 575px) {
  .height-30-xs {
    height: 30% !important;
  }
}

.height-35 {
  height: 35%;
}
@media (width: 575px) {
  .height-35-xs {
    height: 35% !important;
  }
}

.height-40 {
  height: 40%;
}
@media (width: 575px) {
  .height-40-xs {
    height: 40% !important;
  }
}

.height-45 {
  height: 45%;
}
@media (width: 575px) {
  .height-45-xs {
    height: 45% !important;
  }
}

.height-50 {
  height: 50%;
}
@media (width: 575px) {
  .height-50-xs {
    height: 50% !important;
  }
}

.height-55 {
  height: 55%;
}
@media (width: 575px) {
  .height-55-xs {
    height: 55% !important;
  }
}

.height-60 {
  height: 60%;
}
@media (width: 575px) {
  .height-60-xs {
    height: 60% !important;
  }
}

.height-65 {
  height: 65%;
}
@media (width: 575px) {
  .height-65-xs {
    height: 65% !important;
  }
}

.height-70 {
  height: 70%;
}
@media (width: 575px) {
  .height-70-xs {
    height: 70% !important;
  }
}

.height-75 {
  height: 75%;
}
@media (width: 575px) {
  .height-75-xs {
    height: 75% !important;
  }
}

.height-80 {
  height: 80%;
}
@media (width: 575px) {
  .height-80-xs {
    height: 80% !important;
  }
}

.height-85 {
  height: 85%;
}
@media (width: 575px) {
  .height-85-xs {
    height: 85% !important;
  }
}

.height-90 {
  height: 90%;
}
@media (width: 575px) {
  .height-90-xs {
    height: 90% !important;
  }
}

.height-95 {
  height: 95%;
}
@media (width: 575px) {
  .height-95-xs {
    height: 95% !important;
  }
}

.height-100 {
  height: 100%;
}
@media (width: 575px) {
  .height-100-xs {
    height: 100% !important;
  }
}

.button-main-slider-container {
  max-width: 357px;
}

.button-main-slider {
  border: 2px solid #1B9CF2;
  width: 100%;
  color: #ffffff;
  font-family: "Be Vietnam Pro Bold", sans-serif;
  font-size: 14px;
  height: 60px;
  background-color: transparent;
  text-transform: uppercase;
  display: flex;
  text-align: center;
  align-items: center;
  letter-spacing: normal;
  word-spacing: normal;
  text-rendering: auto;
  -ms-writing-mode: lr-tb !important;
  writing-mode: horizontal-tb !important;
  text-decoration: none;
  justify-content: center;
}
.button-main-slider:hover {
  background-color: #1B9CF2;
  color: #ffffff;
}
.button-main-slider:active {
  background-color: #1B9CF2;
}

.button-training-block {
  border: 1px solid #ffffff;
  width: 100%;
  color: #ffffff;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 700;
  font-size: 14px;
  height: 60px;
  text-transform: uppercase;
  background: linear-gradient(to left, transparent 50%, white 50%);
  background-size: 200%;
  background-position: calc(100% + 1px);
  transition: background-position 0.3s ease-out;
  padding: 1px 6px;
  text-indent: 0;
  text-shadow: none;
  display: flex;
  text-align: center;
  align-items: center;
  letter-spacing: normal;
  word-spacing: normal;
  text-rendering: auto;
  -ms-writing-mode: lr-tb !important;
  writing-mode: horizontal-tb !important;
  text-decoration: none;
  justify-content: center;
}
.button-training-block.inline {
  width: unset;
  padding: 20px 60px;
  display: inline;
}
.button-training-block.inline svg {
  height: 12px;
  width: 12px;
  margin: 0 0 4px 10px;
  stroke: #ffffff;
}
@media (max-width: 767px) {
  .button-training-block.inline {
    display: block;
    padding: 20px 30px;
  }
}
.button-training-block:hover {
  background-position: left;
  color: #03153B;
}
.button-training-block:hover.inline svg {
  stroke: #03153B;
}

.button-googlemaps-round {
  border: 2px solid #ECECEC;
  border-radius: 30px;
  background-color: #ffffff;
  transition: background-color 0.2s ease-in;
  font-family: "Be Vietnam Pro Bold", sans-serif;
  font-size: 14px;
  color: #03153B;
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.button-googlemaps-round:hover {
  background-color: #ECECEC;
  color: #03153B;
}

.button-join_us {
  font-family: "Be Vietnam Pro Bold", sans-serif;
  font-size: 14px;
  color: #03153B;
  padding: 16px 90px;
  border: 2px solid #1B9CF2;
  text-transform: uppercase;
  text-decoration: none;
  background: linear-gradient(to top, #1B9CF2 50%, #fff 50%);
  background-size: 100% 200%;
  background-position: top;
  transition: background-position 0.3s ease-out;
  border-radius: 0;
}
.button-join_us:hover {
  background-position: bottom;
  color: #FFFFFF;
}

.button-job-apply {
  font-family: "Be Vietnam Pro Bold", sans-serif;
  font-size: 14px;
  color: #03153B;
  padding: 16px 90px;
  border: 2px solid #1B9CF2;
  text-transform: uppercase;
  text-decoration: none;
  background: linear-gradient(to left, #fff 50%, #1B9CF2 50%);
  background-size: 200%;
  background-position: right;
  transition: background-position 0.3s ease-out;
  border-radius: 0;
}
.button-job-apply:hover {
  background-position: left;
  color: #FFFFFF;
}
@media (max-width: 453px) {
  .button-job-apply {
    padding: 16px 50px;
  }
}

.container {
  max-width: 1402px;
}
.container-fluid {
  max-width: 1860px;
  margin-left: auto;
  margin-right: auto;
}
.container-medium {
  max-width: 1650px;
  margin-left: auto;
  margin-right: auto;
}
.container-large {
  max-width: 1552px;
  margin-left: auto;
  margin-right: auto;
}
.container-small {
  max-width: 1164px;
  margin-left: auto;
  margin-right: auto;
}
.container-xsmall {
  max-width: 922px;
  margin-left: auto;
  margin-right: auto;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.fw-semibold {
  font-weight: 500 !important;
}

.collapseTopWhite {
  height: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: -1;
  position: absolute;
  top: 0;
}
.collapseTopWhite.show {
  z-index: 1;
  height: 420px;
}

.navbar {
  height: 70px;
  background-color: rgb(3, 21, 59);
  z-index: 10;
  position: sticky;
  top: 0;
  transition: all 1s ease;
}
@media (max-width: 991px) {
  .navbar {
    padding-top: 0;
  }
}
.navbar .container-fluid {
  z-index: 2;
  height: 70px;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  padding: 0 62px;
}
@media (max-width: 575px) {
  .navbar .container-fluid {
    padding: 0 60px;
  }
}
@media (max-width: 453px) {
  .navbar .container-fluid {
    padding: 0 40px;
  }
}
@media (max-width: 405px) {
  .navbar .container-fluid {
    padding: 0 15px;
  }
}
.navbar .container-fluid .navbar-brand > img.logo {
  width: 222px;
  height: auto;
}
.navbar .container-fluid .navbar-toggler {
  border: 0;
  padding: 0;
  font-size: 20px;
  line-height: 0;
  border-radius: 0;
  transition: unset;
}
.navbar .container-fluid .navbar-toggler:focus {
  box-shadow: none;
}
.navbar .container-fluid .navbar-collapse {
  height: 70px;
}
.navbar .container-fluid .navbar-collapse .navbar-nav {
  height: 70px;
  align-items: center;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item {
  background-color: transparent;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item.last {
  padding-right: 14px;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link {
  font-family: "Be Vietnam Pro Bold", sans-serif;
  font-size: 13px;
  color: #ffffff;
  padding: 1.261vw 0.42vw;
  background-color: transparent;
  border-width: 0;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link .iconify {
  transition: transform 0.3s ease-out;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link:hover, .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.open {
  color: #1B9CF2 !important;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link:hover .iconify, .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.open .iconify {
  color: #1B9CF2;
  transform: rotate(90deg);
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.sites {
  padding: 0 15px 0 15px;
  background-color: #1B9CF2;
  width: 150px;
  white-space: normal;
  height: 50px;
  align-items: center;
}
@media (max-width: 1136px) {
  .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.sites {
    width: auto;
  }
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.sites::after {
  margin: auto;
  transform: rotate(-90deg);
  transition: all 0.3s ease;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.sites:hover, .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.sites.show {
  color: #ffffff !important;
  background-color: unset;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.sites:hover.blue_gb, .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.sites.show.blue_gb {
  background-color: #03153B;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.sites:hover::after, .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.sites.show::after {
  transform: rotate(0deg);
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.sites .c-text {
  padding-left: 6px;
}
@media (max-width: 1136px) {
  .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.sites .c-text {
    padding-right: 10px;
  }
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.langs {
  background-color: #344361;
  padding: 0;
  width: 50px;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.langs::after {
  transform: rotate(-90deg);
  transition: all 0.3s ease;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.langs:hover, .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.langs.show {
  color: #ffffff;
  background-color: transparent;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.langs:hover.blue_gb, .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.langs.show.blue_gb {
  color: #ffffff !important;
  background-color: #03153B !important;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.langs:hover::after, .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link.langs.show::after {
  transform: rotate(0deg);
}
@media (max-width: 1180px) {
  .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link {
    font-size: 12px;
  }
}
@media (max-width: 1106px) {
  .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link {
    font-size: 11px;
  }
}
@media (max-width: 1060px) {
  .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link {
    font-size: 10px;
  }
}
@media (max-width: 1012px) {
  .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .nav-link {
    font-size: 9px;
  }
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .collapsing, .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .collapse {
  transition: height 0s ease;
  position: absolute;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .collapsing ul, .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .collapse ul {
  list-style: none;
  padding-left: 1.5rem;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .collapsing ul li, .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .collapse ul li {
  margin-bottom: 15px;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .collapsing ul li a, .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .collapse ul li a {
  font-family: "Be Vietnam Pro Bold", sans-serif;
  font-size: 13px;
  color: #03153B;
  text-decoration: none;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .collapsing ul li a:hover, .navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .collapse ul li a:hover {
  color: #1B9CF2;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .dropdown-menu {
  display: none;
  font-family: "Be Vietnam Pro", sans-serif;
  margin-top: 0;
  border: 0;
  border-radius: 0;
  min-width: unset;
  padding: 0;
  opacity: 1;
  z-index: 1;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .dropdown-menu.sites {
  width: 150px;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .dropdown-menu.langs {
  width: 50px;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .dropdown-menu li .dropdown-item {
  padding: 9px 16px;
  color: #032776;
  font-size: 11px;
  font-weight: 700;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .dropdown-menu li .dropdown-item:hover {
  background-color: #E5E6E5;
}
.navbar .container-fluid .navbar-collapse .navbar-nav .nav-item .dropdown-menu li .dropdown-item img {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.offcanvas-start {
  width: 100%;
  border-right: none;
  overflow: scroll;
}
.offcanvas-start .offcanvas-header .btn-close {
  font-size: 22px;
  color: #03153B !important;
  background: none;
  opacity: 1;
  margin: 0;
  width: auto;
  height: auto;
}
.offcanvas-start .offcanvas-body {
  padding: 0;
  overflow: unset;
  flex-grow: 0;
}
.offcanvas-start .offcanvas-body a .iconify {
  float: left;
  margin-top: 5px;
}
.offcanvas-start .offcanvas-body a:hover, .offcanvas-start .offcanvas-body a:active, .offcanvas-start .offcanvas-body a:focus {
  color: #1892D8;
}
.offcanvas-start .offcanvas-body a:hover .iconify, .offcanvas-start .offcanvas-body a:active .iconify, .offcanvas-start .offcanvas-body a:focus .iconify {
  color: #1892D8;
}

footer {
  position: relative;
  z-index: 3;
}
footer .container {
  padding-top: 84px;
  padding-bottom: 86px;
}
@media (min-width: 454px) and (max-width: 1498px) {
  footer .container {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
@media (max-width: 575px) {
  footer .container {
    padding-top: 0;
    padding-bottom: 0;
    word-break: break-all;
  }
}
footer .container .row {
  padding-top: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid #3B4E76;
}
footer .container .row:first-of-type {
  border-top: 1px solid #3B4E76;
}
footer .container .row .col {
  padding: 0;
  color: #ffffff;
}
footer .container .row .col img {
  margin-right: 16px;
}
footer .container .row .col a {
  color: #ffffff;
  text-decoration: none;
}
footer .container .row .col a:hover {
  text-decoration: underline;
}
footer .col-mobile {
  padding-left: 0;
  padding-right: 0;
}
footer .col-mobile > .row {
  padding: 0 30px;
}
footer .col-mobile > .row .col .accordion-button {
  padding: 18px 0;
  background-color: transparent;
  color: #fff;
}
footer .col-mobile > .row .col .accordion-button::after {
  flex-shrink: 0;
  width: 12px;
  height: 23px;
  margin-left: auto;
  content: "";
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17.139" height="32.864" viewBox="0 0 17.139 32.864"><path d="M0,0,16.078,16.078,32.157,0" transform="translate(0.354 32.51) rotate(-90)" fill="none" stroke="#ffffff" stroke-width="2"/></svg>');
  background-repeat: no-repeat;
  background-size: 8px;
  transition: transform 0.2s ease-in-out;
  transform: rotate(90deg);
  margin-right: 6px;
}
footer .col-mobile > .row .col .accordion-button:not(.collapsed)::after {
  transform: rotate(-90deg);
}
footer .col-mobile > .row .col .accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}

.section_credits {
  padding-top: 35px;
  padding-bottom: 15px;
  position: relative;
  z-index: 3;
}
@media (min-width: 454px) and (max-width: 1498px) {
  .section_credits .container {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
.section_credits .container .row a {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  padding-right: 10px;
}
.section_credits .container .row a:first-of-type {
  padding-right: 20px;
}
@media (max-width: 575px) {
  .section_credits .container .row a:first-of-type {
    padding-right: 0;
  }
}
.section_credits .container .row a:hover {
  text-decoration: underline;
}
.section_credits .container .row:last-of-type {
  text-align: right;
}
@media (max-width: 767px) {
  .section_credits .container .footer_license {
    display: flex;
    flex-direction: column;
  }
  .section_credits .container .footer_license .footer_conditions {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
  }
  .section_credits .container .footer_license .footer_conditions a.socials {
    margin-bottom: 16px;
  }
  .section_credits .container .footer_license .footer_logo {
    text-align: center;
  }
}
@media (max-width: 767px) and (max-width: 575px) {
  .section_credits .container .footer_license .footer_logo {
    text-align: left;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .section_credits .container .footer_license .footer_logo img {
    max-width: 222px;
  }
}
@media (max-width: 575px) {
  .section_credits .container .footer_license .footer_conditions {
    align-items: start;
    flex-direction: column;
  }
  .section_credits .container .footer_license .footer_conditions a {
    margin-bottom: 16px;
  }
}

.section_main_slider {
  background-color: rgb(3, 21, 59);
}

.splide.home .splide__track .splide__list .splide__slide > .row {
  height: 100%;
}
.splide.home .splide__track .splide__list .splide__slide > .row > .col-left {
  padding: 5.86vh 50px 60px 50px;
  max-width: 490px;
  background-repeat: no-repeat;
  background-position: bottom left;
}
@media (max-width: 991px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left {
    max-width: unset;
    background-size: cover;
  }
}
@media (max-width: 405px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left {
    padding: 5.86vh 5px 60px 5px;
  }
}
.splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top h1 {
  font-family: "IBM Plex Mono ExtraLight", sans-serif;
  text-transform: uppercase;
  text-decoration: underline;
  color: #5A6A8B;
  text-decoration-thickness: 2px;
  text-underline-offset: 8px;
}
@media (max-width: 899px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top h1 {
    font-size: 5vw;
  }
}
@media (max-width: 575px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top h1 {
    font-size: 58px;
  }
}
@media (max-width: 506px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top h1 {
    font-size: 10vw;
  }
}
@media (max-height: 620px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top h1 {
    font-size: 6vh;
    line-height: 9vh;
  }
}
.splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters {
  padding-top: 5.33vh;
  padding-right: 1.33vh;
}
@media (max-width: 606px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters {
    padding-top: 1vh;
  }
}
@media (max-width: 575px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters {
    padding-top: 5.33vh;
  }
}
@media (max-width: 288px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters {
    padding-top: 2vh;
  }
}
.splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters:last-of-type {
  padding-top: 3.2vh;
}
@media (max-width: 606px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters:last-of-type {
    padding-top: 1vh;
  }
}
@media (max-width: 575px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters:last-of-type {
    padding-top: 3.2vh;
  }
}
@media (max-width: 288px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters:last-of-type {
    padding-top: 2vh;
  }
}
.splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters .c-number, .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters .c-number-t {
  display: inline-block;
  font-family: "Be Vietnam Pro", sans-serif;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 5.2vh;
}
@media (max-width: 926px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters .c-number, .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters .c-number-t {
    font-size: 3vw;
  }
}
@media (max-width: 767px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters .c-number, .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters .c-number-t {
    font-size: 4vw;
  }
}
@media (max-width: 575px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters .c-number, .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters .c-number-t {
    font-size: 40px;
  }
}
@media (max-height: 620px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters .c-number, .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters .c-number-t {
    font-size: 5vh;
  }
}
.splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters .c-text {
  font-family: "Be Vietnam Pro", sans-serif;
  text-transform: uppercase;
  color: #1B9CF2;
}
@media (max-width: 575px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-top .counters .c-text {
    font-size: 10px;
  }
}
.splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-bottom {
  position: absolute;
  bottom: 70px;
  width: calc(100% - 100px);
}
@media (max-width: 405px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-bottom {
    width: calc(100% - 9px);
  }
}
@media (max-width: 991px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-bottom {
    text-align: -webkit-center;
  }
}
@media (max-height: 620px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-bottom {
    bottom: 10vh;
  }
}
@media (max-height: 525px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-bottom {
    position: unset;
  }
}
.splide.home .splide__track .splide__list .splide__slide > .row > .col-left > .row > .col-bottom .button-main-slider:last-of-type {
  margin-top: 2.13vh;
}
.splide.home .splide__track .splide__list .splide__slide > .row > .col-right {
  padding-left: 0;
  padding-right: 0;
  height: 100%;
}
@media (max-width: 575px) {
  .splide.home .splide__track .splide__list .splide__slide > .row > .col-right > .row {
    max-width: unset;
  }
}

.splide.inside.vertical {
  padding: 0;
  height: 100%;
}
.splide.inside.vertical .overlay {
  height: 100%;
  width: 100%;
  background: rgb(3, 21, 59);
  background: linear-gradient(180deg, rgba(3, 21, 59, 0) 0%, rgba(3, 21, 59, 0.2) 70%, rgba(3, 21, 59, 0.7) 100%);
  text-align: right;
  padding-right: 35px;
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
}
.splide.inside.vertical .overlay .content {
  max-width: 600px;
  padding-right: 80px;
}
.splide.inside.vertical .splide__pagination {
  top: 0;
  right: 62px;
  bottom: unset;
  padding: 0;
}
.splide.inside.vertical .splide__pagination li .splide__pagination__page {
  background: transparent;
  height: 21px;
  width: 21px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.splide.inside.vertical .splide__pagination li .splide__pagination__page::before {
  content: "";
  background: transparent;
  width: 19px;
  height: 19px;
  display: block;
  position: absolute;
  border-radius: 50%;
  margin-top: -9px;
  margin-left: 1px;
}
.splide.inside.vertical .splide__pagination li .splide__pagination__page::after {
  content: "";
  background: #ffffff;
  width: 3px;
  height: 3px;
  display: block;
  position: absolute;
  border-radius: 50%;
  margin-left: 9px;
  margin-top: -1px;
}
.splide.inside.vertical .splide__pagination li .splide__pagination__page.is-active {
  background: rgba(3, 38, 114, 0.4);
  transform: unset;
}
.splide.inside.vertical .splide__pagination li .splide__pagination__page:hover {
  background: rgba(3, 38, 114, 0.4);
  transform: unset;
}
.splide.inside.vertical .splide__track .splide__list .splide__slide > .col {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  height: 100%;
}

.section_news_slider {
  background-color: #F8F8F8;
}

.splide.news {
  display: inline-block;
  width: 100%;
}
.splide.news .splide__arrows .splide__arrow {
  border-radius: 0;
  opacity: 0.7;
  width: 80px;
  height: 80px;
  margin: 0 -16px;
  background: none;
  transition: opacity 0.3s ease;
}
.splide.news .splide__arrows .splide__arrow img {
  height: 32px;
  width: auto;
}
.splide.news .splide__arrows .splide__arrow:hover {
  opacity: 1;
}
.splide.news .splide__track {
  margin-left: 100px;
  margin-right: 100px;
}
.splide.news .splide__track .splide__list .splide__slide > a {
  display: flex;
  padding-top: 25px;
  text-decoration: none;
}
.splide.news .splide__track .splide__list .splide__slide > a > img {
  margin-right: 16px;
  max-height: 70px;
}
@media (max-width: 575px) {
  .splide.news .splide__track .splide__list .splide__slide > a > img {
    display: none;
  }
}
.splide.news .splide__track .splide__list .splide__slide > a > span {
  font-family: "Be Vietnam Pro", sans-serif;
  color: #02143A;
}

@media (min-width: 454px) and (max-width: 1498px) {
  .section_sectors .container {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
.section_sectors .row .col-6 {
  text-align: center;
  line-height: normal;
}
.section_sectors .row .col-6 > a {
  color: #03153b;
  padding-top: 25px;
  text-decoration: none;
}
.section_sectors .row .col-6 > a > img {
  /* margin-right: 16px; */
  max-height: 70px;
}
@media (max-width: 575px) {
  .section_sectors .row .col-6 > a > img {
    /*display: none;*/
  }
}
@media (max-width: 575px) {
  .section_sectors .row .col-6 > a .ff-bevietmanpro {
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .section_sectors .row .col-6 > a .ff-bevietmanpro300 {
    display: none;
  }
}
.section_sectors .row .shadow_ss {
  box-shadow: 0 !important;
  --bs-bg-opacity: 0;
  padding: 2rem !important;
  margin-bottom: 3rem !important;
  border-radius: 0 !important;
}
.section_sectors .row .shadow_ss:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  padding: 2rem !important;
  margin-bottom: 3rem !important;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
  border-radius: 0.25rem !important;
}

/*
.section_sectors {
  .row {
    .col-6 {
      .bot_sector {
        display: flex;
        align-items: center;
        text-decoration: none;
        background-color: #0F1E40;
        width: 100%;
        border-top: 1px solid #2D3F65;
        border-right: 1px solid #2D3F65;
        border-bottom: 0;
        border-left: 0;
        font-family: 'Be Vietnam Pro', sans-serif;
        height: 370px;
        color: #ffffff;
        justify-content: center;
        img {
          margin-right: 23px;
        }
        &:hover {
          background-color: #2D3F65;
        }
      }
    }
  }
}
*/
.section_labels {
  padding: 6.981vw 0 4.981vw 0;
}
.section_labels .label1 {
  font-family: "Be Vietnam Pro Bold", sans-serif;
  text-transform: uppercase;
  color: #03153B;
  font-size: 3.678vw;
}
.section_labels .label2 {
  font-family: "Be Vietnam Pro Medium", sans-serif;
  text-transform: uppercase;
  -webkit-text-stroke: 1px #03153B;
  -webkit-text-fill-color: #ffffff;
  text-align: right;
  font-size: 7.357vw;
}

#googleMap {
  display: block;
  width: 100%;
  height: 590px;
  transition: all 0.3s ease;
}
@media (max-width: 396px) {
  #googleMap {
    transition: all 0.3s ease;
    height: 356px;
  }
}
@media (max-width: 250px) {
  #googleMap {
    transition: all 0.3s ease;
    height: 180px;
  }
}
#googleMap iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@media (min-width: 454px) and (max-width: 1498px) {
  .section_maps > .container {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
.section_maps > .container > .row > .col h3 {
  font-family: "Be Vietnam Pro", sans-serif;
  color: #03153B;
  margin-bottom: 40px;
  align-self: start;
}
@media (max-width: 453px) {
  .section_maps > .container > .row > .col h3 {
    font-size: 20px;
  }
}
.section_maps > .container > .row .content {
  padding: 20px 40px;
}
.section_maps > .container > .row .content.text-align {
  padding: 50px;
  text-align: right;
}
@media (max-width: 767px) {
  .section_maps > .container > .row .content h6 {
    text-align: center;
  }
  .section_maps > .container > .row .content.text-align {
    padding: 50px;
    text-align: center;
  }
}
@media (max-width: 575px) {
  .section_maps > .container > .row .content h6 {
    text-align: center;
  }
  .section_maps > .container > .row .content.text-align {
    padding: 5px;
    text-align: center;
  }
}
@media (max-width: 453px) {
  .section_maps > .container > .row .content h6 {
    text-align: center;
  }
  .section_maps > .container > .row .content.text-align {
    padding: 15px;
    text-align: center;
  }
}

@media (min-width: 454px) and (max-width: 1774px) {
  .section_training .container-medium {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
.section_training .col-left {
  background-color: #ffffff;
  padding: 112px 122px 153px 122px;
}
.section_training .col-left h4 {
  font-family: "Be Vietnam Pro", sans-serif;
  color: #03153B;
  margin-bottom: 60px;
}
@media (max-width: 1485px) {
  .section_training .col-left {
    padding: 112px 50px 153px 50px;
  }
}
@media (max-width: 1290px) {
  .section_training .col-left .row div img {
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 1040px) {
  .section_training .col-left {
    padding: 112px 20px 153px 20px;
  }
}
@media (max-width: 991px) {
  .section_training .col-left {
    display: none;
  }
}
.section_training .col-right {
  background: rgb(0, 216, 174);
  background: linear-gradient(180deg, rgb(0, 216, 174) 0%, rgb(0, 27, 85) 100%);
  padding: 114px 122px 0 110px;
  height: 550px;
}
.section_training .col-right h3 {
  font-family: "Be Vietnam Pro", sans-serif;
  color: #ffffff;
  text-align: right;
}
.section_training .col-right h6 {
  font-family: "Be Vietnam Pro", sans-serif;
  color: #ffffff;
  text-align: right;
}
@media (max-width: 1091px) {
  .section_training .col-right {
    padding: 114px 40px 0 40px;
  }
}
@media (max-width: 575px) {
  .section_training .col-right {
    padding: 50px;
    height: auto;
  }
  .section_training .col-right h3 {
    font-size: 28px;
  }
}
@media (max-width: 991px) {
  .section_training .col-right {
    padding: 114px 122px 0 110px;
  }
}
@media (max-width: 575px) {
  .section_training .col-right {
    padding: 114px 50px 50px 50px;
  }
}
@media (max-width: 453px) {
  .section_training .col-right {
    padding: 30px 40px 30px;
  }
}
.section_training .col-mobile {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.14);
}

.section_clients.bb_1x_silver {
  padding-bottom: 15px;
  margin-bottom: 60px;
}

.section_clients {
  padding-bottom: 68px;
}
@media (min-width: 454px) and (max-width: 1498px) {
  .section_clients .container {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
@media (max-width: 991px) {
  .section_clients {
    text-align: center;
  }
}
@media (max-width: 575px) {
  .section_clients .splide.clients {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (max-width: 453px) {
  .section_clients .splide.clients {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.splide.clients {
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 80px;
}
@media (max-width: 530px) {
  .splide.clients .splide__arrows {
    display: none;
  }
}
.splide.clients .splide__arrows .splide__arrow {
  border-radius: 0;
  opacity: 0.7;
  width: 80px;
  height: 80px;
  background: none;
  transition: opacity 0.3s ease;
}
.splide.clients .splide__arrows .splide__arrow.splide__arrow--prev {
  left: 0;
}
.splide.clients .splide__arrows .splide__arrow.splide__arrow--next {
  right: 0;
}
.splide.clients .splide__arrows .splide__arrow img {
  height: 32px;
  width: auto;
}
.splide.clients .splide__arrows .splide__arrow:hover {
  opacity: 1;
}
.splide.clients .splide__track .splide__list .splide__slide a {
  opacity: 0.7;
  transition: opacity 0.3s ease;
}
.splide.clients .splide__track .splide__list .splide__slide a:hover {
  opacity: 1;
}
.splide.clients .splide__pagination li .splide__pagination__page {
  background: #ffffff;
  height: 21px;
  width: 21px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.splide.clients .splide__pagination li .splide__pagination__page::before {
  content: "";
  background: #FFFFFF;
  width: 19px;
  height: 19px;
  display: block;
  position: absolute;
  border-radius: 50%;
  margin-top: -9px;
  margin-left: 1px;
}
.splide.clients .splide__pagination li .splide__pagination__page::after {
  content: "";
  background: #032672;
  width: 3px;
  height: 3px;
  display: block;
  position: absolute;
  border-radius: 50%;
  margin-left: 9px;
  margin-top: -1px;
}
.splide.clients .splide__pagination li .splide__pagination__page.is-active {
  background: rgba(3, 38, 114, 0.4);
  transform: unset;
}
.splide.clients .splide__pagination li .splide__pagination__page:hover {
  background: rgba(3, 38, 114, 0.4);
  transform: unset;
}

.section_news_jumbo .container-medium > .row > .col > .overlay {
  background-color: rgba(3, 21, 59, 0.6);
  position: relative;
  height: 100%;
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .content {
  padding: 70px 120px;
}
@media (max-width: 575px) {
  .section_news_jumbo .container-medium > .row > .col > .overlay > .content {
    padding: 40px 60px;
  }
  .section_news_jumbo .container-medium > .row > .col > .overlay > .content.text-right {
    text-align: center;
  }
}
@media (max-width: 453px) {
  .section_news_jumbo .container-medium > .row > .col > .overlay > .content {
    padding: 30px 40px;
  }
  .section_news_jumbo .container-medium > .row > .col > .overlay > .content.text-right {
    text-align: center;
  }
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__arrows .splide__arrow {
  opacity: 1;
  border-radius: 0;
  width: 50px;
  height: 50px;
  margin: 0 -16px;
  background: #FFFFFF;
  transition: opacity 0.2s ease-in;
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__arrows .splide__arrow:hover {
  background: #FFFFFF;
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__arrows .splide__arrow.splide__arrow--prev {
  justify-content: start;
  padding-left: 15px;
  transition: all 0.2s ease-in;
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__arrows .splide__arrow.splide__arrow--prev:hover {
  padding-left: 0;
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__arrows .splide__arrow.splide__arrow--next {
  justify-content: flex-end;
  padding-right: 15px;
  transition: all 0.2s ease-in;
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__arrows .splide__arrow.splide__arrow--next:hover {
  padding-right: 0;
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__track {
  margin: 0 120px 1rem;
}
@media (max-width: 991px) {
  .section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__track {
    margin: 0 0 1rem 120px;
    padding-right: 120px !important;
  }
}
@media (max-width: 575px) {
  .section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__track {
    margin: 0 0 1rem 60px;
    padding-right: 60px !important;
  }
}
@media (max-width: 453px) {
  .section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__track {
    margin: 0 0 1rem 40px;
    padding-right: 40px !important;
  }
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__track > .splide__list > .splide__slide {
  background-color: #ffffff;
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__track > .splide__list > .splide__slide > a {
  text-decoration: none;
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__track > .splide__list > .splide__slide > a > .row > .col {
  background-color: #ffffff;
  padding: 55px 40px;
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__track > .splide__list > .splide__slide > a > .row > .col > .content {
  word-break: break-word;
}
@media (max-width: 400px) {
  .section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__track > .splide__list > .splide__slide > a > .row > .col > .content > h3 {
    line-height: 19px;
    font-size: 16px;
  }
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__track > .splide__list > .splide__slide > a > .row > .col > .lermais {
  position: absolute;
  bottom: 0;
  right: 12px;
  left: 0;
  padding-right: 20px;
  padding-bottom: 15px;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgb(255, 255, 255) 50%);
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__track > .splide__list > .splide__slide > a > .row > .col > .lermais .bullet {
  transition: all 0.3s ease-out;
  padding-left: 0;
  margin: 0 0 5px 10px;
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__track > .splide__list > .splide__slide > a:hover .row .col {
  background-color: #F8F8F8;
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__track > .splide__list > .splide__slide > a:hover .row .col > .lermais {
  background: rgb(248, 248, 248);
  background: linear-gradient(180deg, rgba(248, 248, 248, 0.7) 0%, rgb(248, 248, 248) 50%);
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__track > .splide__list > .splide__slide > a:hover .row .col > .lermais .bullet {
  transition: all 0.3s ease-out;
  margin-left: 25px;
  margin-right: -15px;
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__pagination {
  bottom: unset;
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__pagination li .splide__pagination__page {
  background: transparent;
  height: 21px;
  width: 21px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__pagination li .splide__pagination__page::before {
  content: "";
  background: transparent;
  width: 19px;
  height: 19px;
  display: block;
  position: absolute;
  border-radius: 50%;
  margin-top: -9px;
  margin-left: 1px;
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__pagination li .splide__pagination__page::after {
  content: "";
  background: #ffffff;
  width: 3px;
  height: 3px;
  display: block;
  position: absolute;
  border-radius: 50%;
  margin-left: 9px;
  margin-top: -2px;
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__pagination li .splide__pagination__page.is-active {
  background: rgba(255, 255, 255, 0.4);
  transform: unset;
}
.section_news_jumbo .container-medium > .row > .col > .overlay > .splide.news_jumbo > .splide__pagination li .splide__pagination__page:hover {
  background: rgba(255, 255, 255, 0.4);
  transform: unset;
}

@media (max-width: 575px) {
  .row {
    max-width: 100%;
  }
}

.bg-paralax {
  height: 100%;
  width: 100%;
  position: fixed;
  background-attachment: fixed;
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (max-width: 575px) {
  .bg-paralax {
    height: 100%;
    background-position: center center;
  }
}

.section_about_slider {
  background-color: rgba(3, 21, 59, 0.85);
}
@media (min-width: 454px) and (max-width: 1674px) {
  .section_about_slider .container-large {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
.section_about_slider.green {
  background-color: rgba(5, 58, 60, 0.8);
}
.section_about_slider .d-lg-flex {
  padding-right: 0;
}
.section_about_slider .button-main-slider.bg-blue {
  background-color: #1B9CF2;
}
@media (max-width: 991px) {
  .section_about_slider .button-main-slider {
    margin-bottom: 10px;
  }
}

.splide.about {
  margin-top: -70px;
}
.splide.about .splide__track .splide__list .splide__slide {
  min-height: 543px;
}
.splide.about .splide__track .splide__list .splide__slide .row {
  height: 100%;
}
.splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
}
.splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(1) h6, .splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(1) h4 {
  width: 100%;
}
@media (min-width: 575px) {
  .splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(1) h6, .splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(1) h4 {
    width: 567px;
  }
}
@media (min-width: 454px) and (max-width: 1300px) {
  .splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(1) {
    padding-left: 62px;
  }
}
@media (max-width: 1199px) {
  .splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(1) {
    align-items: start;
  }
  .splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(1) h4 {
    font-size: 18px;
  }
}
@media (max-width: 575px) {
  .splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(1) h4 {
    font-size: 16px;
  }
}
.splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(2) img {
  max-width: 930px;
  width: 100%;
}
.splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(2) .content {
  line-height: 0;
}
.splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(2) .content .counters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.2vw;
  font-size: 6vw;
}
.splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(2) .content .counters:last-of-type {
  margin-bottom: 0;
}
.splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(2) .content .counters .c-number, .splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(2) .content .counters .c-number-t {
  display: inline-block;
  font-family: "IBM Plex Sans Thin", sans-serif;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 62px;
}
.splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(2) .content .counters .c-text {
  font-family: "Be Vietnam Pro", sans-serif;
  text-transform: uppercase;
  color: #1B9CF2;
}
.splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(3) > .container-large > .row > div {
  padding-right: 15px;
  padding-left: 0;
}
.splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(3) > .container-large > .row > div:last-of-type {
  padding-right: 0;
}
@media (max-width: 991px) {
  .splide.about .splide__track .splide__list .splide__slide .row > div:nth-of-type(3) > .container-large > .row > div {
    padding-right: 0;
  }
}
.splide.about .splide__track .splide__list .splide__slide .hr-white {
  background: #ffffff;
  height: 88px;
  margin-top: -87px;
}
@media (max-width: 767px) {
  .splide.about .splide__track .splide__list .splide__slide .hr-white {
    margin-left: 0;
    margin-right: 0;
  }
}

.sticky {
  position: relative;
  margin-top: -24px;
  z-index: 2;
  width: auto;
}
.sticky.container {
  padding-right: var(--bs-gutter-x, 62px) !important;
  padding-left: var(--bs-gutter-x, 62px) !important;
}
@media (max-width: 575px) {
  .sticky {
    display: none;
  }
}
.sticky a {
  font-family: "Be Vietnam Pro Bold", sans-serif;
  font-size: calc(10px + 0.21vw);
  color: #03153B;
  padding: 1.682vw 2.102vw;
  border: 1px solid #1B9CF2;
  text-transform: uppercase;
  text-decoration: none;
  background: linear-gradient(to top, #1B9CF2 50%, #fff 50%);
  background-size: 100% 200%;
  background-position: top;
  transition: background-position 0.3s ease-out;
}
.sticky a:hover {
  background-position: bottom;
  color: #FFFFFF;
}
.sticky.fixed {
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: #102144;
  margin-top: 0;
  transition: height 0.3s ease;
}
@media (max-width: 575px) {
  .sticky.fixed {
    display: block;
  }
}

.section_highlights_slider {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  background-color: #ffffff;
}

.splide.highlights {
  width: 100%;
  max-width: 960px;
}
.splide.highlights .splide__track {
  padding-right: 136px;
}
.splide.highlights .splide__track .splide__list .splide__slide {
  overflow: hidden;
}
@media (min-width: 454px) and (max-width: 1026px) {
  .splide.highlights .splide__track .splide__list .splide__slide > .row > .col {
    padding-left: 62px;
    padding-right: 62px;
  }
}
.splide.highlights .splide__pagination {
  top: unset;
  right: 2em;
}
.splide.highlights .splide__pagination li {
  line-height: 3;
}
.splide.highlights .splide__pagination li .splide__pagination__page {
  background: #ffffff;
  height: 21px;
  width: 21px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.splide.highlights .splide__pagination li .splide__pagination__page::before {
  content: "";
  background: #FFFFFF;
  width: 19px;
  height: 19px;
  display: block;
  position: absolute;
  border-radius: 50%;
  margin-top: -9px;
  margin-left: 1px;
}
.splide.highlights .splide__pagination li .splide__pagination__page::after {
  content: "";
  background: #032672;
  width: 3px;
  height: 3px;
  display: block;
  position: absolute;
  border-radius: 50%;
  margin-left: 9px;
  margin-top: -1px;
}
.splide.highlights .splide__pagination li .splide__pagination__page.is-active {
  background: rgba(3, 38, 114, 0.4);
  transform: unset;
}
.splide.highlights .splide__pagination li .splide__pagination__page:hover {
  background: rgba(3, 38, 114, 0.4);
  transform: unset;
}

.section_objectives {
  background-color: #ffffff;
}
.section_objectives .container {
  background-position: center center;
  background-repeat: no-repeat;
  background-blend-mode: luminosity;
  background-color: #0A1734;
}
.section_objectives .container .row {
  height: 100%;
  background-color: rgba(10, 23, 52, 0.85);
  margin: 0;
}
.section_objectives .container .row .col {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 575px) {
  .section_objectives .container .row .col h1 {
    font-size: 40px;
  }
}
.section_objectives .container .row .col .tabs {
  height: 100%;
  width: 924px;
}
@media (min-width: 454px) and (max-width: 1026px) {
  .section_objectives .container .row .col .tabs {
    padding-left: 62px;
    padding-right: 62px;
  }
}
@media (max-width: 991px) {
  .section_objectives .container .row .col .tabs {
    width: 100%;
  }
}
.section_objectives .container .row .col .tabs .nav .nav-item {
  font-family: "Be Vietnam Pro Bold", sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: left;
}
.section_objectives .container .row .col .tabs .nav .nav-item .nav-link {
  padding: 15px 12px;
  color: rgba(255, 255, 255, 0.4);
  border-radius: 0;
}
.section_objectives .container .row .col .tabs .nav .nav-item .nav-link:hover {
  color: rgb(255, 255, 255);
}
.section_objectives .container .row .col .tabs .nav .nav-item .nav-link.active {
  color: rgb(255, 255, 255);
  background: unset;
}
.section_objectives .container .row .col .tabs .nav .nav-item .nav-link.active + .slider {
  width: 100%;
}
.section_objectives .container .row .col .tabs .nav .nav-item .pre-slider {
  display: block;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.15);
  width: 100%;
}
.section_objectives .container .row .col .tabs .nav .nav-item .slider {
  display: block;
  height: 1px;
  background-color: #ffffff;
  width: 0;
  transition: width 0.3s ease-out;
}
.section_objectives .container .row .col .tabs .nav .nav-item:hover .slider {
  width: 100%;
}
.section_objectives .container .row .col .tabs .tab-content {
  margin-top: 86px;
}
.section_objectives .container .row .col .tabs .tab-content .tab-pane {
  font-family: "Be Vietnam Pro Light", sans-serif;
  font-size: 18px;
  color: #ffffff;
  line-height: 28px;
}

.section_structure {
  background-color: #ffffff;
}
@media (min-width: 454px) and (max-width: 1498px) {
  .section_structure .container {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
.section_structure .container .row.title {
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 70px;
  background-color: #F5F5F5;
  align-items: center;
}
.section_structure .container .row.title p {
  margin-bottom: 0;
}
.section_structure .container .row.cards {
  font-family: "Be Vietnam Pro Light", sans-serif;
  font-size: 14px;
  color: #03153B;
  margin: 0 -12px;
}
@media (max-width: 575px) {
  .section_structure .container .row.cards {
    height: 550px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
  }
  .section_structure .container .row.cards:first-of-type {
    padding-left: 0;
  }
  .section_structure .container .row.cards:last-of-type {
    padding-right: 0;
  }
}
@media (max-width: 400px) {
  .section_structure .container .row.cards {
    flex-direction: column;
    margin: 0;
  }
}
.section_structure .container .row.cards .card {
  margin-top: 30px;
  padding: 10px 10px 20px 36px;
  background-color: #F5F5F5;
  border: 0;
  border-radius: 0;
}
.section_structure .container .row.cards .card .card-body {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  color: #032672;
}
.section_structure .container .row.cards .card .card-body .flag {
  text-align: right;
  display: inline;
  margin-bottom: 8px;
}
.section_structure .container .row.cards .card .card-body .flag > img {
  height: 30px;
  width: 30px;
}
.section_structure .container .row.cards .card .card-body a {
  white-space: pre-line;
  text-decoration: none;
  color: #1b9cf2;
}
.section_structure .container .row.cards .card .card-body a:hover {
  text-decoration: underline;
}
.section_structure .container .row.cards .card .card-body span {
  padding: 13px 0;
}
.section_structure .container .row.info div:nth-of-type(2) {
  text-align: right;
}

.section_testimonial {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 550px;
  color: #ffffff;
  font-family: "Be Vietnam Pro", sans-serif;
  line-height: 28px;
}
.section_testimonial > .main {
  max-width: 1080px;
}
.section_testimonial > .main > .text > .quotes-end {
  float: right;
  margin-top: 140px;
}
@media (max-width: 991px) {
  .section_testimonial > .main > .text > .quotes-end {
    margin-right: 30px;
  }
}
@media (max-width: 400px) {
  .section_testimonial > .main > .text > .quotes-end {
    margin-right: 20px;
  }
}
.section_testimonial > .main > .text > .sentence {
  max-width: 926px;
  display: inline-block;
  margin-left: 20px;
}
.section_testimonial > .main > .name {
  margin-top: 40px;
  margin-left: 11rem;
  line-height: 14px;
}
@media (max-width: 400px) {
  .section_testimonial > .main > .name {
    margin-left: 8rem;
  }
}
.section_testimonial > .main > .job {
  margin-left: 11rem;
}
@media (max-width: 400px) {
  .section_testimonial > .main > .job {
    margin-left: 8rem;
  }
}

.section_history {
  background-color: #ffffff;
}
.section_history .container {
  padding-top: 200px;
  padding-bottom: 240px;
}
@media (min-width: 454px) and (max-width: 1498px) {
  .section_history .container {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
.section_history .container .row.title .col h1 {
  font-family: "Be Vietnam Pro Bold", sans-serif;
  font-size: 40px;
  color: #03153B;
  width: 100%;
  text-align: right;
}
.section_history .container .row.cards .col {
  overflow-x: auto;
  white-space: nowrap;
  height: 446px;
}
.section_history .container .row.cards .col .card {
  width: 195px;
  border: 0;
  border-radius: 0;
  display: inline-flex;
  float: none;
  margin-right: 45px;
}
.section_history .container .row.cards .col .card:last-of-type {
  margin-right: 0;
}
.section_history .container .row.cards .col .card .year {
  font-family: "Be Vietnam Pro Bold", sans-serif;
  font-size: 60px;
  line-height: 60px;
  font-style: italic;
  color: #F5F5F5;
}
.section_history .container .row.cards .col .card .img {
  height: 150px;
  background-size: 195px 150px;
  background-repeat: no-repeat;
  background-position: top left;
}
.section_history .container .row.cards .col .card .card-body {
  padding: 1rem 0;
  white-space: normal;
}

.section_testimonial {
  background-color: rgba(3, 21, 59, 0.85);
}

.splide.internal > .splide__track {
  padding-top: 8px !important;
}

@media (min-width: 454px) and (max-width: 1498px) {
  .section_board .container {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
.section_board .container .row .col h6 {
  margin-top: 40px;
}

@media (min-width: 454px) and (max-width: 1498px) {
  .section_highlight_block .container {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
.section_highlight_block .container .row .col {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
}
.section_highlight_block .container .row .col .content {
  max-width: 960px;
  word-break: break-word;
}
@media (max-width: 575px) {
  .section_highlight_block {
    padding-top: 20px;
    padding-bottom: 100px;
  }
}

.section_feature_block {
  height: 400px;
  display: flex;
  align-items: center;
}
@media (max-width: 575px) {
  .section_feature_block {
    background-position-x: 75%;
  }
  .section_feature_block h4 {
    font-size: 22px;
  }
}
.section_feature_block .container .row .col .content a {
  color: #ffffff;
  font-family: "Be Vietnam Pro Bold", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid #ffffff;
  padding: 26px 22px;
}

.section_points_block {
  background-position: center center;
  background-repeat: no-repeat;
  background-blend-mode: luminosity;
}
.section_points_block .row {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
}
.section_points_block .row .col-right .tabs {
  width: 924px;
}
@media (max-width: 575px) {
  .section_points_block .row .col-right .tabs {
    width: 100%;
  }
}
.section_points_block .row .col-right .tabs .nav .nav-item {
  font-family: "Be Vietnam Pro Bold", sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: left;
}
.section_points_block .row .col-right .tabs .nav .nav-item .nav-link {
  padding: 15px 12px;
  color: #CBCBCB;
  border-radius: 0;
}
.section_points_block .row .col-right .tabs .nav .nav-item .nav-link:hover {
  color: #1B9CF2;
}
.section_points_block .row .col-right .tabs .nav .nav-item .nav-link.active {
  color: #1B9CF2;
  background: unset;
}
.section_points_block .row .col-right .tabs .nav .nav-item .nav-link.active + .slider {
  width: 100%;
}
.section_points_block .row .col-right .tabs .nav .nav-item .pre-slider {
  display: block;
  height: 1px;
  background-color: #E6E6E6;
  width: 100%;
}
.section_points_block .row .col-right .tabs .nav .nav-item .slider {
  display: block;
  height: 1px;
  background-color: #1B9CF2;
  width: 0;
  transition: width 0.3s ease-out;
}
.section_points_block .row .col-right .tabs .nav .nav-item:hover .slider {
  width: 100%;
}
.section_points_block .row .col-right .tabs .tab-content .tab-pane {
  font-family: "Be Vietnam Pro Light", sans-serif;
  font-size: 16px;
  color: #03153B;
  line-height: 26px;
}

.section_detail_grid_block {
  background-color: #ffffff;
}
.section_detail_grid_block div .row .col-content .content {
  width: 100%;
}
@media (max-width: 1399px) {
  .section_detail_grid_block div .row .col-content.pl-170px {
    padding-left: 100px;
  }
  .section_detail_grid_block div .row .col-content.pr-170px {
    padding-right: 100px;
  }
  .section_detail_grid_block div .row .col-content .content {
    padding-top: 30px;
  }
}
@media (max-width: 991px) {
  .section_detail_grid_block div .row .col-content.pl-170px {
    padding-left: 20px;
  }
  .section_detail_grid_block div .row .col-content.pr-170px {
    padding-right: 20px;
  }
}
@media (max-width: 575px) {
  .section_detail_grid_block div .row .col-content {
    max-width: 100%;
  }
}
@media (max-width: 991px) {
  .section_detail_grid_block div {
    justify-content: flex-start !important;
    padding-top: 0px;
  }
  .section_detail_grid_block div .col-img {
    order: 1;
  }
  .section_detail_grid_block div .col-content {
    order: 2;
    padding-bottom: 100px;
  }
}
.section_detail_grid_block .accordion {
  width: 560px;
  max-width: 100%;
}
.section_detail_grid_block .accordion .accordion-button {
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 22px;
  color: #03153B;
  padding: 1rem 30px 1rem 0;
}
.section_detail_grid_block .accordion .accordion-button.collapsed::after {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-bottom: 3px;
}
.section_detail_grid_block .accordion .accordion-button:not(.collapsed) {
  background-color: transparent;
}
.section_detail_grid_block .accordion .accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}
.section_detail_grid_block .accordion .accordion-button::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #03153B;
  position: absolute;
  right: 0;
}
.section_detail_grid_block .accordion .accordion-button::after {
  background: none;
  border: solid #03153B;
  border-width: 0 2px 2px 0;
  display: inline-block;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  width: 8px;
  height: 8px;
  margin-left: unset;
  position: absolute;
  right: 8px;
  margin-bottom: -4px;
}
@media (max-width: 1199px) {
  .section_detail_grid_block .accordion {
    width: 460px;
  }
}
@media (max-width: 991px) {
  .section_detail_grid_block {
    padding-top: 20px;
  }
  .section_detail_grid_block .container-medium .pl-170px, .section_detail_grid_block .container-medium .pr-170px {
    padding-left: 20px;
    padding-right: 20px;
  }
  .section_detail_grid_block .container-medium .pl-170px .content, .section_detail_grid_block .container-medium .pr-170px .content {
    max-width: 100%;
  }
}

.section_partners_block {
  background-color: #ffffff;
}
@media (min-width: 454px) and (max-width: 1498px) {
  .section_partners_block .container {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
@media (max-width: 575px) {
  .section_partners_block .container .row .row .col-4 img {
    width: 100%;
    max-width: 206px;
  }
}
@media (max-width: 1199px) {
  .section_partners_block {
    padding-top: 100px;
  }
}
@media (max-width: 767px) {
  .section_partners_block {
    padding-top: 60px;
  }
}
@media (max-width: 575px) {
  .section_partners_block {
    padding-top: 20px;
  }
}

.section_contacts_slider {
  background-color: #ffffff;
}
@media (min-width: 454px) and (max-width: 991px) {
  .section_contacts_slider {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
.section_contacts_slider figure {
  max-width: 50%;
}
.section_contacts_slider .d-xl-flex {
  padding-right: 0;
}

.splide.contacts .splide__track .splide__list .splide__slide {
  min-height: 543px;
}
.splide.contacts .splide__track .splide__list .splide__slide > .row {
  height: 100%;
}
.splide.contacts .splide__track .splide__list .splide__slide > .row .col-2, .splide.contacts .splide__track .splide__list .splide__slide > .row .col-3 {
  min-height: 320px;
  flex-direction: column;
  align-self: center;
}
.splide.contacts .splide__track .splide__list .splide__slide > .row > .col-6:nth-of-type(2) img {
  max-width: 930px;
}

.section_contact_form {
  padding-top: 118px;
  padding-bottom: 140px;
}
@media (min-width: 454px) and (max-width: 1250px) {
  .section_contact_form .container-small {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
@media (min-width: 454px) and (max-width: 1026px) {
  .section_contact_form .container-xsmall {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
@media (max-width: 575px) {
  .section_contact_form {
    padding-top: 0;
    padding-bottom: 90px;
  }
}
.section_contact_form form label {
  font-family: "Be Vietnam Pro Light", sans-serif;
  font-size: 14px;
  color: #03153B;
}
.section_contact_form form input, .section_contact_form form textarea, .section_contact_form form .form-check-input[type=checkbox] {
  border-radius: 0;
  border: 1px solid #EBEBEB;
}
.section_contact_form form button[type=submit] {
  font-family: "Be Vietnam Pro Bold", sans-serif;
  font-size: 14px;
  color: #03153B;
  padding: 16px 90px;
  border: 2px solid #1B9CF2;
  text-transform: uppercase;
  text-decoration: none;
  background: linear-gradient(to top, #1B9CF2 50%, #fff 50%);
  background-size: 100% 200%;
  background-position: top;
  transition: background-position 0.3s ease-out;
}
.section_contact_form form button[type=submit]:hover {
  background-position: bottom;
  color: #FFFFFF;
}

@media (min-width: 454px) and (max-width: 1498px) {
  .section_news_grid_block .container {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
.section_news_grid_block .row a {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-decoration: none;
}
.section_news_grid_block .row a:hover {
  background-color: #F8F8F8;
}
.section_news_grid_block .row a:hover .content .bullet {
  transition: all 0.3s ease-out;
  margin-left: 25px;
  margin-right: -15px;
}
.section_news_grid_block .row a > img {
  min-height: 246px;
  object-fit: cover;
}
.section_news_grid_block .row a .content {
  padding: 30px;
}
.section_news_grid_block .row a .content div:nth-of-type(3) {
  height: 340px;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
}
.section_news_grid_block .row a .content .bullet {
  margin-left: 10px;
  transition: all 0.3s ease-out;
  padding-left: 0;
  margin-right: 0;
}
@media (max-width: 991px) {
  .section_news_grid_block .row {
    max-width: 100%;
    margin: 0;
  }
}

.section_news_detail_slider {
  background-color: #03153B;
  height: 473px;
}
@media (min-width: 454px) and (max-width: 1026px) {
  .section_news_detail_slider .container-xsmall {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}

.splide.news.detail {
  display: block;
  max-width: 750px;
}
.splide.news.detail .splide__arrows .splide__arrow {
  opacity: 1;
  border-radius: 0;
  width: 50px;
  height: 50px;
  margin: 0 -16px;
  background: #F5F8F8;
  transition: opacity 0.2s ease-in;
}
.splide.news.detail .splide__arrows .splide__arrow:hover {
  background: #FFFFFF;
}
.splide.news.detail .splide__arrows .splide__arrow.splide__arrow--prev {
  justify-content: start;
  padding-left: 15px;
  transition: all 0.2s ease-in;
}
.splide.news.detail .splide__arrows .splide__arrow.splide__arrow--prev:hover {
  padding-left: 0;
}
.splide.news.detail .splide__arrows .splide__arrow.splide__arrow--next {
  justify-content: flex-end;
  padding-right: 15px;
  transition: all 0.2s ease-in;
}
.splide.news.detail .splide__arrows .splide__arrow.splide__arrow--next:hover {
  padding-right: 0;
}
.splide.news.detail .splide__pagination {
  bottom: -30px;
}
.splide.news.detail .splide__pagination .splide__pagination__page {
  background: #ffffff;
  width: 10px;
  height: 10px;
  border: 1px solid #0A1734;
  opacity: 1;
}
.splide.news.detail .splide__pagination .splide__pagination__page.is-active {
  background: #0A1734;
  transform: unset;
  width: 10px;
  height: 10px;
  border: 1px solid #0A1734;
}
.splide.news.detail .splide__pagination .splide__pagination__page:hover {
  opacity: 1;
}
.splide.news.detail .splide__track {
  margin-left: 0;
  margin-right: 0;
}
.splide.news.detail .splide__track .splide__list .splide__slide {
  height: auto;
  padding-top: 0;
}
.splide.news.detail .splide__track .splide__list .splide__slide .row {
  height: 100%;
  width: 100%;
}
.splide.news.detail .splide__track .splide__list .splide__slide .row img {
  width: 100%;
  height: auto;
}

.news_prev {
  color: #CBCBCB;
  text-decoration: none;
}
.news_prev .bullet, .news_prev svg {
  margin-right: 10px;
  margin-left: 0;
  transition: all 0.3s ease-out;
}
.news_prev .bullet g, .news_prev svg g {
  stroke: #CBCBCB;
}
.news_prev:hover {
  color: #03153B;
}
.news_prev:hover .bullet, .news_prev:hover svg {
  margin-right: 20px;
  margin-left: -10px;
}
.news_prev:hover .bullet g, .news_prev:hover svg g {
  stroke: #03143b;
}

.news_next {
  color: #CBCBCB;
  text-decoration: none;
}
.news_next .bullet, .news_next svg {
  margin-left: 10px;
  transition: all 0.3s ease-out;
}
.news_next .bullet g, .news_next svg g {
  stroke: #CBCBCB;
}
.news_next:hover {
  color: #03153B;
}
.news_next:hover .bullet, .news_next:hover svg {
  margin-left: 25px;
  margin-right: -15px;
}
.news_next:hover .bullet g, .news_next:hover svg g {
  stroke: #03143b;
}

.bullet {
  transition: all 0.3s ease-out;
  padding-left: 0;
}
.bullet.invert {
  transform: rotate(180deg);
}

.section_news_detail_block .container-xsmall {
  max-width: 750px;
}
@media (min-width: 454px) and (max-width: 1026px) {
  .section_news_detail_block .container-xsmall {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
@media (max-width: 575px) {
  .section_news_detail_block {
    padding-top: 20px;
  }
  .section_news_detail_block .row {
    margin-left: 0;
  }
  .section_news_detail_block .row .col .fs-30px {
    font-size: 22px;
  }
  .section_news_detail_block .row .col .fs-16px {
    font-size: 14px;
  }
}

.section_group_block {
  margin-bottom: -310px;
}
@media (max-width: 575px) {
  .section_group_block {
    margin-bottom: -100px;
  }
}

@media (min-width: 454px) and (max-width: 1498px) {
  .section_principles_block .container {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}

@media (min-width: 454px) and (max-width: 1498px) {
  .section_commitment_block .container {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}

.splide.commitment .splide__pagination, .splide.principles .splide__pagination, .splide.structure .splide__pagination, .splide.history .splide__pagination {
  bottom: unset;
  left: unset;
  padding: unset;
  position: unset;
  right: unset;
  z-index: 1;
}
.splide.commitment .splide__pagination .splide__pagination__page, .splide.principles .splide__pagination .splide__pagination__page, .splide.structure .splide__pagination .splide__pagination__page, .splide.history .splide__pagination .splide__pagination__page {
  background: #ffffff;
  height: 21px;
  width: 21px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.splide.commitment .splide__pagination .splide__pagination__page::before, .splide.principles .splide__pagination .splide__pagination__page::before, .splide.structure .splide__pagination .splide__pagination__page::before, .splide.history .splide__pagination .splide__pagination__page::before {
  content: "";
  background: #FFFFFF;
  width: 19px;
  height: 19px;
  display: block;
  position: absolute;
  border-radius: 50%;
  margin-top: -9px;
  margin-left: 1px;
}
.splide.commitment .splide__pagination .splide__pagination__page::after, .splide.principles .splide__pagination .splide__pagination__page::after, .splide.structure .splide__pagination .splide__pagination__page::after, .splide.history .splide__pagination .splide__pagination__page::after {
  content: "";
  background: #032672;
  width: 3px;
  height: 3px;
  display: block;
  position: absolute;
  border-radius: 50%;
  margin-left: 9px;
  margin-top: -1px;
}
.splide.commitment .splide__pagination .splide__pagination__page.is-active, .splide.principles .splide__pagination .splide__pagination__page.is-active, .splide.structure .splide__pagination .splide__pagination__page.is-active, .splide.history .splide__pagination .splide__pagination__page.is-active {
  background: rgba(3, 38, 114, 0.4);
  transform: unset;
}
.splide.commitment .splide__pagination .splide__pagination__page:hover, .splide.principles .splide__pagination .splide__pagination__page:hover, .splide.structure .splide__pagination .splide__pagination__page:hover, .splide.history .splide__pagination .splide__pagination__page:hover {
  background: rgba(3, 38, 114, 0.4);
  transform: unset;
}

.section_academy_slider {
  background-color: #03153B;
  height: 473px;
}
@media (min-width: 454px) and (max-width: 1526px) {
  .section_academy_slider .container {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
.section_academy_slider .track {
  margin-left: 0;
  margin-right: 0;
}
.section_academy_slider .track .list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.section_academy_slider .track .list .slide {
  height: auto;
  padding-top: 0;
}
.section_academy_slider .track .list .slide .row {
  height: 100%;
  width: 100%;
}
.section_academy_slider .track .list .slide .row img {
  width: 100%;
  height: auto;
}

.section_academy_steps_block {
  height: 700px;
  background-position: center 38%;
}
@media (max-width: 575px) {
  .section_academy_steps_block {
    height: 430px;
  }
}
.section_academy_steps_block .overlay {
  position: absolute;
  background-color: rgba(3, 21, 59, 0.9);
  height: 700px;
  width: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 575px) {
  .section_academy_steps_block .overlay {
    height: 430px;
  }
}
@media (max-width: 575px) {
  .section_academy_steps_block .img {
    height: 40px;
  }
}
.section_academy_steps_block .splide.steps .splide__track .splide__list .splide__slide .row .col {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  word-break: break-word;
}
.section_academy_steps_block .splide.steps .splide__track .splide__list .splide__slide .row .col span {
  max-width: 80%;
}
.section_academy_steps_block .splide.steps .splide__pagination {
  bottom: -30px;
}
.section_academy_steps_block .splide.steps .splide__pagination .splide__pagination__page {
  background: transparent;
  width: 10px;
  height: 10px;
  border: 1px solid #ffffff;
  opacity: 1;
}
.section_academy_steps_block .splide.steps .splide__pagination .splide__pagination__page.is-active {
  background: #ffffff;
  transform: unset;
  width: 10px;
  height: 10px;
  border: 1px solid #ffffff;
}
.section_academy_steps_block .splide.steps .splide__pagination .splide__pagination__page:hover {
  opacity: 1;
}

@media (min-width: 454px) and (max-width: 1748px) {
  .section_detail_grid_block .container-medium {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
@media (max-width: 575px) {
  .section_detail_grid_block div .row {
    height: 100%;
  }
}

@media (min-width: 454px) and (max-width: 1026px) {
  .section_academy_detail_block .container-xsmall {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}

.section_job_offers_table {
  background-color: #ffffff;
}
@media (min-width: 454px) and (max-width: 1674px) {
  .section_job_offers_table .container-large {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
.section_job_offers_table .row {
  border-bottom: 1px solid #ECECEC;
}

/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
/* ++++++++++++++++++++++++++++ */
.section_ldg_highlight_block_results {
  background-color: #ffffff;
  color: #031d56;
  font-family: "Be Vietnam Pro", Helvetica, Arial, serif;
  letter-spacing: 0;
}
@media (min-width: 454px) and (max-width: 1498px) {
  .section_ldg_highlight_block_results .container {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
.section_ldg_highlight_block_results .container .row {
  /* SECTION 2 */
}
.section_ldg_highlight_block_results .container .row.title {
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 70px;
  background-color: #F5F5F5;
  align-items: center;
}
.section_ldg_highlight_block_results .container .row.title p {
  margin-bottom: 0;
}
.section_ldg_highlight_block_results .container .row .section2-flex {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.section_ldg_highlight_block_results .container .row .section2-flex.layout {
  position: relative;
  overflow: hidden;
}
.section_ldg_highlight_block_results .container .row .section2-flex1 {
  display: flex;
  flex-direction: column;
}
.section_ldg_highlight_block_results .container .row .section2-flex1.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 75%;
  margin: 49px auto 0px;
}
.section_ldg_highlight_block_results .container .row .section2-flex2 {
  display: flex;
  flex-direction: column;
}
.section_ldg_highlight_block_results .container .row .section2-flex2.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  width: 100%;
  margin: 0px 0px;
}
.section_ldg_highlight_block_results .container .row .section2-txt-box {
  display: flex;
  justify-content: center;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_results .container .row .section2-txt-box {
    align-items: flex-start;
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_results .container .row .section2-txt-box {
    align-items: flex-start;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_results .container .row .section2-txt-box {
    align-items: flex-start;
    justify-content: center;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_results .container .row .section2-txt-box {
    align-items: flex-start;
    justify-content: center;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_results .container .row .section2-txt-box {
    align-items: flex-start;
    justify-content: center;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_results .container .row .section2-txt-box {
    align-items: flex-start;
    justify-content: center;
  }
}
.section_ldg_highlight_block_results .container .row .section2-txt-box.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 100%;
  margin: 48px 0px 0px;
}
.section_ldg_highlight_block_results .container .row .section2-txt {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  font: 700 36px/0.83 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: #031e56;
  text-align: center;
  letter-spacing: 0px;
  white-space: pre-wrap;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_results .container .row .section2-txt {
    font-size: 32px;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_results .container .row .section2-txt {
    font-size: 29px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_results .container .row .section2-txt {
    font-size: 25px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_results .container .row .section2-txt {
    font-size: 24px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_results .container .row .section2-txt {
    font-size: 22px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_results .container .row .section2-txt {
    font-size: 21px;
  }
}
.section_ldg_highlight_block_results .container .row .section2-txt1 {
  display: flex;
  justify-content: center;
  font: 16px/1.62 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: #031d56;
  text-align: center;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_results .container .row .section2-txt1 {
    font-size: 15px;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_results .container .row .section2-txt1 {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_results .container .row .section2-txt1 {
    font-size: 13px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_results .container .row .section2-txt1 {
    font-size: 12px;
  }
}
.section_ldg_highlight_block_results .container .row .section2-txt1.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 100%;
  margin: 47px 0px 0px;
}
.section_ldg_highlight_block_results .container .row .section2-flex3 {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_results .container .row .section2-flex3 {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
.section_ldg_highlight_block_results .container .row .section2-flex3.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 95%;
  margin: 68px auto 112px;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_results .container .row .section2-flex3.layout {
    width: 80%;
  }
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_results .container .row .section2-flex3.layout {
    width: 80%;
  }
}
.section_ldg_highlight_block_results .container .row .section2-flex3-item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 300px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .section_ldg_highlight_block_results .container .row .section2-flex3-item {
    flex: 0 0 382px;
  }
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_results .container .row .section2-flex3-item {
    flex: 0 0 49.99%;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_results .container .row .section2-flex3-item {
    flex: 0 0 49.99%;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_results .container .row .section2-flex3-item {
    flex: 0 0 350px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_results .container .row .section2-flex3-item {
    flex: 0 0 100%;
  }
}
.section_ldg_highlight_block_results .container .row .section2-cover-block {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid white;
}
.section_ldg_highlight_block_results .container .row .section2-cover-block.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
.section_ldg_highlight_block_results .container .row .section2-txt2 {
  display: flex;
  align-items: flex-end;
  font: 800 45px/0.72 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: #031d56;
  letter-spacing: 0px;
}
@media (max-width: 1919px) {
  .section_ldg_highlight_block_results .container .row .section2-txt2 {
    text-align: left;
  }
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_results .container .row .section2-txt2 {
    font-size: 44px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_results .container .row .section2-txt2 {
    font-size: 38px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_results .container .row .section2-txt2 {
    font-size: 38px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_results .container .row .section2-txt2 {
    font-size: 38px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_results .container .row .section2-txt2 {
    font-size: 38px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_results .container .row .section2-txt2 {
    font-size: 36px;
  }
}
.section_ldg_highlight_block_results .container .row .section2-txt2.layout {
  position: absolute;
  top: 41px;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 100%;
  margin: 0px auto;
}
@media (max-width: 1919px) {
  .section_ldg_highlight_block_results .container .row .section2-txt3-box {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_results .container .row .section2-txt3-box {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_results .container .row .section2-txt3-box {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_results .container .row .section2-txt3-box {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_results .container .row .section2-txt3-box {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.section_ldg_highlight_block_results .container .row .section2-txt3-box.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 100%; /*70.33%;*/
  margin: 106px auto 40px;
}
.section_ldg_highlight_block_results .container .row .section2-txt3 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  font: 16px/1.3 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: #1b9cf2;
  letter-spacing: 0px;
  white-space: pre-wrap;
}
@media (max-width: 1919px) {
  .section_ldg_highlight_block_results .container .row .section2-txt3 {
    text-align: left;
  }
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_results .container .row .section2-txt3 {
    font-size: 15px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_results .container .row .section2-txt3 {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_results .container .row .section2-txt3 {
    font-size: 14px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_results .container .row .section2-txt3 {
    font-size: 14px;
  }
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_results .container .row .section2-txt2, .section_ldg_highlight_block_results .container .row .section2-txt3 {
    text-align: center;
    justify-content: center;
  }
}
.section_ldg_highlight_block_results .container .row .section2-img {
  background: var(--src) center center/contain no-repeat;
}
.section_ldg_highlight_block_results .container .row .section2-img.layout {
  position: absolute;
  top: 0px;
  height: 133px;
  width: 1px;
  min-width: 15px;
  right: 0px;
  margin: 41px 0px 38px;
}
.section_ldg_highlight_block_results .container .row .section2-img.layout.last {
  display: none;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_results .container .row .section2-img.layout.last {
    display: block;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_results .container .row .section2-img.layout.last {
    display: none;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_results .container .row .section2-img.layout {
    display: none;
  }
}
.section_ldg_highlight_block_results .container .row .section2-flex3-spacer {
  flex: 0 0 auto;
  min-width: 1px;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_results .container .row .section2-flex3-spacer {
    display: none;
  }
}

.section_ldg_highlight_block_turnover_ebitda {
  min-height: 700px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #031d56;
  font-family: "Be Vietnam Pro", Helvetica, Arial, serif;
  letter-spacing: 0;
  /* SECTION 3 */
}
.section_ldg_highlight_block_turnover_ebitda .overlay {
  position: relative;
  background-color: rgba(3, 21, 59, 0.9);
  min-height: 700px;
  width: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_turnover_ebitda .img {
    height: 40px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-block {
  display: flex;
  flex-direction: column;
  background: var(--src) center center/cover no-repeat;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-block.layout {
  position: relative;
  overflow: hidden;
  min-height: 701px;
  flex-shrink: 0;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex {
  display: flex;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 66.67%;
  margin: 102px auto;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex.layout {
    width: 58.77%;
    margin: 84px auto;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex.layout {
    width: 65.53%;
    margin: 65px auto;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex.layout {
    width: 71.71%;
    margin: 49px auto;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex.layout {
    width: 77.17%;
    margin: 40px auto;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex.layout {
    width: 81.84%;
    margin: 32px auto;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex.layout {
    width: 85.73%;
    margin: 27px auto;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex-item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 369px;
  align-items: center;
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex-item {
    flex: 0 0 100%;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex1 {
  display: flex;
  flex-direction: column;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex1.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 0px 0px 15px;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex1.layout {
    margin: 0px 0px 50px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex1.layout {
    margin: 0px 0px 100px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex1.layout {
    margin: 0px 0px 50px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex2 {
  display: flex;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex2.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex2-item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 46px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-cover-block {
  display: flex;
  flex-direction: column;
  background: var(--src) center center/contain no-repeat;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-cover-block.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights3 {
  display: flex;
  justify-content: center;
  font: 700 16px/2.25 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: white;
  text-align: center;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights3 {
    font-size: 15px;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights3 {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights3 {
    font-size: 13px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights3 {
    font-size: 12px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights3.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 4px 7.5px 6px 8.5px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights3.layout {
    margin: 5px 7px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights3.layout {
    margin: 5px 6px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights3.layout {
    margin: 5px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex2-spacer {
  flex: 0 1 25px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights31 {
  font: 700 16px/2.25 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: white;
  letter-spacing: 1.6px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights31 {
    font-size: 15px;
    text-align: left;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights31 {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights31 {
    font-size: 13px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights31 {
    font-size: 12px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights31.layout {
  position: relative;
  flex: 0 0 auto;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 4px 0px 6px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights31.layout {
    margin: 5px 0px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex1-spacer {
  flex: 0 0 auto;
  min-height: 50px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex1-spacer1 {
  flex: 0 0 auto;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex4 {
  display: flex;
  width: 100%;
  height: 100%;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex4-item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 162px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex5 {
  display: flex;
  flex-direction: column;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex5.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 61px 0px 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex5.layout {
    margin: 52px 0px 0px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex5.layout {
    margin: 43px 0px 0px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex5.layout {
    margin: 35px 0px 0px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex5.layout {
    margin: 31px 0px 0px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex5.layout {
    margin: 27px 0px 0px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex5.layout {
    margin: 24px 0px 0px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex4-spacer {
  flex: 0 1 46px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex4-item1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 152px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex3 {
  display: flex;
  flex-direction: column;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex3.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 100%;
  margin: 0px 0px;
  align-items: flex-end;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex3.layout {
    margin: 5px 0px 0px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-icon1 {
  background: var(--src) center center/contain no-repeat;
  width: 27px;
  height: 27px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6 {
  display: flex;
  justify-content: flex-end;
  font: 800 40px/0.6 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: #3df6a8;
  text-align: right;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6 {
    font-size: 36px;
    text-align: right;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6 {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6 {
    font-size: 28px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6 {
    font-size: 26px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6 {
    font-size: 24px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6 {
    font-size: 23px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 31px 0px 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6.layout {
    margin: 27px 0px 0px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6.layout {
    margin: 23px 0px 0px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6.layout {
    margin: 19px 0px 0px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6.layout {
    margin: 18px 0px 0px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6.layout {
    margin: 16px 0px 0px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6.layout {
    margin: 15px 0px 0px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex1-item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 259px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex41 {
  display: flex;
  align-items: flex-end;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex41.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex51 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights32 {
  display: flex;
  justify-content: center;
  font: 700 16px/1.5 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: white;
  text-align: center;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights32 {
    font-size: 15px;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights32 {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights32 {
    font-size: 13px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights32 {
    font-size: 12px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights32.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 0px 30.5px 3px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights32.layout {
    margin: 0px 27px 5px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights32.layout {
    margin: 0px 23px 5px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights32.layout {
    margin: 0px 19px 5px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights32.layout {
    margin: 0px 17px 5px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights32.layout {
    margin: 0px 16px 5px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights32.layout {
    margin: 0px 14px 5px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-box1 {
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 0.247059);
  border-radius: 2px 2px 2px 2px;
  opacity: 0.4;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-box1.layout {
  position: relative;
  height: 140px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights4 {
  display: flex;
  justify-content: center;
  font: 16px/1.5 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: white;
  text-align: center;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights4 {
    font-size: 15px;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights4 {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights4 {
    font-size: 13px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights4 {
    font-size: 12px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights4.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 7px 0px 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights4.layout {
    margin: 6px 0px 0px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights4.layout {
    margin: 5px 0px 0px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex4-item2 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 161px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex6 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights33 {
  display: flex;
  justify-content: center;
  font: 700 16px/1.5 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: white;
  text-align: center;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights33 {
    font-size: 15px;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights33 {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights33 {
    font-size: 13px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights33 {
    font-size: 12px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights33.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 0px 29.5px 9px 30.5px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights33.layout {
    margin: 0px 26px 8px 27px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights33.layout {
    margin: 0px 22px 7px 23px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights33.layout {
    margin: 0px 19px 6px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights33.layout {
    margin: 0px 17px 5px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights33.layout {
    margin: 0px 15px 5px 16px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights33.layout {
    margin: 0px 14px 5px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-box2 {
  background-color: white;
  box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 0.247059);
  border-radius: 2px 2px 2px 2px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-box2.layout {
  position: relative;
  height: 194px;
  margin: 1px 0px 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-box2.layout {
    margin: 5px 0px 0px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights34 {
  display: flex;
  justify-content: center;
  font: 700 16px/1.5 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: white;
  text-align: center;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights34 {
    font-size: 15px;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights34 {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights34 {
    font-size: 13px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights34 {
    font-size: 12px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights34.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 26.09%;
  margin: 7px auto 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights34.layout {
    margin: 6px auto 0px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights34.layout {
    margin: 5px auto 0px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex-spacer {
  flex: 0 1 254px;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex-spacer {
    display: none;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex7 {
  display: flex;
  flex-direction: column;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex7.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 0px 0px 15px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex7.layout {
    margin: 0px 0px 13px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex7.layout {
    margin: 0px 0px 11px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex7.layout {
    margin: 0px 0px 100px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex7.layout {
    margin: 0px 0px 8px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-flex7.layout {
    margin: 0px 0px 7px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex8 {
  display: flex;
  width: 100%;
  height: 100%;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex8-item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 46px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex8-spacer {
  flex: 0 1 15px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights35 {
  font: 700 16px/2.25 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: white;
  letter-spacing: 1.6px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights35 {
    font-size: 15px;
    text-align: left;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights35 {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights35 {
    font-size: 13px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights35 {
    font-size: 12px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights35.layout {
  position: relative;
  flex: 0 0 auto;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 4px 0px 6px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights35.layout {
    margin: 5px 0px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex7-spacer {
  flex: 0 0 auto;
  min-height: 50px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex4-item3 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 152px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6.layout1 {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 31px 0px 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6.layout1 {
    margin: 25px 0px 0px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6.layout1 {
    margin: 21px 0px 0px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6.layout1 {
    margin: 18px 0px 0px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6.layout1 {
    margin: 16px 0px 0px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6.layout1 {
    margin: 14px 0px 0px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-hero-title6.layout1 {
    margin: 13px 0px 0px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex7-spacer1 {
  flex: 0 0 auto;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex7-item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 259px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex10 {
  display: flex;
  align-items: flex-end;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex10.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex10-item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 162px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex11 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights36 {
  display: flex;
  justify-content: center;
  font: 700 16px/1.5 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: white;
  text-align: center;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights36 {
    font-size: 15px;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights36 {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights36 {
    font-size: 13px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights36 {
    font-size: 12px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights36.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 0px 35.5px 8px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights36.layout {
    margin: 0px 31px 7px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights36.layout {
    margin: 0px 26px 6px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights36.layout {
    margin: 0px 22px 5px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights36.layout {
    margin: 0px 19px 5px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights36.layout {
    margin: 0px 17px 5px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights36.layout {
    margin: 0px 16px 5px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex10-spacer {
  flex: 0 1 46px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex10-item1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 161px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-flex12 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights37 {
  display: flex;
  justify-content: center;
  font: 700 16px/1.5 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: white;
  text-align: center;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights37 {
    font-size: 15px;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights37 {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights37 {
    font-size: 13px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights37 {
    font-size: 12px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights37.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 0px 30.5px 8px 31.5px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights37.layout {
    margin: 0px 27px 7px 28px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights37.layout {
    margin: 0px 23px 6px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights37.layout {
    margin: 0px 19px 5px 20px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights37.layout {
    margin: 0px 17px 5px 18px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights37.layout {
    margin: 0px 16px 5px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights37.layout {
    margin: 0px 14px 5px 15px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-box3 {
  background-color: white;
  box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 0.247059);
  border-radius: 2px 2px 2px 2px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-box3.layout {
  position: relative;
  height: 184px;
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights38 {
  display: flex;
  justify-content: center;
  font: 700 16px/1.5 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: white;
  text-align: center;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights38 {
    font-size: 15px;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights38 {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights38 {
    font-size: 13px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights38 {
    font-size: 12px;
  }
}
.section_ldg_highlight_block_turnover_ebitda .seccao3-highlights38.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 26.09%;
  margin: 7px auto 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights38.layout {
    margin: 6px auto 0px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_ebitda .seccao3-highlights38.layout {
    margin: 5px auto 0px;
  }
}

.section_ldg_highlight_block_investments {
  background-color: #ffffff;
  color: #031d56;
  font-family: "Be Vietnam Pro", Helvetica, Arial, serif;
  letter-spacing: 0;
  /* SECTION 4 */
}
.section_ldg_highlight_block_investments .container {
  /*
  @media (min-width:454px) and (max-width: 1498px) {
    padding-right: var(--bs-gutter-x,62px) !important;
    padding-left: var(--bs-gutter-x,62px) !important;
  }
  */
}
.section_ldg_highlight_block_investments .container .row.title {
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 70px;
  background-color: #F5F5F5;
  align-items: center;
}
.section_ldg_highlight_block_investments .container .row.title p {
  margin-bottom: 0;
}
.section_ldg_highlight_block_investments .container .row p {
  margin-bottom: 0;
}
.section_ldg_highlight_block_investments .container .row p img {
  width: 60%;
  margin: 0px auto;
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_investments .container .row p img {
    width: 100%;
  }
}
.section_ldg_highlight_block_investments .section4-block {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.section_ldg_highlight_block_investments .section4-block.layout {
  position: relative;
  overflow: hidden;
  /*min-height: 700px;*/
  flex-shrink: 0;
}
.section_ldg_highlight_block_investments .section4-flex {
  display: flex;
  flex-direction: column;
}
.section_ldg_highlight_block_investments .section4-flex.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 75%;
  margin: 0px auto 50px;
}
.section_ldg_highlight_block_investments .section4-hero-title3 {
  display: flex;
  justify-content: center;
  font: 700 36px/0.83 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: #031e56;
  text-align: center;
  letter-spacing: 0px;
}
.section_ldg_highlight_block_investments .section4-hero-title3.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 31.59%;
  margin: 0px auto;
}
.section_ldg_highlight_block_investments .section4-highlights2-box {
  display: flex;
  justify-content: center;
}
.section_ldg_highlight_block_investments .section4-highlights2-box.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 47px 4px 0px 0px;
}
.section_ldg_highlight_block_investments .section4-highlights2 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  font: 16px/1.62 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: #031d56;
  text-align: center;
  letter-spacing: 0px;
  white-space: pre-wrap;
}
@media (min-width: 992px) {
  .section_ldg_highlight_block_investments .section4-block_image {
    width: 55%;
    margin: 5px auto;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_investments .section4-block_image {
    width: 100%;
    margin: 75px auto;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_investments .section4-block_image {
    width: 140%;
    margin: 50px -12%;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_investments .section4-block_image {
    width: 140%;
    margin: 50px -12%;
  }
}
.section_ldg_highlight_block_investments .section4-block1 {
  display: flex;
  flex-direction: column;
  background: var(--src) center center/auto no-repeat;
  width: 100%;
  height: 100%;
}
.section_ldg_highlight_block_investments .section4-medium-title1 {
  display: flex;
  justify-content: center;
  font: 700 24px/1.33 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: #1b9cf2;
  text-align: center;
  letter-spacing: 0px;
}
.section_ldg_highlight_block_investments .section4-medium-title1.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 50px 0px 0px;
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_investments .section4-medium-title1.layout {
    margin: 30px 0px 0px;
  }
}
.section_ldg_highlight_block_investments .section4-hero-title1 {
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 3rem;
  line-height: 0.2;
  font-family: "Be Vietnam Pro", Helvetica, Arial, serif;
  color: #1b9cf2;
  text-align: center;
  letter-spacing: 0px;
}
.section_ldg_highlight_block_investments .section4-hero-title1.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 98.45%;
  margin: 35px auto;
}

.section_ldg_highlight_block_turnover_geography {
  min-height: 700px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #031d56;
  font-family: "Be Vietnam Pro", Helvetica, Arial, serif;
  letter-spacing: 0;
  /* SECTION 5 */
}
.section_ldg_highlight_block_turnover_geography .overlay {
  position: relative;
  background-color: rgba(3, 21, 59, 0.9);
  min-height: 700px;
  width: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_turnover_geography .img {
    height: 40px;
  }
}
.section_ldg_highlight_block_turnover_geography .container .row p img {
  width: 60%;
  margin: 0px auto;
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_geography .container .row p img {
    width: 100%;
  }
}
.section_ldg_highlight_block_turnover_geography .section5-block {
  display: flex;
  flex-direction: column;
  background: var(--src) center center/cover no-repeat;
}
.section_ldg_highlight_block_turnover_geography .section5-block.layout {
  position: relative;
  overflow: hidden;
  min-height: 701px;
  flex-shrink: 0;
  /*
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content; */
}
.section_ldg_highlight_block_turnover_geography .section5-decorator2 {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}
.section_ldg_highlight_block_turnover_geography .section5-decorator2.layout {
  position: absolute;
  bottom: 0px;
  left: 2px;
  width: 1920px;
}
.section_ldg_highlight_block_turnover_geography .section5-flex {
  display: flex;
  flex-direction: column;
}
.section_ldg_highlight_block_turnover_geography .section5-flex.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  min-height: 573px;
  flex-shrink: 0;
  margin: 0px auto;
}
@media (max-width: 1919px) {
  .section_ldg_highlight_block_turnover_geography .section5-flex.layout {
    width: 75%;
  }
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_geography .section5-flex.layout {
    margin: 0px auto;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_geography .section5-flex.layout {
    margin: 0px auto;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_geography .section5-flex.layout {
    width: 77.78%;
    margin: 0px auto;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_turnover_geography .section5-flex.layout {
    width: 72.7%;
    margin: 0px auto;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_geography .section5-flex.layout {
    width: 78.02%;
    margin: 0px auto;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_turnover_geography .section5-flex.layout {
    width: 82.56%;
    margin: 0px auto;
  }
}
.section_ldg_highlight_block_turnover_geography .section5-hero-title4 {
  display: flex;
  justify-content: center;
  font: 700 36px/0.83 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: white;
  text-align: center;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_turnover_geography .section5-hero-title4 {
    font-size: 32px;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_geography .section5-hero-title4 {
    font-size: 29px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_geography .section5-hero-title4 {
    font-size: 25px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_turnover_geography .section5-hero-title4 {
    font-size: 24px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_geography .section5-hero-title4 {
    font-size: 22px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_turnover_geography .section5-hero-title4 {
    font-size: 21px;
  }
}
.section_ldg_highlight_block_turnover_geography .section5-hero-title4.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
.section_ldg_highlight_block_turnover_geography .section5-group {
  display: flex;
  flex-direction: column;
  background: var(--src) center center/contain no-repeat;
}
.section_ldg_highlight_block_turnover_geography .section5-group.layout {
  position: relative;
  height: 348px;
  width: 97.5%;
  margin: 143px auto 0px;
}
@media (min-width: 992px) {
  .section_ldg_highlight_block_turnover_geography .section5-group_image {
    margin: 0px auto;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_turnover_geography .section5-group_image {
    width: 100%;
    margin: 5px auto;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_turnover_geography .section5-group_image {
    width: 125%;
    margin: 0px 1%;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_turnover_geography .section5-group_image {
    width: 125%;
    margin: 0px 1%;
  }
}

.section_ldg_highlight_block_total_volume_business_employees {
  background-color: #ffffff;
  color: #031d56;
  font-family: "Be Vietnam Pro", Helvetica, Arial, serif;
  letter-spacing: 0;
  /* SECTION 6 */
}
@media (min-width: 454px) and (max-width: 1498px) {
  .section_ldg_highlight_block_total_volume_business_employees .container {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .container .row.title {
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 70px;
  background-color: #F5F5F5;
  align-items: center;
}
.section_ldg_highlight_block_total_volume_business_employees .container .row.title p {
  margin-bottom: 0;
}
.section_ldg_highlight_block_total_volume_business_employees .section6-block {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.section_ldg_highlight_block_total_volume_business_employees .section6-block.layout {
  position: relative;
  overflow: hidden;
  min-height: 695px;
  flex-shrink: 0;
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex {
  display: flex;
  flex-direction: column;
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 62.5%;
  margin: 167px auto 50px auto;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex.layout {
    width: 68.97%;
  }
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex.layout {
    width: 74.76%;
    margin: 133px auto;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex.layout {
    width: 79.8%;
    margin: 97px auto;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex.layout {
    width: 84.04%;
    margin: 65px auto;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex.layout {
    width: 87.53%;
    margin: 50px auto;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex.layout {
    width: 90.35%;
    margin: 34px auto;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex.layout {
    width: 92.58%;
    margin: 23px auto;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex1 {
  display: flex;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex1 {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex1.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex1-item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 112px;
  align-items: flex-end;
}
@media (max-width: 1919px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex1-item {
    flex: 1 1 auto;
  }
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex1-item {
    flex: 0 0 100%;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-block1 {
  display: flex;
  flex-direction: column;
}
.section_ldg_highlight_block_total_volume_business_employees .section6-block1.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  min-height: 111px;
  flex-shrink: 0;
  margin: 16px 0px 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-block1.layout {
    margin: 14px 0px 0px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-block1.layout {
    margin: 12px 0px 0px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-block1.layout {
    margin: 10px 0px 0px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-block1.layout {
    margin: 9px 0px 0px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-block1.layout {
    margin: 8px 0px 0px;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-icon2 {
  background: var(--src) center center/contain no-repeat;
}
.section_ldg_highlight_block_total_volume_business_employees .section6-icon2.layout {
  position: relative;
  height: 109px;
  width: 109px;
  min-width: 109px;
  margin: 0px 1px 2px 2px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-icon2.layout {
    margin: 0px 5px 5px;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex1-spacer {
  flex: 0 1 78px;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex1-spacer {
    display: none;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex1-item1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 0 auto;
}
@media (max-width: 1919px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex1-item1 {
    min-width: unset;
  }
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex1-item1 {
    flex: 0 0 100%;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-hero-title {
  font: 800 120px/0.2 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: #031d56;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title {
    font-size: 104px;
    text-align: left;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title {
    font-size: 86px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title {
    font-size: 71px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title {
    font-size: 64px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title {
    font-size: 56px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title {
    font-size: 51px;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-hero-title.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  min-height: 24px;
  flex-shrink: 0;
  margin: 31px 0px 72px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title.layout {
    margin: 27px 0px 62px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title.layout {
    margin: 23px 0px 50px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title.layout {
    margin: 19px 0px 41px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title.layout {
    margin: 18px 0px 36px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title.layout {
    margin: 16px 0px 31px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title.layout {
    margin: 15px 0px 28px;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex1-spacer1 {
  flex: 0 1 48px;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex1-spacer1 {
    display: none;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex1-item2 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 324px;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex1-item2 {
    flex: 0 0 100%;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex2 {
  display: flex;
  flex-direction: column;
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex2.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 0px 0px 23px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex2.layout {
    margin: 0px 0px 20px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex2.layout {
    margin: 0px 0px 17px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex2.layout {
    margin: 0px 0px 14px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex2.layout {
    margin: 0px 0px 13px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex2.layout {
    margin: 0px 0px 12px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex2.layout {
    margin: 0px 0px 11px;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-medium-title3 {
  font: 500 24px/1.66 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: #031e56;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title3 {
    font-size: 22px;
    text-align: left;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title3 {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title3 {
    font-size: 19px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title3 {
    font-size: 18px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title3 {
    font-size: 17px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title3 {
    font-size: 16px;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-medium-title3.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
.section_ldg_highlight_block_total_volume_business_employees .section6-medium-title {
  font: 700 24px/1.33 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: #031e56;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title {
    font-size: 22px;
    text-align: left;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title {
    font-size: 19px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title {
    font-size: 18px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title {
    font-size: 17px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title {
    font-size: 16px;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-medium-title.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex-spacer {
  flex: 0 0 auto;
  min-height: 113px;
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex3 {
  display: flex;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3 {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex3.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 9px 0 1px 14.33%;
  /*
    width: 56.75%;
    margin: 9px 3.92% 1px 39.33%; 
  */
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3.layout {
    /*margin: 8px 3.92% 5px 39.33%;*/
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3.layout {
    /*margin: 7px 3.92% 5px 39.33%;*/
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3.layout {
    /*margin: 6px 3.92% 5px 39.33%;*/
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3.layout {
    width: 93.54%;
    margin: 5px 6.46% 5px 0%;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex3-item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 112px;
  align-items: flex-end;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3-item {
    flex: 0 0 100%;
  }
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3-item {
    flex: 0 0 100%;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3-item {
    flex: 0 0 100%;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-block1.layout1 {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-block1.layout1 {
    margin: 9px 0px 0px;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-line {
  background-color: #00f890;
  border-radius: 1px;
}
.section_ldg_highlight_block_total_volume_business_employees .section6-line.layout {
  position: absolute;
  top: 0px;
  height: 2px;
  left: 0px;
  right: 0px;
  margin: 0px 0px 109px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-line.layout {
    margin: 0px 0px 90px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-line.layout {
    margin: 0px 0px 68px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-line.layout {
    margin: 0px 0px 50px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-line.layout {
    margin: 0px 0px 41px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-line.layout {
    margin: 0px 0px 32px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-line.layout {
    margin: 0px 0px 26px;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex3-spacer {
  flex: 0 1 78px;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3-spacer {
    display: none;
  }
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3-spacer {
    flex: 0 0 16px;
    min-width: unset;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3-spacer {
    display: none;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex3-item1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 0 auto;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3-item1 {
    flex: 0 0 100%;
  }
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3-item1 {
    flex: 0 0 calc(33.3333333333% - 10.6666666667px);
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3-item1 {
    flex: 0 0 100%;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-hero-title1 {
  display: flex;
  justify-content: flex-end;
  font: 800 120px/0.2 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: #031d56;
  text-align: right;
  letter-spacing: 0px;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title1 {
    justify-content: flex-start;
    text-align: left;
  }
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title1 {
    font-size: 104px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title1 {
    font-size: 86px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title1 {
    font-size: 71px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title1 {
    font-size: 64px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title1 {
    font-size: 56px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title1 {
    font-size: 51px;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-hero-title1.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 14px 0px 73px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title1.layout {
    margin: 12px 0px 62px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title1.layout {
    margin: 10px 0px 51px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title1.layout {
    margin: 9px 0px 41px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title1.layout {
    margin: 18px 0px 36px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title1.layout {
    margin: 7px 0px 31px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-hero-title1.layout {
    margin: 7px 0px 28px;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex3-spacer1 {
  flex: 0 1 48px;
  /*
  flex: 1 0 auto;
  min-width: 41px; 
  */
}
@media (max-width: 1919px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3-spacer1 {
    flex: 1 1 48px;
  }
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3-spacer1 {
    display: none;
  }
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3-spacer1 {
    flex: 0 0 16px;
    min-width: unset;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3-spacer1 {
    display: none;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex3-item2 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 1 177px;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3-item2 {
    flex: 0 0 100%;
  }
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3-item2 {
    flex: 0 0 100%;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex3-item2 {
    flex: 0 0 100%;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex4 {
  display: flex;
  flex-direction: column;
}
.section_ldg_highlight_block_total_volume_business_employees .section6-flex4.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 14px 0px 25px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex4.layout {
    margin: 12px 0px 22px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex4.layout {
    margin: 10px 0px 19px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex4.layout {
    margin: 9px 0px 16px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex4.layout {
    margin: 8px 0px 14px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex4.layout {
    margin: 7px 0px 13px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-flex4.layout {
    margin: 7px 0px 12px;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-medium-title2 {
  font: 500 24px/1.66 "Be Vietnam Pro Light", Helvetica, Arial, serif;
  color: #031d56;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title2 {
    font-size: 22px;
    text-align: left;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title2 {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title2 {
    font-size: 19px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title2 {
    font-size: 18px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title2 {
    font-size: 17px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title2 {
    font-size: 16px;
  }
}
.section_ldg_highlight_block_total_volume_business_employees .section6-medium-title2.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title2.layout {
    margin: 0px 8px 0px 0px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title2.layout {
    margin: 0px 7px 0px 0px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title2.layout {
    margin: 0px 6px 0px 0px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_total_volume_business_employees .section6-medium-title2.layout {
    margin: 0px 5px 0px 0px;
  }
}

.section_ldg_highlight_block_president_message {
  background-color: #ffffff;
  color: #031d56;
  font-family: "Be Vietnam Pro", Helvetica, Arial, serif;
  letter-spacing: 0;
  /* SECTION 8 */
}
@media (min-width: 454px) and (max-width: 1498px) {
  .section_ldg_highlight_block_president_message .container {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
.section_ldg_highlight_block_president_message .container .row.title {
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 70px;
  background-color: #F5F5F5;
  align-items: center;
}
.section_ldg_highlight_block_president_message .container .row.title p {
  margin-bottom: 0;
}
.section_ldg_highlight_block_president_message .section8-block {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.section_ldg_highlight_block_president_message .section8-block.layout {
  position: relative;
  overflow: hidden;
  min-height: 500px;
  flex-shrink: 0;
}
.section_ldg_highlight_block_president_message .section8-flex {
  display: flex;
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_president_message .section8-flex {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
.section_ldg_highlight_block_president_message .section8-flex.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 62.6%;
  margin: 25px auto;
}
@media (max-width: 1399px) {
  .section_ldg_highlight_block_president_message .section8-flex.layout {
    width: 69.07%;
  }
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_president_message .section8-flex.layout {
    width: 74.85%;
    margin: 108px auto;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_president_message .section8-flex.layout {
    width: 79.87%;
    margin: 81px auto;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_president_message .section8-flex.layout {
    width: 84.1%;
    margin: 57px auto;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_president_message .section8-flex.layout {
    width: 87.58%;
    margin: 45px auto;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_president_message .section8-flex.layout {
    width: 90.39%;
    margin: 33px auto;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_president_message .section8-flex.layout {
    width: 92.61%;
    margin: 25px auto;
  }
}
.section_ldg_highlight_block_president_message .section8-flex-item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 787px;
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_president_message .section8-flex-item {
    flex: 0 0 100%;
  }
}
.section_ldg_highlight_block_president_message .section8-flex1 {
  display: flex;
  flex-direction: column;
}
.section_ldg_highlight_block_president_message .section8-flex1.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  min-width: 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 81px 0px 81px 2px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_president_message .section8-flex1.layout {
    margin: 68px 5px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_president_message .section8-flex1.layout {
    margin: 55px 5px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_president_message .section8-flex1.layout {
    margin: 43px 5px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_president_message .section8-flex1.layout {
    margin: 37px 5px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_president_message .section8-flex1.layout {
    margin: 32px 5px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_president_message .section8-flex1.layout {
    margin: 28px 5px;
  }
}
.section_ldg_highlight_block_president_message .section8-hero-title5 {
  font: 700 36px/0.83 "Be Vietnam Pro Light", Helvetica, Arial, serif;
  color: #031d56;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_president_message .section8-hero-title5 {
    font-size: 32px;
    text-align: left;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_president_message .section8-hero-title5 {
    font-size: 29px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_president_message .section8-hero-title5 {
    font-size: 25px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_president_message .section8-hero-title5 {
    font-size: 24px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_president_message .section8-hero-title5 {
    font-size: 22px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_president_message .section8-hero-title5 {
    font-size: 21px;
  }
}
.section_ldg_highlight_block_president_message .section8-hero-title5.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
.section_ldg_highlight_block_president_message .section8-flex1-spacer {
  flex: 0 0 auto;
  min-height: 48px;
}
.section_ldg_highlight_block_president_message .section8-highlights2 {
  font: 16px/1.62 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: #031d56;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_president_message .section8-highlights2 {
    font-size: 15px;
    text-align: left;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_president_message .section8-highlights2 {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_president_message .section8-highlights2 {
    font-size: 13px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_president_message .section8-highlights2 {
    font-size: 12px;
  }
}
.section_ldg_highlight_block_president_message .section8-highlights2.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 1px 0px 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_president_message .section8-highlights2.layout {
    margin: 5px 0px 0px;
  }
}
.section_ldg_highlight_block_president_message .section8-flex1-spacer1 {
  flex: 0 0 auto;
  min-height: 43px;
}
.section_ldg_highlight_block_president_message .section8-cover-block {
  display: flex;
  flex-direction: column;
  background-color: white;
  /*border: 2px solid #1b9cf2;*/
}
.section_ldg_highlight_block_president_message .section8-cover-block.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  min-width: 240px;
  margin: 1px auto;
}
.section_ldg_highlight_block_president_message .section8-text-body {
  display: flex;
  justify-content: left;
  font: 700 14px/1.42 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: #031e56;
  text-align: center;
  letter-spacing: 0px;
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_president_message .section8-text-body {
    font-size: 13px;
    text-align: center;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_president_message .section8-text-body {
    font-size: 12px;
  }
}
.section_ldg_highlight_block_president_message .section8-text-body.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  min-height: 20px;
  flex-shrink: 0;
  margin: 13px auto 17px auto;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_president_message .section8-text-body.layout {
    margin: 11px 32px 15px 30px;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_president_message .section8-text-body.layout {
    margin: 10px 27px 13px 26px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_president_message .section8-text-body.layout {
    margin: 8px 22px 11px 21px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_president_message .section8-text-body.layout {
    margin: 7px 20px 10px 19px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_president_message .section8-text-body.layout {
    margin: 7px 18px 9px 17px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_president_message .section8-text-body.layout {
    margin: 6px 16px 8px;
  }
}
.section_ldg_highlight_block_president_message .section8-flex-spacer {
  flex: 0 1 55px;
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_president_message .section8-flex-spacer {
    display: none;
  }
}
.section_ldg_highlight_block_president_message .section8-flex-item1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 360px;
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_president_message .section8-flex-item1 {
    flex: 0 0 100%;
  }
}
.section_ldg_highlight_block_president_message .section8-image13 {
  background: var(--src) center center/contain no-repeat;
}
.section_ldg_highlight_block_president_message .section8-image13.layout {
  position: relative;
  flex-grow: 1;
  min-height: 460px;
  flex-shrink: 0;
  margin: 0px 0px 5px;
}
.section_ldg_highlight_block_president_message .section8-image14.layout {
  background-image: url("/img/assets/9646431631d4590bf0c6a3755c9ad695.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  flex-grow: 1;
  min-height: 460px;
  flex-shrink: 0;
  margin: 0px 0px 5px;
}
.section_ldg_highlight_block_president_message .button-read-message:hover {
  background-position: bottom;
  color: #fff;
}
.section_ldg_highlight_block_president_message a:hover {
  color: #0a58ca;
}
.section_ldg_highlight_block_president_message .button-read-message {
  background: linear-gradient(0deg, #1b9cf2 50%, #fff 0);
  background-position: top;
  background-size: 100% 200%;
  border: 2px solid #1b9cf2;
  border-radius: 0;
  color: #03153b;
  font-family: Be Vietnam Pro Bold, sans-serif;
  font-size: 14px;
  padding: 16px 87px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-position 0.3s ease-out;
}
.section_ldg_highlight_block_president_message .button-download:hover {
  background-position: bottom;
  color: #fff;
}
.section_ldg_highlight_block_president_message .button-download {
  background: linear-gradient(0deg, #1b9cf2 50%, #fff 0);
  background-position: top;
  background-size: 100% 200%;
  border: 2px solid #1b9cf2;
  border-radius: 0;
  color: #03153b;
  font-family: Be Vietnam Pro Bold, sans-serif;
  font-size: 14px;
  padding: 16px 60px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-position 0.3s ease-out;
}

.section_ldg_highlight_block_see_complete_accounts_report {
  background-color: #f3f3f3;
  color: #031d56;
  font-family: "Be Vietnam Pro", Helvetica, Arial, serif;
  letter-spacing: 0;
  /* SECTION 9 */
}
@media (min-width: 454px) and (max-width: 1498px) {
  .section_ldg_highlight_block_see_complete_accounts_report .container {
    padding-right: var(--bs-gutter-x, 62px) !important;
    padding-left: var(--bs-gutter-x, 62px) !important;
  }
}
.section_ldg_highlight_block_see_complete_accounts_report .container .row.title {
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 70px;
  background-color: #F5F5F5;
  align-items: center;
}
.section_ldg_highlight_block_see_complete_accounts_report .container .row.title p {
  margin-bottom: 0;
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-block {
  display: flex;
  flex-direction: column;
  background: var(--src) center center/cover no-repeat;
  background-color: #f3f3f3;
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-block.layout {
  position: relative;
  overflow: hidden;
  min-height: 250px;
  flex-shrink: 0;
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-flex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-medium-title {
  display: flex;
  justify-content: center;
  font: 700 24px/1.25 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: #031d56;
  text-align: center;
  letter-spacing: 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_see_complete_accounts_report .section9-medium-title {
    font-size: 22px;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_see_complete_accounts_report .section9-medium-title {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .section_ldg_highlight_block_see_complete_accounts_report .section9-medium-title {
    font-size: 19px;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_see_complete_accounts_report .section9-medium-title {
    font-size: 18px;
  }
}
@media (max-width: 479px) {
  .section_ldg_highlight_block_see_complete_accounts_report .section9-medium-title {
    font-size: 17px;
  }
}
@media (max-width: 383px) {
  .section_ldg_highlight_block_see_complete_accounts_report .section9-medium-title {
    font-size: 16px;
  }
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-medium-title.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 3px 0px 0px;
}
@media (max-width: 1199px) {
  .section_ldg_highlight_block_see_complete_accounts_report .section9-medium-title.layout {
    margin: 5px 0px 0px;
  }
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-flex-item {
  display: flex;
  flex-direction: column;
  position: relative;
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-flex1-spacer1 {
  flex: 0 0 auto;
  min-height: 43px;
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-cover-block {
  display: flex;
  flex-direction: column;
  background-color: white;
  /*border: 2px solid #1b9cf2; */
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-cover-block.layout {
  position: relative;
  flex-grow: 1;
  min-height: 50px;
  flex-shrink: 0;
  min-width: 207px;
  margin: 1px auto 0px;
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-text-body {
  display: flex;
  justify-content: center;
  font: 700 14px/1.42 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: #031e56;
  text-align: center;
  letter-spacing: 0px;
}
@media (max-width: 991px) {
  .section_ldg_highlight_block_see_complete_accounts_report .section9-text-body {
    font-size: 13px;
    text-align: center;
  }
}
@media (max-width: 575px) {
  .section_ldg_highlight_block_see_complete_accounts_report .section9-text-body {
    font-size: 12px;
  }
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-text-body.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  min-width: 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  /*margin: 13px auto 17px;*/
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-flex1 {
  display: flex;
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-flex1.layout {
  position: absolute;
  top: 11px;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  bottom: 11px;
  /*left: 24px;*/
  right: 20px; /*24px;*/
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-flex1-item {
  display: flex;
  flex-direction: column;
  position: relative;
  /*flex: 1 1 192px;*/
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-group {
  display: flex;
  flex-direction: column;
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-group.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  /*margin: 0px 0px 0px 168px; */
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-image1 {
  background: var(--src) center center/contain no-repeat;
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-image1.layout {
  position: absolute;
  top: 0px;
  height: 12px;
  left: 0px;
  width: 10px;
  min-width: 10px;
  margin: 16px 0px 0px 14px;
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-image2 {
  background: var(--src) center center/contain no-repeat;
}
.section_ldg_highlight_block_see_complete_accounts_report .section9-image2.layout {
  position: relative;
  height: 28px;
  width: 20px;
  min-width: 20px;
  margin: 0px 4px 0px 0px;
}
.section_ldg_highlight_block_see_complete_accounts_report .button-read-message:hover {
  background-position: bottom;
  color: #fff;
}
.section_ldg_highlight_block_see_complete_accounts_report a:hover {
  color: #0a58ca;
}
.section_ldg_highlight_block_see_complete_accounts_report .button-read-message {
  background: linear-gradient(0deg, #1b9cf2 50%, #fff 0);
  background-position: top;
  background-size: 100% 200%;
  border: 2px solid #1b9cf2;
  border-radius: 0;
  color: #03153b;
  font-family: Be Vietnam Pro Bold, sans-serif;
  font-size: 14px;
  padding: 16px 50px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-position 0.3s ease-out;
}
.section_ldg_highlight_block_see_complete_accounts_report .button-download:hover {
  background-position: bottom;
  color: #fff;
}
.section_ldg_highlight_block_see_complete_accounts_report .button-download {
  background: linear-gradient(0deg, #1b9cf2 50%, #fff 0);
  background-position: top;
  background-size: 100% 200%;
  border: 2px solid #1b9cf2;
  border-radius: 0;
  color: #03153b;
  font-family: Be Vietnam Pro Bold, sans-serif;
  font-size: 14px;
  padding: 16px 60px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-position 0.3s ease-out;
}

/* SECTION 1 */
.section1-block {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.section1-block.layout {
  position: relative;
  overflow: hidden;
  min-height: 500px;
  flex-shrink: 0;
}

.section1-flex {
  display: flex;
  flex-direction: column;
  background: var(--src) center center/cover no-repeat;
}

.section1-flex.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}

.section1-block2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.section1-block3 {
  display: flex;
  flex-direction: column;
}

.section1-block3.layout {
  position: relative;
  height: 8px;
  margin: 16px 8px 16px 10px;
}

.section1-box9 {
  background-color: white;
}

.section1-box9.layout {
  position: absolute;
  height: 3px;
  bottom: 7px;
  left: 0px;
  width: 22px;
}

.section1-box9.layout1 {
  position: absolute;
  top: 7px;
  height: 3px;
  left: 0px;
  width: 22px;
}

.section1-flex1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.section1-image7 {
  background: var(--src) center center/contain no-repeat;
}

.section1-image7.layout {
  position: relative;
  min-height: 49px;
  flex-shrink: 0;
  width: 28.53%;
  margin: 0px auto;
}

@media (max-width: 479px) {
  .section1-image7.layout {
    width: 91.74%;
  }
}
.section1-hero-title2 {
  display: flex;
  justify-content: center;
  font: 300 40px/1.75 "IBM Plex Mono", Helvetica, Arial, serif;
  color: white;
  text-align: center;
  text-decoration: underline;
  letter-spacing: 0px;
  width: 100%;
  height: 100%;
}

@media (max-width: 1199px) {
  .section1-hero-title2 {
    font-size: 36px;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .section1-hero-title2 {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  .section1-hero-title2 {
    font-size: 28px;
  }
}
@media (max-width: 575px) {
  .section1-hero-title2 {
    font-size: 26px;
  }
}
@media (max-width: 479px) {
  .section1-hero-title2 {
    font-size: 24px;
  }
}
@media (max-width: 383px) {
  .section1-hero-title2 {
    font-size: 23px;
  }
}
.section1-highlights1 {
  display: flex;
  justify-content: center;
  font: 700 18px/1.2 "Be Vietnam Pro", Helvetica, Arial, serif;
  color: #00b2ff;
  text-align: center;
  letter-spacing: 1.8px;
}

@media (max-width: 1199px) {
  .section1-highlights1 {
    font-size: 17px;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .section1-highlights1 {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .section1-highlights1 {
    font-size: 15px;
  }
}
@media (max-width: 479px) {
  .section1-highlights1 {
    font-size: 14px;
  }
}
.section1-highlights1.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 33.8%;
  margin: 23px auto 0px;
}

@media (max-width: 1199px) {
  .section1-highlights1.layout {
    width: 100%;
    margin: 20px 0px 0px;
  }
}
@media (max-width: 991px) {
  .section1-highlights1.layout {
    margin: 17px 0px 0px;
  }
}
@media (max-width: 767px) {
  .section1-highlights1.layout {
    margin: 14px 0px 0px;
  }
}
@media (max-width: 575px) {
  .section1-highlights1.layout {
    margin: 13px 0px 0px;
  }
}
@media (max-width: 479px) {
  .section1-highlights1.layout {
    margin: 12px 0px 0px;
  }
}
@media (max-width: 383px) {
  .section1-highlights1.layout {
    margin: 11px 0px 0px;
  }
}
.skip-link {
  background: #1B9CF2;
  color: #FFFFFF;
  font-weight: 700;
  left: 50%;
  padding: 4px;
  position: absolute;
  transform: translateY(-100%);
}

.skip-link:focus {
  transform: transform 0.3s;
}