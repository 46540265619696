html { scroll-behavior: smooth; }

$xs: 453px;
$sm: 575px;
$md: 767px;
$lg: 991px;
$xl: 1199px;
$xxl: 1399px;

$navbarHeight: 70px;
