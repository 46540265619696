//Main Slider
.section_main_slider {
  background-color: rgba(3, 21, 59, 1);
}
.splide {
  &.home {
    .splide__track {
      .splide__list {
        .splide__slide {
          >.row {
            height: 100%;
            >.col-left {
              padding: 5.86vh 50px 60px 50px;
              max-width: 490px;
              background-repeat: no-repeat;
              background-position: bottom left;
              @include media-breakpoint-down($lg) {
                max-width: unset;
                background-size: cover;
              }
              @include media-breakpoint-down(405px) {
                padding: 5.86vh 5px 60px 5px;
              }
              >.row {
                >.col-top {
                  //height: 56vh;
                  //min-height: 300px;
                  h1 {
                    font-family: 'IBM Plex Mono ExtraLight', sans-serif;
                    text-transform: uppercase;
                    text-decoration: underline;
                    color: #5A6A8B;
                    text-decoration-thickness: 2px;
                    text-underline-offset: 8px;
                    @include media-breakpoint-down(899px) {
                      font-size: 5vw;
                    }
                    @include media-breakpoint-down($sm) {
                      font-size: 58px;
                    }
                    @include media-breakpoint-down(506px) {
                      font-size: 10vw;
                    }
                    @media (max-height: 620px) {
                      font-size: 6vh;
                      line-height: 9vh;
                    }
                  }
                  .counters {
                    padding-top: 5.33vh;
                    padding-right: 1.33vh;
                    @include media-breakpoint-down(606px) {
                      padding-top: 1vh;
                    }
                    @include media-breakpoint-down($sm) {
                      padding-top: 5.33vh;
                    }
                    @include media-breakpoint-down(288px) {
                      padding-top: 2vh;
                    }
                    &:last-of-type {
                      padding-top: 3.20vh;
                      @include media-breakpoint-down(606px) {
                        padding-top: 1vh;
                      }
                      @include media-breakpoint-down($sm) {
                        padding-top: 3.20vh;
                      }
                      @include media-breakpoint-down(288px) {
                        padding-top: 2vh;
                      }
                    }
                    .c-number, .c-number-t {
                      display: inline-block;
                      font-family: 'Be Vietnam Pro', sans-serif;
                      text-transform: uppercase;
                      color: #ffffff;
                      line-height: 5.20vh;
                      @include media-breakpoint-down(926px) {
                        font-size: 3vw;
                      }
                      @include media-breakpoint-down($md) {
                        font-size: 4vw;
                      }
                      @include media-breakpoint-down($sm) {
                        font-size: 40px;
                      }
                      @media (max-height: 620px) {
                        font-size: 5vh;
                      }
                    }
                    .c-text {
                      font-family: 'Be Vietnam Pro', sans-serif;
                      text-transform: uppercase;
                      color: #1B9CF2;
                      @include media-breakpoint-down($sm) {
                        font-size: 10px;
                      }
                    }
                  }
                }
                >.col-bottom {
                  position: absolute;
                  bottom: 70px;
                  width: calc(100% - 100px);
                  @include media-breakpoint-down(405px) {
                    width: calc(100% - 9px);
                  }
                  @include media-breakpoint-down($lg) {
                    text-align: -webkit-center;
                  }
                  @media (max-height: 620px) {
                    bottom: 10vh;
                  }
                  @media (max-height: 525px) {
                    position: unset;
                  }
                  .button-main-slider {
                    &:first-of-type {
                      //margin-top: 5.97vh;
                    }
                    &:last-of-type {
                      margin-top: 2.13vh;
                    }
                  }
                }
              }
            }
            >.col-right {
              padding-left: 0;
              padding-right: 0;
              height: 100%;
              >.row{
                @include media-breakpoint-down($sm) {
                  max-width: unset;
                }
              }
            }
          }
        }
      }
    }
  }
}

//Main Slider Inside Vertical
.splide.inside.vertical {
  padding: 0;
  height: 100%;
  .overlay {
    height: 100%;
    width: 100%;
    background: rgb(3,21,59);
    background: linear-gradient(180deg, rgba(3,21,59,0) 0%, rgba(3,21,59,0.2) 70%, rgba(3,21,59,0.7) 100%);
    text-align: right;
    padding-right: 35px;
    align-items: flex-start;
    display: flex;
    justify-content: flex-end;
    .content {
      max-width: 600px;
      padding-right: 80px;
    }
  }
  .splide__pagination {
    top: 0;
    right: 62px;
    bottom: unset;
    padding: 0;
    li {
      //line-height: 3;
      .splide__pagination__page {
        background: transparent;
        height: 21px;
        width: 21px;
        opacity: 1;
        transition: all 0.3s ease-in-out;
        &::before {
          content: "";
          background: transparent;
          width: 19px;
          height: 19px;
          display: block;
          position: absolute;
          border-radius: 50%;
          margin-top: -9px;
          margin-left: 1px;
        }
        &::after {
          content: "";
          background: #ffffff;
          width: 3px;
          height: 3px;
          display: block;
          position: absolute;
          border-radius: 50%;
          margin-left: 9px;
          margin-top: -1px;
        }
        &.is-active {
          background: rgba(3, 38, 114, 0.4);
          transform: unset;
        }
        &:hover {
          background: rgba(3, 38, 114, 0.4);
          transform: unset;
        }
      }
    }
  }
  .splide__track {
    .splide__list {
      .splide__slide {
        >.col {
          background-repeat: no-repeat;
          background-position: top left;
          background-size: cover;
          height: 100%;
        }
      }
    }
  }
}

//News Slider
.section_news_slider {
  background-color: #F8F8F8;
}
.splide {
  &.news {
    display: inline-block;
    width: 100%;
    .splide__arrows {
      .splide__arrow {
        border-radius: 0;
        opacity: 0.7;
        width: 80px;
        height: 80px;
        margin: 0 -16px;
        background: none;
        transition: opacity 0.3s ease;
        img {
          height: 32px;
          width: auto;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
    .splide__track {
      margin-left: 100px;
      margin-right: 100px;
      .splide__list {
        .splide__slide {
          > a {
            display: flex;
            padding-top: 25px;
            text-decoration: none;
            > img {
              margin-right: 16px;
              max-height: 70px;
              @include media-breakpoint-down($sm) {
                display: none;
              }
            }
            > span {
              font-family: 'Be Vietnam Pro', sans-serif;
              color: #02143A;
            }
          }
        }
      }
    }
  }
}

//Block Sectors
.section_sectors {
  .container {
    @media (min-width:454px) and (max-width: 1498px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
  }
  .row {
    .col-6 {
      text-align: center;
      line-height: normal;
      > a {
        color: #03153b;
        padding-top: 25px;
        text-decoration: none;
        > img {
          /* margin-right: 16px; */
          max-height: 70px;
          
          @include media-breakpoint-down($sm) {
            /*display: none;*/            
          }
        }
        @include media-breakpoint-down($sm) {
          .ff-bevietmanpro {
            text-align: center;
            align-items: center;
            display: flex;
            justify-content: center;
          }
          .ff-bevietmanpro300 { display: none; }
        }   
      } 
    } 
    .shadow_ss {
      box-shadow: 0!important;
      --bs-bg-opacity: 0;
      padding: 2rem!important;
      margin-bottom: 3rem!important;
      border-radius: 0!important;
      &:hover {
        box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
        padding: 2rem!important;
        margin-bottom: 3rem!important;
        --bs-bg-opacity: 1;
        background-color: rgba(var(--bs-white-rgb),var(--bs-bg-opacity))!important;
        border-radius: .25rem!important;
      }
    } 
  }
}
/*
.section_sectors {
  .row {
    .col-6 {
      .bot_sector {
        display: flex;
        align-items: center;
        text-decoration: none;
        background-color: #0F1E40;
        width: 100%;
        border-top: 1px solid #2D3F65;
        border-right: 1px solid #2D3F65;
        border-bottom: 0;
        border-left: 0;
        font-family: 'Be Vietnam Pro', sans-serif;
        height: 370px;
        color: #ffffff;
        justify-content: center;
        img {
          margin-right: 23px;
        }
        &:hover {
          background-color: #2D3F65;
        }
      }
    }
  }
}
*/@at-root
//Block Labels
.section_labels {
  padding: 6.981vw 0 4.981vw 0;
  .label1 {
    font-family: 'Be Vietnam Pro Bold', sans-serif;
    text-transform: uppercase;
    color: #03153B;
    font-size: 3.678vw;
  }
  .label2 {
    font-family: 'Be Vietnam Pro Medium', sans-serif;
    text-transform: uppercase;
    -webkit-text-stroke: 1px #03153B;
    -webkit-text-fill-color: #ffffff;
    text-align: right;
    font-size: 7.357vw;
  }
}

//Block Map
#googleMap {
  display: block;
  width: 100%;
  height: 590px;
  transition: all 0.3s ease;
  @include media-breakpoint-down(396px) {
    transition: all 0.3s ease;
    height: 356px;
  }
  @include media-breakpoint-down(250px) {
    transition: all 0.3s ease;
    height: 180px;
  }
  iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }
}
.section_maps {
  >.container {
    @media (min-width:454px) and (max-width: 1498px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
    >.row {
      >.col {
        h3 {
          font-family: 'Be Vietnam Pro', sans-serif;
          color: #03153B;
          margin-bottom: 40px;
          align-self: start;
          @include media-breakpoint-down($xs) {
            font-size: 20px;
          }
        }
      }
      .content {
        padding: 20px 40px;
        
        &.text-align { 
          padding: 50px;
          text-align: right;
        }
        @include media-breakpoint-down($md){          
          h6 { text-align: center; }
          &.text-align {
            padding: 50px;
            text-align: center;
          }
        }
        @include media-breakpoint-down($sm){          
          h6 { text-align: center; }
          &.text-align {
            padding: 5px;
            text-align: center;
          }
        }
        @include media-breakpoint-down($xs){ 
          h6 { text-align: center; }
          &.text-align {
            padding: 15px;
            text-align: center;
          }
        }
      }
    }
  }
}

//Block Training
.section_training {
  .container-medium {
    @media (min-width:454px) and (max-width: 1774px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
  }
  .col-left {
    background-color: #ffffff;
    padding: 112px 122px 153px 122px;
    h4 {
      font-family: 'Be Vietnam Pro', sans-serif;
      color: #03153B;
      margin-bottom: 60px;
    }
    @include media-breakpoint-down(1485px) {
      padding: 112px 50px 153px 50px;
    }
    @include media-breakpoint-down(1290px) {
      .row{
        div{
          img{
            width: 180px;
            height: 180px;
          }
        }
      }
    }

    @include media-breakpoint-down(1040px) {
      padding: 112px 20px 153px 20px;
    }

    @include media-breakpoint-down($lg) {
      display:none;
    }
  }
  .col-right {
    background: rgb(0,216,174);
    background: linear-gradient(180deg, rgba(0,216,174,1) 0%, rgba(0,27,85,1) 100%);
    padding: 114px 122px 0 110px;
    height: 550px;
    h3 {
      font-family: 'Be Vietnam Pro', sans-serif;
      color: #ffffff;
      text-align: right;
    }
    h6 {
      font-family: 'Be Vietnam Pro', sans-serif;
      color: #ffffff;
      text-align: right;
    }

    @include media-breakpoint-down(1091px) {
      padding: 114px 40px 0 40px;
    }

    @include media-breakpoint-down($sm) {
      padding: 50px;
      height: auto;
      h3{
        font-size: 28px;
      }
    }
    @include media-breakpoint-down($lg) {
      padding: 114px 122px 0 110px;
    }
    @include media-breakpoint-down($sm) {
      padding: 114px 50px 50px 50px;
    }
    @include media-breakpoint-down($xs) {
      padding: 30px 40px 30px;
    }
  }
  .col-mobile {
    box-shadow: 0 0 50px 0 rgb(0 0 0 / 14%);
  }
}

//Block Clients
.section_clients.bb_1x_silver{
  padding-bottom:15px;
  margin-bottom:60px;
}
.section_clients {
  .container {
    @media (min-width:454px) and (max-width: 1498px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
  }

  padding-bottom: 68px;

  @include media-breakpoint-down($lg){
    text-align: center;
  }

  @include media-breakpoint-down($sm){
    .splide.clients{
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  @include media-breakpoint-down($xs){
    .splide.clients{
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
.splide {
  &.clients {
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 80px;
    .splide__arrows {
      @include media-breakpoint-down(530px){
        display: none;
      }
      .splide__arrow {
        &.splide__arrow--prev {
          left: 0;
        }
        &.splide__arrow--next {
          right: 0;
        }
        border-radius: 0;
        opacity: 0.7;
        width: 80px;
        height: 80px;
        background: none;
        transition: opacity 0.3s ease;
        img {
          height: 32px;
          width: auto;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
    .splide__track {
      .splide__list {
        .splide__slide {
          a {
            opacity: 0.7;
            transition: opacity 0.3s ease;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
    .splide__pagination {
      li {
        .splide__pagination__page {
          background: #ffffff;
          height: 21px;
          width: 21px;
          opacity: 1;
          transition: all 0.3s ease-in-out;
          &::before {
            content: "";
            background: #FFFFFF;
            width: 19px;
            height: 19px;
            display: block;
            position: absolute;
            border-radius: 50%;
            margin-top: -9px;
            margin-left: 1px;
          }
          &::after {
            content: "";
            background: #032672;
            width: 3px;
            height: 3px;
            display: block;
            position: absolute;
            border-radius: 50%;
            margin-left: 9px;
            margin-top: -1px;
          }
          &.is-active {
            background: rgba(3, 38, 114, 0.4);
            transform: unset;
          }
          &:hover {
            background: rgba(3, 38, 114, 0.4);
            transform: unset;
          }
        }
      }
    }
  }
}

//Block News Jumbo
.section_news_jumbo {
  .container-medium {
    >.row {
      >.col {
        >.overlay {
          background-color: rgba(3,21,59,.6);
          position: relative;
          height: 100%;
          >.content, {
            padding: 70px 120px;
            @include media-breakpoint-down($sm){
              padding: 40px 60px;
              &.text-right {
                text-align: center;
              }
            }
            @include media-breakpoint-down($xs){
              padding: 30px 40px;
              &.text-right {
                text-align: center;
              }
            }
          }

          >.splide.news_jumbo {
            >.splide__arrows {
              .splide__arrow {
                opacity: 1;
                border-radius: 0;
                width: 50px;
                height: 50px;
                margin: 0 -16px;
                background: #FFFFFF;
                transition: opacity 0.2s ease-in;
                &:hover {
                  background: #FFFFFF;
                }
                &.splide__arrow--prev {
                  justify-content: start;
                  padding-left: 15px;
                  transition: all 0.2s ease-in;
                  &:hover {
                    padding-left: 0;
                  }
                }
                &.splide__arrow--next {
                  justify-content: flex-end;
                  padding-right: 15px;
                  transition: all 0.2s ease-in;
                  &:hover {
                    padding-right: 0;
                  }
                }
              }
            }
            >.splide__track {
              margin: 0 120px 1rem;
              @include media-breakpoint-down($lg){ margin: 0 0 1rem 120px; padding-right: 120px!important; }
              @include media-breakpoint-down($sm){ margin: 0 0 1rem 60px; padding-right: 60px!important; }
              @include media-breakpoint-down($xs){ margin: 0 0 1rem 40px; padding-right: 40px!important; }
              >.splide__list {
                >.splide__slide {
                  background-color: #ffffff;
                  >a {
                    text-decoration: none;
                    >.row {
                      >.col {
                        background-color: #ffffff;
                        padding: 55px 40px;
                        >.content {
                          word-break: break-word;
                          >h3 {
                            //height: 80px;
                            @include media-breakpoint-down(400px){
                              line-height: 19px;
                              font-size: 16px;
                            }
                          }
                          >div {
                            //height: 145px;
                            //overflow: hidden;
                          }
                        }
                        >.lermais {
                          position: absolute;
                          bottom: 0;
                          right: 12px;
                          left: 0;
                          padding-right: 20px;
                          padding-bottom: 15px;
                          background: rgb(255,255,255);
                          background: linear-gradient(180deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,1) 50%);
                          .bullet {
                            transition: all 0.3s ease-out;
                            padding-left: 0;
                            margin: 0 0 5px 10px;
                          }
                        }
                      }
                    }
                    &:hover {
                      .row {
                        .col {
                          background-color: #F8F8F8;
                          >.lermais {
                            background: rgb(248,248,248);
                            background: linear-gradient(180deg, rgba(248,248,248,0.7) 0%, rgba(248,248,248,1) 50%);
                            .bullet {
                              transition: all 0.3s ease-out;
                              margin-left: 25px;
                              margin-right: -15px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            >.splide__pagination {
              bottom: unset;
              li {
                .splide__pagination__page {
                  background: transparent;
                  height: 21px;
                  width: 21px;
                  opacity: 1;
                  transition: all 0.3s ease-in-out;
                  &::before {
                    content: "";
                    background: transparent;
                    width: 19px;
                    height: 19px;
                    display: block;
                    position: absolute;
                    border-radius: 50%;
                    margin-top: -9px;
                    margin-left: 1px;
                  }
                  &::after {
                    content: "";
                    background: #ffffff;
                    width: 3px;
                    height: 3px;
                    display: block;
                    position: absolute;
                    border-radius: 50%;
                    margin-left: 9px;
                    margin-top: -2px;
                  }
                  &.is-active {
                    background: rgba(255, 255, 255, 0.4);
                    transform: unset;
                  }
                  &:hover {
                    background: rgba(255, 255, 255, 0.4);
                    transform: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//Responsive row
.row{
  @include media-breakpoint-down($sm){
    max-width: 100%;
  }
}
