//Grid Page
.section_news_grid_block {
  .container {
    @media (min-width:454px) and (max-width: 1498px) {
      padding-right: var(--bs-gutter-x,62px) !important;
      padding-left: var(--bs-gutter-x,62px) !important;
    }
  }
  .row{
    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      text-decoration: none;
      &:hover {
        background-color: #F8F8F8;
        .content {
          .bullet {
            transition: all 0.3s ease-out;
            margin-left: 25px;
            margin-right: -15px;
          }
        }
      }
      > img {
        min-height: 246px;
        object-fit: cover;
      }
      .content {
        padding: 30px;
        div:nth-of-type(3) {
          height: 340px;
          overflow: hidden;
          -webkit-mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
          mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
        }
        .bullet {
          margin-left: 10px;
          transition: all 0.3s ease-out;
          padding-left: 0;
          margin-right: 0;
        }
      }
    }
    @include media-breakpoint-down($lg){
      max-width: 100%;
      margin: 0;
    }
  }
}
