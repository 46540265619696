// Fonts

@font-face {
  font-family: 'Be Vietnam Pro Bold';
  src: url('../fonts/BeVietnamPro-Bold.eot');
  src: url('../fonts/BeVietnamPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BeVietnamPro-Bold.woff2') format('woff2'),
  url('../fonts/BeVietnamPro-Bold.woff') format('woff'),
  url('../fonts/BeVietnamPro-Bold.svg#BeVietnamPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro Black Italic';
  src: url('../fonts/BeVietnamPro-BlackItalic.eot');
  src: url('../fonts/BeVietnamPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BeVietnamPro-BlackItalic.woff2') format('woff2'),
  url('../fonts/BeVietnamPro-BlackItalic.woff') format('woff'),
  url('../fonts/BeVietnamPro-BlackItalic.svg#BeVietnamPro-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro ExtraBold Italic';
  src: url('../fonts/BeVietnamPro-ExtraBoldItalic.eot');
  src: url('../fonts/BeVietnamPro-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BeVietnamPro-ExtraBoldItalic.woff2') format('woff2'),
  url('../fonts/BeVietnamPro-ExtraBoldItalic.woff') format('woff'),
  url('../fonts/BeVietnamPro-ExtraBoldItalic.svg#BeVietnamPro-ExtraBoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro ExtraBold';
  src: url('../fonts/BeVietnamPro-ExtraBold.eot');
  src: url('../fonts/BeVietnamPro-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BeVietnamPro-ExtraBold.woff2') format('woff2'),
  url('../fonts/BeVietnamPro-ExtraBold.woff') format('woff'),
  url('../fonts/BeVietnamPro-ExtraBold.svg#BeVietnamPro-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro ExtraLight';
  src: url('../fonts/BeVietnamPro-ExtraLight.eot');
  src: url('../fonts/BeVietnamPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BeVietnamPro-ExtraLight.woff2') format('woff2'),
  url('../fonts/BeVietnamPro-ExtraLight.woff') format('woff'),
  url('../fonts/BeVietnamPro-ExtraLight.svg#BeVietnamPro-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro Bold Italic';
  src: url('../fonts/BeVietnamPro-BoldItalic.eot');
  src: url('../fonts/BeVietnamPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BeVietnamPro-BoldItalic.woff2') format('woff2'),
  url('../fonts/BeVietnamPro-BoldItalic.woff') format('woff'),
  url('../fonts/BeVietnamPro-BoldItalic.svg#BeVietnamPro-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro Italic';
  src: url('../fonts/BeVietnamPro-Italic.eot');
  src: url('../fonts/BeVietnamPro-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BeVietnamPro-Italic.woff2') format('woff2'),
  url('../fonts/BeVietnamPro-Italic.woff') format('woff'),
  url('../fonts/BeVietnamPro-Italic.svg#BeVietnamPro-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro ExtraLight Italic';
  src: url('../fonts/BeVietnamPro-ExtraLightItalic.eot');
  src: url('../fonts/BeVietnamPro-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BeVietnamPro-ExtraLightItalic.woff2') format('woff2'),
  url('../fonts/BeVietnamPro-ExtraLightItalic.woff') format('woff'),
  url('../fonts/BeVietnamPro-ExtraLightItalic.svg#BeVietnamPro-ExtraLightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro Medium Italic';
  src: url('../fonts/BeVietnamPro-MediumItalic.eot');
  src: url('../fonts/BeVietnamPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BeVietnamPro-MediumItalic.woff2') format('woff2'),
  url('../fonts/BeVietnamPro-MediumItalic.woff') format('woff'),
  url('../fonts/BeVietnamPro-MediumItalic.svg#BeVietnamPro-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro Light Italic';
  src: url('../fonts/BeVietnamPro-LightItalic.eot');
  src: url('../fonts/BeVietnamPro-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BeVietnamPro-LightItalic.woff2') format('woff2'),
  url('../fonts/BeVietnamPro-LightItalic.woff') format('woff'),
  url('../fonts/BeVietnamPro-LightItalic.svg#BeVietnamPro-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro Medium';
  src: url('../fonts/BeVietnamPro-Medium.eot');
  src: url('../fonts/BeVietnamPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BeVietnamPro-Medium.woff2') format('woff2'),
  url('../fonts/BeVietnamPro-Medium.woff') format('woff'),
  url('../fonts/BeVietnamPro-Medium.svg#BeVietnamPro-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro Light';
  src: url('../fonts/BeVietnamPro-Light.eot');
  src: url('../fonts/BeVietnamPro-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BeVietnamPro-Light.woff2') format('woff2'),
  url('../fonts/BeVietnamPro-Light.woff') format('woff'),
  url('../fonts/BeVietnamPro-Light.svg#BeVietnamPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro';
  src: url('../fonts/BeVietnamPro-Regular.eot');
  src: url('../fonts/BeVietnamPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BeVietnamPro-Regular.woff2') format('woff2'),
  url('../fonts/BeVietnamPro-Regular.woff') format('woff'),
  url('../fonts/BeVietnamPro-Regular.svg#BeVietnamPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro SemiBold';
  src: url('../fonts/BeVietnamPro-SemiBold.eot');
  src: url('../fonts/BeVietnamPro-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BeVietnamPro-SemiBold.woff2') format('woff2'),
  url('../fonts/BeVietnamPro-SemiBold.woff') format('woff'),
  url('../fonts/BeVietnamPro-SemiBold.svg#BeVietnamPro-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro Thin';
  src: url('../fonts/BeVietnamPro-Thin.eot');
  src: url('../fonts/BeVietnamPro-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BeVietnamPro-Thin.woff2') format('woff2'),
  url('../fonts/BeVietnamPro-Thin.woff') format('woff'),
  url('../fonts/BeVietnamPro-Thin.svg#BeVietnamPro-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro Thin Italic';
  src: url('../fonts/BeVietnamPro-ThinItalic.eot');
  src: url('../fonts/BeVietnamPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BeVietnamPro-ThinItalic.woff2') format('woff2'),
  url('../fonts/BeVietnamPro-ThinItalic.woff') format('woff'),
  url('../fonts/BeVietnamPro-ThinItalic.svg#BeVietnamPro-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro SemiBold Italic';
  src: url('../fonts/BeVietnamPro-SemiBoldItalic.eot');
  src: url('../fonts/BeVietnamPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BeVietnamPro-SemiBoldItalic.woff2') format('woff2'),
  url('../fonts/BeVietnamPro-SemiBoldItalic.woff') format('woff'),
  url('../fonts/BeVietnamPro-SemiBoldItalic.svg#BeVietnamPro-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Be Vietnam Pro Black';
  src: url('../fonts/BeVietnamPro-Black.eot');
  src: url('../fonts/BeVietnamPro-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BeVietnamPro-Black.woff2') format('woff2'),
  url('../fonts/BeVietnamPro-Black.woff') format('woff'),
  url('../fonts/BeVietnamPro-Black.svg#BeVietnamPro-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono Bold';
  src: url('../fonts/IBMPlexMono-Bold.eot');
  src: url('../fonts/IBMPlexMono-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexMono-Bold.woff2') format('woff2'),
  url('../fonts/IBMPlexMono-Bold.woff') format('woff'),
  url('../fonts/IBMPlexMono-Bold.svg#IBMPlexMono-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono ExtraLight Italic';
  src: url('../fonts/IBMPlexMono-ExtraLightItalic.eot');
  src: url('../fonts/IBMPlexMono-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexMono-ExtraLightItalic.woff2') format('woff2'),
  url('../fonts/IBMPlexMono-ExtraLightItalic.woff') format('woff'),
  url('../fonts/IBMPlexMono-ExtraLightItalic.svg#IBMPlexMono-ExtraLightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono ExtraLight';
  src: url('../fonts/IBMPlexMono-ExtraLight.eot');
  src: url('../fonts/IBMPlexMono-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexMono-ExtraLight.woff2') format('woff2'),
  url('../fonts/IBMPlexMono-ExtraLight.woff') format('woff'),
  url('../fonts/IBMPlexMono-ExtraLight.svg#IBMPlexMono-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono Light';
  src: url('../fonts/IBMPlexMono-Light.eot');
  src: url('../fonts/IBMPlexMono-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexMono-Light.woff2') format('woff2'),
  url('../fonts/IBMPlexMono-Light.woff') format('woff'),
  url('../fonts/IBMPlexMono-Light.svg#IBMPlexMono-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono Italic';
  src: url('../fonts/IBMPlexMono-Italic.eot');
  src: url('../fonts/IBMPlexMono-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexMono-Italic.woff2') format('woff2'),
  url('../fonts/IBMPlexMono-Italic.woff') format('woff'),
  url('../fonts/IBMPlexMono-Italic.svg#IBMPlexMono-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono Bold Italic';
  src: url('../fonts/IBMPlexMono-BoldItalic.eot');
  src: url('../fonts/IBMPlexMono-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexMono-BoldItalic.woff2') format('woff2'),
  url('../fonts/IBMPlexMono-BoldItalic.woff') format('woff'),
  url('../fonts/IBMPlexMono-BoldItalic.svg#IBMPlexMono-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono Light Italic';
  src: url('../fonts/IBMPlexMono-LightItalic.eot');
  src: url('../fonts/IBMPlexMono-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexMono-LightItalic.woff2') format('woff2'),
  url('../fonts/IBMPlexMono-LightItalic.woff') format('woff'),
  url('../fonts/IBMPlexMono-LightItalic.svg#IBMPlexMono-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono SemiBold Italic';
  src: url('../fonts/IBMPlexMono-SemiBoldItalic.eot');
  src: url('../fonts/IBMPlexMono-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexMono-SemiBoldItalic.woff2') format('woff2'),
  url('../fonts/IBMPlexMono-SemiBoldItalic.woff') format('woff'),
  url('../fonts/IBMPlexMono-SemiBoldItalic.svg#IBMPlexMono-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono Thin';
  src: url('../fonts/IBMPlexMono-Thin.eot');
  src: url('../fonts/IBMPlexMono-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexMono-Thin.woff2') format('woff2'),
  url('../fonts/IBMPlexMono-Thin.woff') format('woff'),
  url('../fonts/IBMPlexMono-Thin.svg#IBMPlexMono-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: url('../fonts/IBMPlexMono-Regular.eot');
  src: url('../fonts/IBMPlexMono-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexMono-Regular.woff2') format('woff2'),
  url('../fonts/IBMPlexMono-Regular.woff') format('woff'),
  url('../fonts/IBMPlexMono-Regular.svg#IBMPlexMono-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono Medium Italic';
  src: url('../fonts/IBMPlexMono-MediumItalic.eot');
  src: url('../fonts/IBMPlexMono-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexMono-MediumItalic.woff2') format('woff2'),
  url('../fonts/IBMPlexMono-MediumItalic.woff') format('woff'),
  url('../fonts/IBMPlexMono-MediumItalic.svg#IBMPlexMono-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono SemiBold';
  src: url('../fonts/IBMPlexMono-SemiBold.eot');
  src: url('../fonts/IBMPlexMono-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexMono-SemiBold.woff2') format('woff2'),
  url('../fonts/IBMPlexMono-SemiBold.woff') format('woff'),
  url('../fonts/IBMPlexMono-SemiBold.svg#IBMPlexMono-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono Medium';
  src: url('../fonts/IBMPlexMono-Medium.eot');
  src: url('../fonts/IBMPlexMono-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexMono-Medium.woff2') format('woff2'),
  url('../fonts/IBMPlexMono-Medium.woff') format('woff'),
  url('../fonts/IBMPlexMono-Medium.svg#IBMPlexMono-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans Bold';
  src: url('../fonts/IBMPlexSans-Bold.eot');
  src: url('../fonts/IBMPlexSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-Bold.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-Bold.woff') format('woff'),
  url('../fonts/IBMPlexSans-Bold.svg#IBMPlexSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans Bold Italic';
  src: url('../fonts/IBMPlexSans-BoldItalic.eot');
  src: url('../fonts/IBMPlexSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-BoldItalic.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-BoldItalic.woff') format('woff'),
  url('../fonts/IBMPlexSans-BoldItalic.svg#IBMPlexSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Mono Thin Italic';
  src: url('../fonts/IBMPlexMono-ThinItalic.eot');
  src: url('../fonts/IBMPlexMono-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexMono-ThinItalic.woff2') format('woff2'),
  url('../fonts/IBMPlexMono-ThinItalic.woff') format('woff'),
  url('../fonts/IBMPlexMono-ThinItalic.svg#IBMPlexMono-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans ExtraLight';
  src: url('../fonts/IBMPlexSans-ExtraLight.eot');
  src: url('../fonts/IBMPlexSans-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-ExtraLight.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-ExtraLight.woff') format('woff'),
  url('../fonts/IBMPlexSans-ExtraLight.svg#IBMPlexSans-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans Light';
  src: url('../fonts/IBMPlexSans-Light.eot');
  src: url('../fonts/IBMPlexSans-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-Light.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-Light.woff') format('woff'),
  url('../fonts/IBMPlexSans-Light.svg#IBMPlexSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans ExtraLight Italic';
  src: url('../fonts/IBMPlexSans-ExtraLightItalic.eot');
  src: url('../fonts/IBMPlexSans-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-ExtraLightItalic.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-ExtraLightItalic.woff') format('woff'),
  url('../fonts/IBMPlexSans-ExtraLightItalic.svg#IBMPlexSans-ExtraLightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans Italic';
  src: url('../fonts/IBMPlexSans-Italic.eot');
  src: url('../fonts/IBMPlexSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-Italic.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-Italic.woff') format('woff'),
  url('../fonts/IBMPlexSans-Italic.svg#IBMPlexSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans Medium Italic';
  src: url('../fonts/IBMPlexSans-MediumItalic.eot');
  src: url('../fonts/IBMPlexSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-MediumItalic.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-MediumItalic.woff') format('woff'),
  url('../fonts/IBMPlexSans-MediumItalic.svg#IBMPlexSans-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans Medium';
  src: url('../fonts/IBMPlexSans-Medium.eot');
  src: url('../fonts/IBMPlexSans-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-Medium.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-Medium.woff') format('woff'),
  url('../fonts/IBMPlexSans-Medium.svg#IBMPlexSans-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans Light Italic';
  src: url('../fonts/IBMPlexSans-LightItalic.eot');
  src: url('../fonts/IBMPlexSans-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-LightItalic.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-LightItalic.woff') format('woff'),
  url('../fonts/IBMPlexSans-LightItalic.svg#IBMPlexSans-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans Thin';
  src: url('../fonts/IBMPlexSans-Thin.eot');
  src: url('../fonts/IBMPlexSans-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-Thin.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-Thin.woff') format('woff'),
  url('../fonts/IBMPlexSans-Thin.svg#IBMPlexSans-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans SemiBold';
  src: url('../fonts/IBMPlexSans-SemiBold.eot');
  src: url('../fonts/IBMPlexSans-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-SemiBold.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-SemiBold.woff') format('woff'),
  url('../fonts/IBMPlexSans-SemiBold.svg#IBMPlexSans-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-Regular.eot');
  src: url('../fonts/IBMPlexSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-Regular.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-Regular.woff') format('woff'),
  url('../fonts/IBMPlexSans-Regular.svg#IBMPlexSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans SemiBold Italic';
  src: url('../fonts/IBMPlexSans-SemiBoldItalic.eot');
  src: url('../fonts/IBMPlexSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-SemiBoldItalic.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-SemiBoldItalic.woff') format('woff'),
  url('../fonts/IBMPlexSans-SemiBoldItalic.svg#IBMPlexSans-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans Thin Italic';
  src: url('../fonts/IBMPlexSans-ThinItalic.eot');
  src: url('../fonts/IBMPlexSans-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/IBMPlexSans-ThinItalic.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-ThinItalic.woff') format('woff'),
  url('../fonts/IBMPlexSans-ThinItalic.svg#IBMPlexSans-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
