.button-main-slider-container {
  max-width: 357px;
}

.button-main-slider {
  border: 2px solid #1B9CF2;
  width: 100%;
  color: #ffffff;
  font-family: 'Be Vietnam Pro Bold', sans-serif;
  font-size: 14px;
  height: 60px;
  background-color: transparent;
  text-transform: uppercase;
  display: flex;
  text-align: center;
  align-items: center;
  letter-spacing: normal;
  word-spacing: normal;
  text-rendering: auto;
  -ms-writing-mode: lr-tb !important;
  writing-mode: horizontal-tb !important;
  text-decoration: none;
  justify-content: center;
  &:hover {
    background-color: #1B9CF2;
    color: #ffffff;
  }
  &:active{
    background-color: #1B9CF2;
  }
}

.button-training-block {
  border: 1px solid #ffffff;
  width: 100%;
  color: #ffffff;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 700;
  font-size: 14px;
  height: 60px;
  text-transform: uppercase;
  background: linear-gradient(to left, transparent 50%, white 50%);
  background-size: 200%;
  background-position: calc(100% - -1px);
  transition: background-position 0.3s ease-out;
  padding: 1px 6px;
  text-indent: 0;
  text-shadow: none;
  display: flex;
  text-align: center;
  align-items: center;
  letter-spacing: normal;
  word-spacing: normal;
  text-rendering: auto;
  -ms-writing-mode: lr-tb !important;
  writing-mode: horizontal-tb !important;
  text-decoration: none;
  justify-content: center;
  &.inline {
    width: unset;
    padding: 20px 60px;
    display: inline;
    svg {
      height: 12px;
      width: 12px;
      margin: 0 0 4px 10px;
      stroke: #ffffff;
    }

    @include media-breakpoint-down($md){
      display: block;
      padding:20px 30px;
    }
  }
  &:hover {
    background-position: left;
    color: #03153B;
    &.inline {
      svg {
        stroke: #03153B;
      }
    }
  }
}

.button-googlemaps-round {
  border: 2px solid #ECECEC;
  border-radius: 30px;
  background-color: #ffffff;
  transition: background-color 0.2s ease-in;
  font-family: 'Be Vietnam Pro Bold', sans-serif;
  font-size: 14px;
  color: #03153B;
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #ECECEC;
    color: #03153B;
  }
}

.button-join_us {
  font-family: 'Be Vietnam Pro Bold', sans-serif;
  font-size: 14px;
  color: #03153B;
  padding: 16px 90px;
  border: 2px solid #1B9CF2;
  text-transform: uppercase;
  text-decoration: none;
  background: linear-gradient(to top, #1B9CF2 50%, #fff 50%);
  background-size: 100% 200%;
  background-position: top;
  transition: background-position 0.3s ease-out;
  border-radius: 0;
  &:hover {
    background-position: bottom;
    color: #FFFFFF;
  }
}

.button-job-apply {
  font-family: 'Be Vietnam Pro Bold', sans-serif;
  font-size: 14px;
  color: #03153B;
  padding: 16px 90px;
  border: 2px solid #1B9CF2;
  text-transform: uppercase;
  text-decoration: none;
  background: linear-gradient(to left, #fff 50%, #1B9CF2 50%);
  background-size: 200%;
  background-position: right;
  transition: background-position 0.3s ease-out;
  border-radius: 0;
  &:hover {
    background-position: left;
    color: #FFFFFF;
  }

  @include media-breakpoint-down($xs){
    padding: 16px 50px;
  }
}
